import React, { useEffect, useRef, useState } from "react";
import { useProcessContext } from "../../context/ProcessContext";
import { useFormContext } from "../../context/FormContext";
import BASE_URL, { API_KEY } from "../../../config";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import { IoMdClose } from "react-icons/io";
import ExportRecordFileButton from "../../utility_files/ExportRecordFileButton";

const UploadRecordFileOverlay = () => {
  const { centerAlert, centerAlertWithTitle, fetchAllRecords } =
    useProcessContext();
  const { customModelDetail, parseFormDataWithoutTableO2m } = useFormContext();
  const [recordData, setRecordData] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  //   useEffect for getting form fields
  useEffect(() => {
    if (
      customModelDetail &&
      Array.isArray(customModelDetail.model_fields) &&
      customModelDetail.model_fields.length > 0
    ) {
      const fields = parseFormDataWithoutTableO2m(
        customModelDetail.model_fields
      )?.filter(
        (field) =>
          !["virtualField", "email_verify_status"].includes(field.widget)
      );
      setRecordData(
        fields?.reduce((acc, field) => {
          acc.push(field.technicalName);
          return acc;
        }, [])
      );
    }

    return handleReset;
  }, [customModelDetail]);

  const handleReset = () => {
    setRecordData(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  //   method for import file
  const handleImport = (event) => {
    const file = event.target.files[0];

    if (file && (file.name.endsWith(".xlsx") || file.name.endsWith(".xls"))) {
      // Ensure the file has the correct extension
      setSelectedFile(file);
    } else {
      event.target.value = "";
      centerAlert("error", "Please select a valid .xlsx or .xls file");
      // alert("Please select a valid .oflow file.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedFile === "" || selectedFile === null) {
      return centerAlert("error", "Record file is mandatory set.");
    }

    const payload = {
      key: API_KEY,
      database: customModelDetail.database_obj,
      database_url: customModelDetail.database_url,
      model: customModelDetail.model,
      model_id: customModelDetail.id,
    };

    const formData = new FormData();

    // Append all the data to FormData
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    formData.append("data_file", selectedFile);
    try {
      const response = await fetch(
        `${BASE_URL}/custom-model/post/data/model/field/upload`,
        {
          method: "POST",
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          body: formData,
        }
      );
      const data = await response.json();
      if (response.ok) {
        console.log("record submit success:: ", data);
        await fetchAllRecords({
          key: API_KEY,
          database: customModelDetail?.database_obj,
          database_url: customModelDetail?.database_url,
          modelDetail: {
            model: customModelDetail?.model,
            model_id: customModelDetail?.id,
          },
        });
        centerAlert("success", data.message);
        handleReset();
        const modalElement = document.getElementById("uploadRecordFileModal");
        const modalInstance =
          bootstrapBundleMin.Modal.getInstance(modalElement);
        modalInstance.hide();
      } else {
        centerAlert("error", data.message);
      }
    } catch (error) {
      centerAlertWithTitle("Oops...", error, "error");
    }
  };

  console.log("recordData::: ", recordData);

  return (
    <div
      className="modal fade"
      id="uploadRecordFileModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="uploadRecordFileModal"
      aria-hidden="true"
    >
      {/* <style dangerouslySetInnerHTML={{ __html: styles }} /> */}
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="uploadRecordFileModalLabel">
              Upload Record File
            </h1>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                position: "absolute",
                right: "20px",
                fontSize: "25px",
                color: "white",
              }}
              onClick={handleReset}
            >
              <IoMdClose />
            </button>
          </div>
          <form method="post" onSubmit={handleSubmit}>
            <div className="modal-body">
              <div
                className="importProcessModalForm d-flex flex-column"
                style={{ width: "100%" }}
              >
                <div
                  className=""
                  style={{ width: "100%" }}
                >
                  <ExportRecordFileButton
                    model={customModelDetail.model}
                    headers={recordData}
                  />
                </div>
                <div className="importProcessModalForm_inputField">
                  <label htmlFor="recordFile">Upload file</label>
                  <input
                    type="file"
                    id="recordFile"
                    className="form-control"
                    placeholder="upload recod file"
                    name="record_file"
                    onChange={handleImport}
                    accept=".xlsx, .xls"
                    required
                    ref={fileInputRef}
                  />
                  <span>*only .xlsx or .xls extension is accepted.</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleReset}
              >
                Close
              </button>
              <button type="submit" className="btn modal_save_button">
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadRecordFileOverlay;
