import React, { useEffect, useState } from "react";
import { useProcessContext } from "../../context/ProcessContext";
import { useNodeContext } from "../../context/NodeContext";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../../config";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import { IoMdClose } from "react-icons/io";
import CustomDropdown from "../custom_dropdown/CustomDropdown";
import { useFormContext } from "../../context/FormContext";

const CustomModelOverlay = () => {
  const {
    userData,
    centerAlert,
    centerAlertWithTitle,
    groups,
    fetchAllActiveDatabase,
    createCustomModel,
    fetchAllCustomModel,
  } = useProcessContext();
  const navigate = useNavigate();
  const [activeDataBases, setActiveDatabases] = useState(null);
  const [customModelData, setCustomModelData] = useState({
    customModelData_database: null,
    customModelData_name: "",
    user_id: userData.userid,
    customModelData_transient: false,
  });

  //   const handleDataChange = (e) => {
  //     // const { name } = e.target;
  //     // let newValue = e.target.value;
  //     if (e.target.name === "process_name") {
  //       const regex = /^[a-zA-Z0-9_ ]*$/;

  //       if (regex.test(e.target.value)) {
  //         // newValue = e.target.value
  //         setProcessesFormData({
  //           ...processFormData,
  //           [e.target.name]: e.target.value,
  //         });
  //       }
  //     } else {
  //       setProcessesFormData({
  //         ...processFormData,
  //         [e.target.name]: e.target.value,
  //       });
  //     }
  //   };
  const handleDataChange = (e) => {
    const { name, value, checked, type } = e.target;
    setCustomModelData((prevCustomModelData) => ({
      ...prevCustomModelData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // method for getting active database
  const getData = async () => {
    const activeDatabases = await fetchAllActiveDatabase();
    console.log("Active Databases:", activeDatabases);
    setActiveDatabases(activeDatabases);
  };

  // fetching active database
  useEffect(() => {
    getData();
  }, []);

  const handleReset = () =>
    setCustomModelData({
      customModelData_database: null,
      customModelData_name: "",
      user_id: userData.userid,
      customModelData_transient: false,
    });
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      customModelData.customModelData_database === "" ||
      customModelData.customModelData_database === null
    ) {
      return centerAlert("error", "Hutch is mandatory to select.");
    }

    const payload = {
      database: customModelData.customModelData_database,
      name: customModelData.customModelData_name,
      model: `o2b.${customModelData.customModelData_name
        .trim()
        .toLowerCase()
        .replace(/[^\w_]/g, "_")}`,
      transient: customModelData.customModelData_transient,
      user_id: userData.userid,
    };
    try {
      const response = await createCustomModel(payload, userData.userid);
      handleReset();
      centerAlert("success", response.message);
      fetchAllCustomModel(userData.userid);
      const modalElement = document.getElementById("customModelModal");
      const modalInstance = bootstrapBundleMin.Modal.getInstance(modalElement);
      modalInstance.hide();
    } catch (error) {
      centerAlertWithTitle("Oops...", error, "error");
    }
  };

  return (
    <div
      className="modal fade"
      id="customModelModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              New Model Form
            </h1>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                position: "absolute",
                right: "20px",
                fontSize: "25px",
                color: "white",
              }}
            >
              <IoMdClose />
            </button>
          </div>
          <form method="post" onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="processModalForm">
                <div className="processModalForm_inputField">
                  <label htmlFor="customModelData_database">
                    Hutch (database)*
                  </label>
                  <CustomDropdown
                    name="customModelData_database"
                    defaultValue={
                      customModelData.customModelData_database || null
                    }
                    options={activeDataBases?.map((db) => {
                      return {
                        value: db._id,
                        label: `${db.configname} (${db.database})`,
                      };
                    })}
                    onChange={(event) => handleDataChange(event)}
                    width={"95%"}
                    height={"35px"}
                    border="1px solid gray"
                  />
                </div>
                <div className="processModalForm_inputField">
                  <label htmlFor="customModelData_name">Model Name*</label>
                  <input
                    type="text"
                    id="customModelData_name"
                    placeholder="Enter process name"
                    name="customModelData_name"
                    value={customModelData.customModelData_name}
                    onChange={handleDataChange}
                    required
                  />
                </div>
                <div
                  className="fb_field_details_form_group_checkbox_container"
                  style={{ color: "#383838", width: "100%" }}
                >
                  <label
                    htmlFor="customModelData_transient"
                    className="label_checkbox"
                  >
                    Transient
                  </label>
                  <label className="checkbox_container">
                    <input
                      type="checkbox"
                      id="customModelData_transient"
                      name="customModelData_transient"
                      checked={customModelData.customModelData_transient}
                      onChange={handleDataChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn modal_save_button">
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomModelOverlay;
