import React from "react";
import "./DownloadButton.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const ExportRecordFileButton = ({ headers, model }) => {
  //   const headers = ["Name", "Age", "Email"]; // Define headers only

  const downloadExcel = (e) => {
    e.preventDefault();
    // Create a worksheet with headers only
    const worksheet = XLSX.utils.aoa_to_sheet([headers]);

    // Create Workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write file and trigger download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    saveAs(
      blob,
      `${
        model
          .trim()
          .toLowerCase()
          .replace(/[^a-zA-Z0-9_]/g, "_") || "record_data"
      }.xlsx`
    );
  };

  return (
    <button onClick={downloadExcel} className="export_record_btn">
      Export Sample
    </button>
  );
};

export default ExportRecordFileButton;
