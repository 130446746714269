import React, { useEffect, useState } from "react";
import { useProcessContext } from "../../context/ProcessContext";
import { Link, useNavigate } from "react-router-dom";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useAdminContext } from "../../context/AdminContext";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../config";

const Subscriptions = () => {
  const { topRightAlert, centerAlert, encryptData } = useProcessContext();
  const {
    adminData,
    subscriptions,
    setSubscriptions,
    fetchAllSubscriptionData,
    setsubscriptionId,
  } = useAdminContext();

  const [loading, setLoading] = useState(false);
  const [visibleRow, setVisibleRow] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAllSubscriptionData();
    setLoading(false);
  }, [setSubscriptions, adminData.userid]);

  const handleDelete = async (subscriptionId) => {
    try {
      const requestBody = {
        subscription_id: subscriptionId,
        key: API_KEY,
      };
      const encryptedData = encryptData(requestBody, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/subscription/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log("delete process: ", data);
        topRightAlert("success", data.message);
        // Filter out the deleted process from the state
        // setProcesses((prevProcesses) =>
        //   prevProcesses.filter((process) => process.process_id !== processId)
        // );
        await fetchAllSubscriptionData();
      } else {
        const data = await response.json();
        centerAlert("error", data.message);
        console.error("Error deleting subscription:", data);
      }
    } catch (error) {
      centerAlert("error", "Internal Server Error");
      console.error("Error deleting subscription:", error);
    }
  };
  const handleEditClick = (e, subscriptionId) => {
    e.preventDefault();
    setsubscriptionId(null);
    setsubscriptionId(subscriptionId);
    navigate("/admin-dashboard/subscription/subscription-form");
  };
  // const handleEdit = async (processId) => {
  //   if (processId !== null) {
  //     try {
  //       const response = await fetch(`${BASE_URL}/process/fetch`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           process_id: processId,
  //           user_id: userData.userid,
  //         }),
  //       });

  //       if (response.ok) {
  //         const data = await response.json();
  //         // console.log("Data ProcessDetail:::::::: ", data);
  //         // fetchAllProcessData();
  //         const nodesData = data.processes.process_data_flow.nodesData;
  //         const edgesData = data.processes.process_data_flow.edgesData;
  //         console.log("flowData::::: ", nodesData);
  //         setProcessDetail(data.processes);
  //         setNodes(
  //           data.processes.process_data_flow.nodesData
  //             ? JSON.parse(nodesData)
  //             : initialNodes
  //         );
  //         setEdges(
  //           data.processes?.process_data_flow.edgesData
  //             ? JSON.parse(edgesData)
  //             : []
  //         );
  //         setNewProcessId(processId);
  //         setShapeId(null);
  //         navigate("/process-modeler");
  //       } else {
  //         const data = await response.json();
  //         console.error("Error updating process status:", data);
  //       }
  //     } catch (error) {
  //       console.error("Error updating process status:", error);
  //     }
  //   }
  // };

  if (loading) return <span>Loading....</span>;
  return (
    <div className="donor_dash_right">
      <div className="dashboard_buttons">
        <Link to={"/admin-dashboard/subscription/subscription-form"}>
          <button onClick={() => setsubscriptionId(null)}>
            {/* <button data-bs-toggle="modal" data-bs-target="#subscriptionsModal"> */}
            New Subscription
          </button>
        </Link>
      </div>
      {/* Modal */}
      {/* over modal code */}
      <div className="donor_dash_deatil">
        <div className="donor_kit">
          <h2 className="table_caption_head">List of existing subscriptions</h2>
          <div className="donor_kit_desc" />
          <div className="donor_table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  {/* <th>Process Id</th> */}
                  <th>License Key</th>
                  <th>Start Date</th>
                  <th>Expiry Date</th>
                  <th>Current Status</th>
                  <th>Status Update</th>
                  <th>Database</th>
                  {/* <th>User</th> */}
                  {/* <th>Password</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.length > 0 ? (
                  subscriptions.map((subscription, index) => (
                    <tr key={subscription._id}>
                      <th>{index + 1}</th>
                      <td>{subscription.license_key}</td>
                      <td>{subscription.start_date}</td>
                      <td>{subscription.expiry_date}</td>
                      <td>
                        {subscription.current_status ? "Active" : "Expired"}
                      </td>
                      <td>{subscription.auto_update ? "Auto" : "Manual"}</td>
                      {/* <td>{subscription.user_name}</td> */}
                      <td>{subscription.database}</td>
                      {/* <td>{user.password}</td> */}
                      {/* <td>
                        {visibleRow === user._id ? `${user.password}` : "*****"}
                      </td> */}
                      {/* <td>
                        <div
                          className="form-check form-switch"
                          style={{ paddingLeft: "3.5rem" }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheckDefault-${process.process_id}`}
                            checked={process.odoo_checking_status}
                            onChange={(e) =>
                              handleStatusChange(
                                process.process_id,
                                e.target.checked
                              )
                            }
                          />
                        </div>
                      </td> */}
                      <td className="td_action_cell">
                        {/* {visibleRow === user._id ? (
                          <FaEye
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              marginRight: "3px",
                            }}
                            onClick={() => setVisibleRow(null)}
                          />
                        ) : (
                          <FaEyeSlash
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              marginRight: "3px",
                            }}
                            onClick={() => setVisibleRow(user._id)}
                          />
                        )} */}
                        <CiEdit
                          className="dash_table_icon_edit"
                          onClick={(e) => handleEditClick(e, subscription._id)}
                        />
                        <RiDeleteBin5Line
                          className="dash_table_icon_del"
                          onClick={() => handleDelete(subscription._id)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr key={adminData.userid}>
                    <td colSpan={8} className="text-center ">
                      No existing subscriptions...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
