import React, { memo, useEffect, useState } from "react";
import "./FormControl.css";
import NumBox from "./number-box.svg";
import {
  BsBuildingsFill,
  BsPersonFill,
  BsPersonFillAdd,
  BsPersonFillCheck,
  BsTextareaResize,
} from "react-icons/bs";
import {
  FaCamera,
  FaFile,
  FaImage,
  FaOrcid,
  FaTable,
  FaUserClock,
} from "react-icons/fa";
import { PiTextbox } from "react-icons/pi";
import { CgPassword } from "react-icons/cg";
import { MdDateRange, MdOutlineWorkHistory } from "react-icons/md";
import { AiOutlineAudit, AiOutlineMail } from "react-icons/ai";
import { MdSmartButton } from "react-icons/md";
import { RxGroup } from "react-icons/rx";
import { HiOutlineSelector } from "react-icons/hi";
import { TfiLayoutTab } from "react-icons/tfi";
import { LuAlignVerticalSpaceAround, LuTextCursorInput } from "react-icons/lu";
import { useFormContext } from "../../../context/FormContext";
import { IoChatbubbles, IoGlobeOutline } from "react-icons/io5";
import {
  TbDecimal,
  TbRelationManyToMany,
  TbRelationOneToMany,
} from "react-icons/tb";
import { IoMdCheckboxOutline, IoMdRibbon } from "react-icons/io";
import { TiImage } from "react-icons/ti";
import { useNodeContext } from "../../../context/NodeContext";
import { FaRegAddressCard } from "react-icons/fa6";
import { RiCalendarScheduleLine } from "react-icons/ri";

const componentsBtn = [
  {
    type: "label",
    label: "Label",
    widget: "label",
    icon: <LuTextCursorInput style={{ fontSize: "20px" }} />,
    technicalName: "",
    defaultValue: "",
    placeholder: "",
    tooltip: "",
    isRequired: false,
    isReadOnly: false,
    isTracking: false,
    isOnChange: false,
    isInvisible: false,
    isCreate: false,
    isEdit: false,
    isDelete: false,
  },
  {
    type: "static_image",
    label: "Static Image",
    widget: "static_image",
    icon: <TiImage style={{ fontSize: "20px" }} />,
    defaultValue: "",
    technicalName: "",
    placeholder: "",
    tooltip: "",
    isRequired: false,
    isReadOnly: false,
    isTracking: false,
    isOnChange: false,
    isInvisible: false,
    isCreate: false,
    isEdit: false,
    isDelete: false,
  },
  {
    type: "ribbon",
    label: "Ribbon",
    widget: "ribbon",
    icon: <IoMdRibbon style={{ fontSize: "20px" }} />,
    defaultValue: "",
    technicalName: "",
    placeholder: "",
    tooltip: "",
    isRequired: false,
    isReadOnly: false,
    isTracking: false,
    isOnChange: false,
    isInvisible: false,
    isCreate: false,
    isEdit: false,
    isDelete: false,
  },
  {
    type: "button",
    label: "Button",
    widget: "button",
    icon: <MdSmartButton className="fieldicon" />,
    defaultValue: "",
    technicalName: "x_o2b_button",
    placeholder: "",
    tooltip: "",
    isRequired: false,
    isReadOnly: false,
    isTracking: false,
    isOnChange: false,
    isInvisible: false,
    isCreate: false,
    isEdit: false,
    isDelete: false,
  },
  {
    type: "object",
    label: "Smart Button",
    widget: "statinfo",
    icon: <MdSmartButton className="fieldicon" />,
    defaultValue: "",
    technicalName: "x_o2b_smart_button",
    placeholder: "",
    tooltip: "",
    isRequired: false,
    isReadOnly: false,
    isTracking: false,
    isOnChange: false,
    isInvisible: false,
    isCreate: false,
    isEdit: false,
    isDelete: false,
  },
  // {
  //   type: "object",
  //   label: "Todos",
  //   widget: "default-statinfo",
  //   icon: <MdSmartButton className="fieldicon" />,
  //   defaultValue: "",
  //   technicalName: "x_o2b_todos",
  //   placeholder: "",
  //   tooltip: "",
  //   isRequired: false,
  //   isReadOnly: false,
  //   isTracking: false,
  //   isOnChange: false,
  //   isInvisible: false,
  //   isCreate: false,
  //   isEdit: false,
  //   isDelete: false,
  // },
  // {
  //   type: "object",
  //   label: "Documents",
  //   widget: "default-statinfo",
  //   icon: <MdSmartButton className="fieldicon" />,
  //   defaultValue: "",
  //   technicalName: "x_o2b_documents",
  //   placeholder: "",
  //   tooltip: "",
  //   isRequired: false,
  //   isReadOnly: false,
  //   isTracking: false,
  //   isOnChange: false,
  //   isInvisible: false,
  //   isCreate: false,
  //   isEdit: false,
  //   isDelete: false,
  // },
  {
    type: "group",
    label: "Group",
    widget: "group",
    icon: <RxGroup style={{ fontSize: "20px" }} />,
    defaultValue: "",
    technicalName: "",
    placeholder: "This is group area",
    tooltip: "",
    isRequired: false,
    isReadOnly: false,
    isTracking: false,
    isOnChange: false,
    isInvisible: false,
    isCreate: false,
    isEdit: false,
    isDelete: false,
  },
  {
    type: "tab",
    label: "Tabs",
    widget: "tab",
    icon: <TfiLayoutTab style={{ fontSize: "20px" }} />,
    defaultValue: "",
    technicalName: "",
    placeholder: "",
    tooltip: "",
    isRequired: false,
    isReadOnly: false,
    isTracking: false,
    isOnChange: false,
    isInvisible: false,
    isCreate: false,
    isEdit: false,
    isDelete: false,
  },
  {
    type: "table",
    label: "Table",
    widget: "table",
    icon: <FaTable style={{ fontSize: "20px" }} />,
    defaultValue: "",
    technicalName: "x_o2b_table",
    placeholder: "",
    tooltip: "",
    isRequired: false,
    isReadOnly: false,
    isTracking: false,
    isOnChange: false,
    isInvisible: false,
    isCreate: true,
    isEdit: true,
    isDelete: true,
  },
  {
    type: "chatter",
    label: "Chatter",
    widget: "chatter",
    icon: <IoChatbubbles style={{ fontSize: "20px" }} />,
    defaultValue: "",
    technicalName: "x_o2b_",
    placeholder: "",
    tooltip: "",
    isRequired: false,
    isReadOnly: false,
    isTracking: false,
    isOnChange: false,
    isInvisible: false,
    isCreate: false,
    isEdit: false,
    isDelete: false,
  },
  {
    type: "separator",
    label: "Separator",
    widget: "separator",
    icon: <LuAlignVerticalSpaceAround style={{ fontSize: "20px" }} />,
    defaultValue: "",
    technicalName: "x_o2b_",
    placeholder: "",
    tooltip: "",
    isRequired: false,
    isReadOnly: false,
    isTracking: false,
    isOnChange: false,
    isInvisible: false,
    isCreate: false,
    isEdit: false,
    isDelete: false,
  },
  {
    type: "oe_avatar",
    label: "Form Image",
    widget: "image",
    icon: <FaImage style={{ fontSize: "20px" }} />,
    defaultValue: "",
    technicalName: "x_o2b_form_image",
    placeholder: "",
    tooltip: "",
    isRequired: false,
    isReadOnly: false,
    isTracking: false,
    isOnChange: false,
    isInvisible: false,
    isCreate: false,
    isEdit: false,
    isDelete: false,
  },
  {
    type: "remark_history",
    label: "Remark History",
    widget: "remark_history",
    icon: <MdOutlineWorkHistory style={{ fontSize: "20px" }} />,
    defaultValue: "",
    technicalName: "x_o2b_remark_history",
    placeholder: "",
    tooltip: "",
    isRequired: false,
    isReadOnly: false,
    isTracking: false,
    isOnChange: false,
    isInvisible: false,
    isCreate: false,
    isEdit: false,
    isDelete: false,
  },
];

// const FormControl = ({ isTodoFormBuilder, isDocumentFormBuilder }) => {
const FormControl = () => {
  const { nodes, shapeId } = useNodeContext();
  const { builderType, parseFormDataWithoutTableO2m, isCustomModelForm } =
    useFormContext();
  const [kanabanFieldBtn, setKanabanFieldBtn] = useState(null);
  const [formExistingField, setFormExistingField] = useState(null);
  const [isWebformNode, setWebFormNode] = useState(false);

  const fieldsBtn = [
    {
      type: "char",
      label: "Text",
      widget: "char",
      icon: <PiTextbox className="fieldicon" />,
      technicalName: "x_o2b_text",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "char",
      label: "Password",
      widget: "password",
      icon: <CgPassword className="fieldicon" />,
      technicalName: "x_o2b_password",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "date",
      label: "Date",
      widget: "date",
      icon: <MdDateRange className="fieldicon" />,
      technicalName: "x_o2b_date",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "datetime",
      label: "Datetime",
      widget: "datetime",
      icon: <RiCalendarScheduleLine className="fieldicon" />,
      technicalName: "x_o2b_datetime",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "age",
      label: "Age",
      widget: "date",
      icon: <MdDateRange className="fieldicon" />,
      technicalName: "x_o2b_age",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "integer",
      label: "Integer",
      widget: "integer",
      icon: <img src={NumBox} alt="number icon" style={{ width: "25px" }} />, // assuming "NumBox" is an image source
      technicalName: "x_o2b_integer",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "many2many",
      label: "Many2many",
      widget: "many2many_tags",
      icon: <TbRelationManyToMany className="fieldicon" />,
      technicalName: "x_o2b_many2many",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "many2one",
      label: "Many2one",
      widget: "many2one",
      icon: <TbRelationOneToMany className="fieldicon" />,
      technicalName: "x_o2b_many2one",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "one2many",
      label: "One2many",
      widget: "one2many",
      icon: <TbRelationOneToMany className="fieldicon" />,
      technicalName: "x_o2b_one2many",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "boolean",
      label: "Checkbox",
      widget: "boolean",
      icon: <IoMdCheckboxOutline className="fieldicon" />,
      technicalName: "x_o2b_checkbox",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "text",
      label: "Textarea",
      widget: "text",
      icon: <BsTextareaResize className="fieldicon" />,
      technicalName: "x_o2b_textarea",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "char",
      label: "Email",
      widget: "email",
      icon: <AiOutlineMail className="fieldicon" />,
      technicalName: "x_o2b_email",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "many2one",
      label: "Address",
      widget: "res_partner_many2one",
      icon: <FaRegAddressCard className="fieldicon" />,
      technicalName: "x_o2b_address",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "selection",
      label: "Selection",
      widget: "selection",
      icon: <HiOutlineSelector className="fieldicon" />,
      technicalName: "x_o2b_selection",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "binary",
      label: "Image",
      widget: "image",
      icon: <FaCamera className="fieldicon" style={{ color: "white" }} />,
      technicalName: "x_o2b_image",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "binary",
      label: "File",
      widget: "file",
      icon: <FaFile style={{ color: "#ffffff", fontSize: "20px" }} />,
      technicalName: "x_o2b_file",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "html",
      label: "Html",
      widget: "html",
      icon: <IoGlobeOutline style={{ fontSize: "20px" }} />,
      technicalName: "x_o2b_html",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "float",
      label: "Decimal",
      widget: "float",
      icon: <TbDecimal style={{ fontSize: "20px" }} />,
      technicalName: "x_o2b_decimal",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "logged_in_user",
      label: "Logged-in User",
      widget: "char",
      icon: <BsPersonFill style={{ fontSize: "20px" }} />,
      technicalName: "logged_in_user",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: true,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    // {
    //   type: "employee_id",
    //   label: "Employee Id",
    //   widget: "char",
    //   icon: <FaOrcid style={{ fontSize: "20px" }} />,
    //   technicalName: "employee_id",
    //   defaultValue: "",
    //   placeholder: "",
    //   tooltip: "",
    //   isRequired: false,
    //   isReadOnly: true,
    //   isTracking: false,
    //   isOnChange: false,
    //   isInvisible: false,
    //   isCreate: false,
    //   isEdit: false,
    //   isDelete: false,
    // },
    // {
    //   type: "department",
    //   label: "Department",
    //   widget: "char",
    //   icon: <BsBuildingsFill style={{ fontSize: "20px" }} />,
    //   technicalName: "department",
    //   defaultValue: "",
    //   placeholder: "",
    //   tooltip: "",
    //   isRequired: false,
    //   isReadOnly: true,
    //   isTracking: false,
    //   isOnChange: false,
    //   isInvisible: false,
    //   isCreate: false,
    //   isEdit: false,
    //   isDelete: false,
    // },
    {
      type: "create_uid",
      label: "Created By",
      widget: "char",
      icon: <BsPersonFillAdd style={{ fontSize: "20px" }} />,
      technicalName: "create_uid",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: true,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "create_date",
      label: "Created Date",
      widget: "char",
      icon: <FaUserClock style={{ fontSize: "20px" }} />,
      technicalName: "create_date",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: true,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "write_uid",
      label: "Modified By",
      widget: "char",
      icon: <BsPersonFillCheck style={{ fontSize: "20px" }} />,
      technicalName: "write_uid",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: true,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
    {
      type: "write_date",
      label: "Modified Date",
      widget: "char",
      icon: <FaUserClock style={{ fontSize: "20px" }} />,
      technicalName: "write_date",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: true,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
    },
  ];

  // useEffect for updating the buttons
  useEffect(() => {
    if (shapeId) {
      const currentNode = nodes.find((node) => node.id === shapeId);
      if (currentNode?.type === "webform") {
        setWebFormNode(true);
      } else {
        setWebFormNode(false);
      }
      if (
        currentNode &&
        currentNode.data.isFormSelected &&
        currentNode.data.form
      ) {
        // setKanabanFieldBtn(
        //   parseFormData(currentNode.data.form.formbuilder_data)
        // );
        setFormExistingField(
          parseFormDataWithoutTableO2m(currentNode.data.form.formbuilder_data)
        );
      }
    }
  }, [shapeId, nodes]);

  // method for dragging kanban field buttons
  const handleKanbanFieldDragStart = (
    e,
    title,
    technicalName,
    type,
    widget,
    tooltip,
    isRequired,
    isReadOnly,
    isInvisible
  ) => {
    const data = {
      title,
      technicalName,
      type,
      widget,
      tooltip,
      isRequired,
      isReadOnly,
      isInvisible,
    };
    e.dataTransfer.setData("text/plain", JSON.stringify(data));
  };
  // method for dragging form buttons
  const handleDragStart = (
    e,
    type,
    title,
    widget,
    defaultValue,
    technicalName,
    placeholder,
    tooltip,
    isRequired,
    isReadOnly,
    isTracking,
    isOnChange,
    isInvisible,
    isCreate,
    isEdit,
    isDelete
  ) => {
    const data = {
      type,
      title,
      widget,
      defaultValue,
      technicalName,
      placeholder,
      tooltip,
      isRequired,
      isReadOnly,
      isTracking,
      // isCreatedBy,
      // isLoggedinUser,
      isOnChange,
      isInvisible,
      isCreate,
      isEdit,
      isDelete,
    };
    e.dataTransfer.setData("text/plain", JSON.stringify(data));
  };

  console.log("form fields button:: ", formExistingField);

  return (
    <div className="fb_control">
      {/* <h3>{isKanbanBuilder ? "Kanban Panel" : "Form Panel"}</h3> */}
      <h3>{`${
        builderType[0].toUpperCase() + builderType.substring(1)
      } Panel`}</h3>
      {/* {isKanbanBuilder ? ( */}
      {["kanban", "list"].includes(builderType) && (
        <div className="form_field_btn_group">
          <span className="headSpan ms-2">Fields</span>
          <div className="fcontrol_btns">
            {formExistingField &&
              formExistingField.map((btn, btnIndex) => {
                if (
                  btn.type === "button" ||
                  btn.type === "object" ||
                  btn.type === "action"
                ) {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.title,
                          btn.technicalName,
                          btn.type,
                          btn.widget,
                          btn.tooltip,
                          btn.isRequired,
                          (btn.isReadOnly = true),
                          btn.isInvisible
                        )
                      }
                    >
                      <MdSmartButton className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.widget === "password") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.title,
                          btn.technicalName,
                          btn.type,
                          btn.widget,
                          btn.tooltip,
                          btn.isRequired,
                          (btn.isReadOnly = true),
                          btn.isInvisible
                        )
                      }
                    >
                      <CgPassword className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.widget === "email") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.title,
                          btn.technicalName,
                          btn.type,
                          btn.widget,
                          btn.tooltip,
                          btn.isRequired,
                          (btn.isReadOnly = true),
                          btn.isInvisible
                        )
                      }
                    >
                      <AiOutlineMail className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.widget === "boolean") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.title,
                          btn.technicalName,
                          btn.type,
                          btn.widget,
                          btn.tooltip,
                          btn.isRequired,
                          (btn.isReadOnly = true),
                          btn.isInvisible
                        )
                      }
                    >
                      <IoMdCheckboxOutline className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "char") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.title,
                          btn.technicalName,
                          btn.type,
                          btn.widget,
                          btn.tooltip,
                          btn.isRequired,
                          (btn.isReadOnly = true),
                          btn.isInvisible
                        )
                      }
                    >
                      <PiTextbox className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (
                  btn.type === "float" &&
                  !btn.technicalName.startsWith("x_total_x_o2b_")
                ) {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.title,
                          btn.technicalName,
                          btn.type,
                          btn.widget,
                          btn.tooltip,
                          btn.isRequired,
                          (btn.isReadOnly = true),
                          btn.isInvisible
                        )
                      }
                    >
                      <TbDecimal style={{ fontSize: "20px" }} />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "date") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.title,
                          btn.technicalName,
                          btn.type,
                          btn.widget,
                          btn.tooltip,
                          btn.isRequired,
                          (btn.isReadOnly = true),
                          btn.isInvisible
                        )
                      }
                    >
                      <MdDateRange className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "number" || btn.type === "integer") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.title,
                          btn.technicalName,
                          btn.type,
                          btn.widget,
                          btn.tooltip,
                          btn.isRequired,
                          (btn.isReadOnly = true),
                          btn.isInvisible
                        )
                      }
                    >
                      <img
                        src={NumBox}
                        alt="number icon"
                        style={{ width: "25px" }}
                      />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "text") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.title,
                          btn.technicalName,
                          btn.type,
                          btn.widget,
                          btn.tooltip,
                          btn.isRequired,
                          (btn.isReadOnly = true),
                          btn.isInvisible
                        )
                      }
                    >
                      <BsTextareaResize className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "selection") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.title,
                          btn.technicalName,
                          btn.type,
                          btn.widget,
                          btn.tooltip,
                          btn.isRequired,
                          (btn.isReadOnly = true),
                          btn.isInvisible
                        )
                      }
                    >
                      <HiOutlineSelector className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "binary") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.title,
                          btn.technicalName,
                          btn.type,
                          btn.widget,
                          btn.tooltip,
                          btn.isRequired,
                          (btn.isReadOnly = true),
                          btn.isInvisible
                        )
                      }
                    >
                      <FaFile style={{ fontSize: "17px", color: "#383838" }} />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "many2one") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.title,
                          btn.technicalName,
                          btn.type,
                          btn.widget,
                          btn.tooltip,
                          btn.isRequired,
                          (btn.isReadOnly = true),
                          btn.isInvisible
                        )
                      }
                    >
                      <TbRelationOneToMany className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "many2many") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.title,
                          btn.technicalName,
                          btn.type,
                          btn.widget,
                          btn.tooltip,
                          btn.isRequired,
                          (btn.isReadOnly = true),
                          btn.isInvisible
                        )
                      }
                    >
                      <TbRelationManyToMany className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (
                  [
                    "logged_in_user",
                    "create_uid",
                    "employee_id",
                    "department",
                    "create_date",
                    "write_uid",
                    "write_date",
                  ].includes(btn.type)
                ) {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.title,
                          btn.technicalName,
                          btn.type,
                          btn.widget,
                          btn.tooltip,
                          btn.isRequired,
                          (btn.isReadOnly = true),
                          btn.isInvisible
                        )
                      }
                    >
                      <AiOutlineAudit className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                }
                return null;
              })}
          </div>
        </div>
      )}

      {/* code for list panel */}
      {/* {builderType === "list" && (
        <div className="form_field_btn_group">
          <span className="headSpan ms-2">Fields</span>
          <div className="fcontrol_btns">
            {formExistingField &&
              formExistingField.map((btn, btnIndex) => {
                if (
                  btn.type === "button" ||
                  btn.type === "object" ||
                  btn.type === "action"
                ) {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.type,
                          btn.title,
                          btn.tooltip
                        )
                      }
                    >
                      <MdSmartButton className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "text" || btn.type === "char") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.type,
                          btn.title,
                          btn.tooltip
                        )
                      }
                    >
                      <PiTextbox className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "password") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.type,
                          btn.title,
                          btn.tooltip
                        )
                      }
                    >
                      <CgPassword className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "date") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.type,
                          btn.title,
                          btn.tooltip
                        )
                      }
                    >
                      <MdDateRange className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "number") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.type,
                          btn.title,
                          btn.tooltip
                        )
                      }
                    >
                      <img
                        src={NumBox}
                        alt="number icon"
                        style={{ width: "25px" }}
                      />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "textarea") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.type,
                          btn.title,
                          btn.tooltip
                        )
                      }
                    >
                      <BsTextareaResize className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "email") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.type,
                          btn.title,
                          btn.tooltip
                        )
                      }
                    >
                      <AiOutlineMail className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "selection") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleDragStart(e, btn.type, btn.title, btn.tooltip)
                      }
                    >
                      <HiOutlineSelector className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "binary") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleDragStart(e, btn.type, btn.title, btn.tooltip)
                      }
                    >
                      <FaFile style={{ fontSize: "17px", color: "#383838" }} />
                      <span>{btn.title}</span>
                    </button>
                  );
                }
                return null;
              })}
          </div>
        </div>
      )} */}

      {/* : */}
      {builderType === "form" && (
        <>
          {!isWebformNode && !isCustomModelForm ? (
            // &&
            // !isTodoFormBuilder &&
            // !isDocumentFormBuilder
            <div className="fb_form_panel">
              <span className="headSpan">Components</span>
              <div className="fcontrol_btns">
                {componentsBtn.map((btn) => (
                  <button
                    key={btn.type}
                    className={`${
                      isWebformNode &&
                      [
                        "ribbon",
                        "object",
                        "button",
                        "action",
                        "group",
                        "tab",
                        "table",
                        "chatter",
                        "remark_history",
                        "oe_avatar",
                        "static_image",
                      ].includes(btn.type)
                        ? "d-none"
                        : "fcontrol_btn"
                    }`}
                    // className="fcontrol_btn"
                    draggable
                    onDragStart={(e) =>
                      handleDragStart(
                        e,
                        btn.type,
                        btn.label,
                        btn.widget,
                        btn.defaultValue,
                        btn.technicalName,
                        btn.placeholder,
                        btn.tooltip,
                        btn.isRequired,
                        btn.isReadOnly,
                        btn.isTracking,
                        btn.isOnChange,
                        btn.isInvisible,
                        btn.isCreate,
                        btn.isEdit,
                        btn.isDelete
                      )
                    }
                  >
                    {btn.icon}
                    <span>{btn.label}</span>
                  </button>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="fb_form_panel">
            <span className="headSpan">Fields</span>

            <div className="fcontrol_btns">
              {fieldsBtn &&
                fieldsBtn.map((btn) => (
                  <button
                    key={btn.type}
                    className={`${
                      (isCustomModelForm &&
                        [
                          "logged_in_user",
                          "create_uid",
                          "employee_id",
                          "department",
                          "write_uid",
                          "create_date",
                          "write_date",
                        ].includes(btn.type)) ||
                      (isWebformNode &&
                        [
                          "many2many",
                          "many2one",
                          "one2many",
                          "html",
                          "float",
                          "logged_in_user",
                          "create_uid",
                          "employee_id",
                          "department",
                          "write_uid",
                          "create_date",
                          "write_date",
                        ].includes(btn.type)) ||
                      btn.widget === "image"
                        ? "d-none"
                        : "fcontrol_btn"
                    }`}
                    // className="fcontrol_btn"
                    draggable
                    onDragStart={(e) =>
                      handleDragStart(
                        e,
                        btn.type,
                        btn.label,
                        btn.widget,
                        btn.defaultValue,
                        btn.technicalName,
                        btn.placeholder,
                        btn.tooltip,
                        btn.isRequired,
                        btn.isReadOnly,
                        btn.isTracking,
                        btn.isOnChange,
                        btn.isInvisible,
                        btn.isCreate,
                        btn.isEdit,
                        btn.isDelete
                      )
                    }
                  >
                    {btn.icon}
                    <span>{btn.label}</span>
                  </button>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(FormControl);
