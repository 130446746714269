import React, { useEffect, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { CircleLoader } from "react-spinners";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../config";
import { useProcessContext } from "../../context/ProcessContext";
import { useFormContext } from "../../context/FormContext";
import CustomModelOverlay from "../../template/custom_model_overlay/CustomModelOverlay";
import CreateRecord from "../../template/custom_model_record_overlay/CreateRecord";
import UploadRecordFileOverlay from "../../template/custom_model_record_overlay/UploadRecordFileOverlay";

const Records = () => {
  const {
    userData,
    customModelRecords,
    setCustomModelRecords,
    topRightAlert,
    centerAlert,
    encryptData,
    fetchAllRecords,
  } = useProcessContext();
  const [loading, setLoading] = useState(true);
  const { customModelDetail } = useFormContext();
  const navigate = useNavigate();

  // Fetch all process data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show loading spinner
      await fetchAllRecords({
        key: API_KEY,
        database: customModelDetail?.database_obj,
        database_url: customModelDetail?.database_url,
        modelDetail: {
          model: customModelDetail?.model,
          model_id: customModelDetail?.id,
        },
      }); // Wait for the API call to finish
      setLoading(false); // Hide loading spinner after data is loaded
    };
    fetchData();
  }, [setCustomModelRecords, userData.userid]);

  const handleDelete = async (recordId) => {
    try {
      const payload = {
        key: API_KEY,
        database: customModelDetail?.database_obj,
        database_url: customModelDetail?.database_url,
        modelDetail: {
          model: customModelDetail?.model,
          model_id: customModelDetail?.id,
          record_id: recordId,
        },
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(
        `${BASE_URL}/custom-model/post/data/model/field/delete`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: encryptedData }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        // console.log("delete process: ", data);
        topRightAlert("success", data.message);
        await fetchAllRecords({
          key: API_KEY,
          database: customModelDetail.database_obj,
          database_url: customModelDetail.database_url,
          modelDetail: {
            model: customModelDetail.model,
            model_id: customModelDetail.id,
          },
          fields: { x_name: "" },
        });
      } else {
        const data = await response.json();
        centerAlert("error", data.message);
        console.error("Error deleting custom model:", data);
      }
    } catch (error) {
      centerAlert("error", "Internal Server Error");
      console.error("Error deleting custom model:", error);
    }
  };

  // const handleEditFieldClicked = async (e, modelId) => {
  //   e.preventDefault();
  //   try {
  //     const payload = {
  //       model_id: modelId,
  //       user_id: userData.userid,
  //       key: API_KEY,
  //     };
  //     const encryptedData = encryptData(payload, PAYLOAD_SECRET);
  //     const response = await fetch(`${BASE_URL}/custom-model/fetch/one`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ data: encryptedData }),
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       console.log("fetch response for custom model one data:: ", data);
  //       setCustomModelDetail({
  //         model_id: data.model._id,
  //         id: data.model.id,
  //         name: data.model.name,
  //         model: data.model.model,
  //         transient: data.model.transient,
  //         model_fields: data.model.model_fields,
  //         database_url: data.model.database_url,
  //         database: data.model.database,
  //         database_obj: data.model.database_obj,
  //       });
  //       setBuilderType("form");
  //       setIsCustomModelForm(true);
  //       setNewFormId(null);
  //       setFormData([...data.model.model_fields]);
  //       // setFormData([]);
  //       navigate("/form-builder");
  //     } else {
  //       const data = await response.json();
  //       centerAlert("error", data.message);
  //       console.error("Error fetching one custom model detail:", data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching one custom model detail:", error);
  //     centerAlert("error", error);
  //   }
  //   // setBuilderType("form");
  //   // setIsCustomModelForm(true);
  //   // setNewFormId(null);
  //   // // setFormData([...formFields]);
  //   // setFormData([]);
  //   // navigate("/form-builder");
  // };

  if (customModelDetail === null){
    navigate("/");
  }
    if (loading)
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%" }}
        >
          <CircleLoader color="#007485" />
        </div>
      );

  return (
    <div className="donor_dash_right">
      <div className="dashboard_buttons">
        <button data-bs-toggle="modal" data-bs-target="#createRecordModal">
          Create Record
        </button>
        <button 
        // style={{cursor: "not-allowed"}}
        // title="Coming Soon Feature"
        type="button"
        data-bs-toggle="modal" data-bs-target="#uploadRecordFileModal"
        >
          Upload Record File
        </button>
      </div>
      {/* Modal */}
      <CreateRecord />
      <UploadRecordFileOverlay />
      {/* over modal code */}
      <div className="donor_dash_deatil">
        <div className="donor_kit">
          <h2 className="table_caption_head">List of existing records</h2>
          <div className="donor_kit_desc" />
          <div className="donor_table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Record Id</th>
                  <th>Model</th>
                  <th>Name</th>
                  {/* <th>Transient</th> */}
                  {/* <th>Hutch (database)</th>
                  <th>Fields</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(customModelRecords) &&
                customModelRecords.length > 0 ? (
                  customModelRecords.map((record, index) => (
                    <tr key={`${record.id}_${index}`}>
                      <th>{index + 1}</th>
                      <td>{record.id}</td>
                      <td>{record.model}</td>
                      <td>{record.name}</td>
                      {/* <td>{model.transient ? "True" : "False"}</td>
                      <td>{model.database}</td> */}
                      {/* <td className="d-flex justify-content-center align-items-center gap-1">
                        <button
                          className="configure_connect_button"
                          onClick={(e) => handleEditFieldClicked(e, model._id)}
                        >
                          Edit Fields
                        </button>
                        <Link to="/dashboard/configurations/model/records">
                          <button
                            className="configure_connect_button"
                            // onClick={(e) => handleEditFieldClicked(e, model._id)}
                          >
                            Records
                          </button>
                        </Link>
                      </td> */}

                      <td className="td_action_cell">
                        <RiDeleteBin5Line
                          className="dash_table_icon_del"
                          onClick={() => handleDelete(record.id)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr key={userData.userid}>
                    <td colSpan={5} className="text-center ">
                      No existing records...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Records;
