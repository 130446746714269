import React, { memo, useEffect } from "react";
import { useProcessContext } from "../../context/ProcessContext";
import { useNodeContext } from "../../context/NodeContext";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../config";
import { CircleLoader } from "react-spinners";
import ProcessOverlay from "../../template/process_modeler_overlay/ProcessOverlay";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import CustomModelOverlay from "../../template/custom_model_overlay/CustomModelOverlay";
import { useFormContext } from "../../context/FormContext";

const Models = () => {
  const {
    userData,
    customModels,
    setCustomModels,
    topRightAlert,
    centerAlert,
    encryptData,
    fetchAllCustomModel,
  } = useProcessContext();
  const { setNodes } = useNodeContext();
  const [loading, setLoading] = useState(true);
  const {
    setBuilderType,
    setFormData,
    isCustomModelForm,
    setIsCustomModelForm,
    setNewFormId,
    customModelDetail,
    setCustomModelDetail,
  } = useFormContext();
  const navigate = useNavigate();

  // Fetch all process data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show loading spinner
      await fetchAllCustomModel(userData.userid); // Wait for the API call to finish
      setLoading(false); // Hide loading spinner after data is loaded
    };
    fetchData();
  }, [setCustomModels, userData.userid]);

  const handleDelete = async (modelId) => {
    try {
      const payload = {
        user_id: userData.userid,
        model_id: modelId,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/custom-model/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log("delete process: ", data);
        topRightAlert("success", data.message);
        fetchAllCustomModel(userData.userid);
      } else {
        const data = await response.json();
        centerAlert("error", data.message);
        console.error("Error deleting custom model:", data);
      }
    } catch (error) {
      centerAlert("error", "Internal Server Error");
      console.error("Error deleting custom model:", error);
    }
  };
  // const handleEdit = async (processId) => {
  //   if (processId !== null) {
  //     try {
  //       const response = await fetch(`${BASE_URL}/custom-model/fetch`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           process_id: processId,
  //           user_id: userData.userid,
  //         }),
  //       });

  //       if (response.ok) {
  //         const data = await response.json();

  //         navigate("/process-modeler");
  //       } else {
  //         const data = await response.json();
  //         console.error("Error updating custom model status:", data);
  //       }
  //     } catch (error) {
  //       console.error("Error updating custom model status:", error);
  //     }
  //   }
  // };

  // method for edit field button clicked
  const handleEditFieldClicked = async (e, modelId) => {
    e.preventDefault();
    try {
      const payload = {
        model_id: modelId,
        user_id: userData.userid,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/custom-model/fetch/one`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("fetch response for custom model one data:: ", data);
        setCustomModelDetail({
          model_id: data.model._id,
          id: data.model.id,
          name: data.model.name,
          model: data.model.model,
          transient: data.model.transient,
          model_fields: data.model.model_fields,
          database_url: data.model.database_url,
          database: data.model.database,
          database_obj: data.model.database_obj,
        });
        setBuilderType("form");
        setIsCustomModelForm(true);
        setNewFormId(null);
        setFormData([...data.model.model_fields]);
        // setFormData([]);
        navigate("/form-builder");
      } else {
        const data = await response.json();
        centerAlert("error", data.message);
        console.error("Error fetching one custom model detail:", data);
      }
    } catch (error) {
      console.error("Error fetching one custom model detail:", error);
      centerAlert("error", error);
    }
    // setBuilderType("form");
    // setIsCustomModelForm(true);
    // setNewFormId(null);
    // // setFormData([...formFields]);
    // setFormData([]);
    // navigate("/form-builder");
  };

  // method for record button clicked
  const handleRecordBtnClicked = async (e, modelId) => {
    e.preventDefault();
    try {
      const payload = {
        model_id: modelId,
        user_id: userData.userid,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/custom-model/fetch/one`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("fetch response for custom model one data:: ", data);
        setCustomModelDetail({
          model_id: data.model._id,
          id: data.model.id,
          name: data.model.name,
          model: data.model.model,
          transient: data.model.transient,
          model_fields: data.model.model_fields,
          database_url: data.model.database_url,
          database: data.model.database,
          database_obj: data.model.database_obj,
        });
        navigate("/dashboard/configurations/model/records");
      } else {
        const data = await response.json();
        centerAlert("error", data.message);
        console.error("Error fetching one custom model detail:", data);
      }
    } catch (error) {
      console.error("Error fetching one custom model detail:", error);
      centerAlert("error", error);
    }
  };

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%" }}
      >
        <CircleLoader color="#007485" />
      </div>
    );

  return (
    <div className="donor_dash_right">
      <div className="dashboard_buttons">
        <button data-bs-toggle="modal" data-bs-target="#customModelModal">
          Create Model
        </button>
      </div>
      {/* Modal */}
      <CustomModelOverlay />
      {/* over modal code */}
      <div className="donor_dash_deatil">
        <div className="donor_kit">
          <h2 className="table_caption_head">List of existing models</h2>
          <div className="donor_kit_desc" />
          <div className="donor_table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Model Id</th>
                  <th>Model Name</th>
                  <th>Model</th>
                  <th>Transient</th>
                  <th>Hutch (database)</th>
                  <th>Fields</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {customModels.length > 0 ? (
                  customModels.map((model, index) => (
                    <tr key={`${model.model_id}_${index}`}>
                      <th>{index + 1}</th>
                      <td>{model.id}</td>
                      <td>{model.name}</td>
                      <td>{model.model}</td>
                      <td>{model.transient ? "True" : "False"}</td>
                      <td>{model.database}</td>
                      <td className="d-flex justify-content-center align-items-center gap-1">
                        <button
                          className="configure_connect_button"
                          onClick={(e) => handleEditFieldClicked(e, model._id)}
                        >
                          Edit Fields
                        </button>
                        {/* <Link to="/dashboard/configurations/model/records"> */}
                        <button
                          type="button"
                          className="configure_connect_button"
                          // title="Coming Soon Feature"
                          onClick={(e) => handleRecordBtnClicked(e, model._id)}
                          // style={{ cursor: "not-allowed" }}
                        >
                          Records
                        </button>
                        {/* </Link> */}
                      </td>
                      {/* <td>
                        <div
                          className="form-check form-switch"
                          style={{ paddingLeft: "3.5rem" }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheckDefault-${process.process_id}`}
                            checked={process.odoo_checking_status}
                            // onChange={(e) =>
                            //   handleStatusChange(
                            //     process.process_id,
                            //     e.target.checked
                            //   )
                            // }
                          />
                        </div>
                      </td> */}
                      {/* <td className="td_action_cell">
                        <div
                          className="form-check form-switch"
                          style={{ paddingLeft: "3.5rem" }}
                        >
                          <input
                            className="form-check-input scheduler_action_btn"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheckDefault-${process.process_id}-scheduler`}
                            checked={process.schedular_status}
                            // onChange={(e) =>
                            //   handleSchedulerStatusChange(
                            //     process.process_id,
                            //     e.target.checked
                            //   )
                            // }
                          />
                        </div>
                      </td> */}
                      <td className="td_action_cell">
                        {/* <CiEdit
                          className="dash_table_icon_edit"
                          onClick={() => handleEdit(model._id)}
                        /> */}
                        <RiDeleteBin5Line
                          className="dash_table_icon_del"
                          onClick={() => handleDelete(model._id)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr key={userData.userid}>
                    <td colSpan={7} className="text-center ">
                      No existing models...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Models);
