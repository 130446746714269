import React from "react";
import "./SuccessAnimation.css";

const SuccessAnimation = ({ color }) => {
  return (
    <div className="animation_content">
      {/* <svg width="400" height="400">
        <circle
          fill="none"
          stroke={color || "#68E534"}
          stroke-width="20"
          cx="200"
          cy="200"
          r="190"
          strokeLinecap="round"
          transform="rotate(-90 200 200)"
          className="animation_circle"
        />
        <polyline
          fill="none"
          stroke={color || "#68E534"}
          points="88,214 173,284 304,138"
          strokeWidth="24"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animation_tick"
        />
      </svg> */}
      <svg width="70" height="70" viewBox="0 0 400 400">
        <circle
          fill="none"
          stroke={color || "#68E534"}
          stroke-width="20"
          cx="200"
          cy="200"
          r="190"
          strokeLinecap="round"
          transform="rotate(-90 200 200)"
          className="animation_circle"
        />
        <polyline
          fill="none"
          stroke={color || "#68E534"}
          points="88,214 173,284 304,138"
          strokeWidth="24"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animation_tick"
        />
      </svg>
    </div>
  );
};

export default SuccessAnimation;
