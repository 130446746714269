import React from "react";
import "./LogOut.css";
import { useProcessContext } from "../../context/ProcessContext";
import { useNavigate } from "react-router-dom";
import { useNodeContext } from "../../context/NodeContext";
import { useFormContext } from "../../context/FormContext";
import BASE_URL from "../../../config";

const LogOut = () => {
  const { logout, setLogoutClicked, userData } = useProcessContext();
  const { setShapeId } = useNodeContext();
  const { setForms, setFormData } = useFormContext();
  const navigate = useNavigate();

  const logOutCall = async () => {
    const response = await fetch(`${BASE_URL}/user/logout`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userId: userData?._id }),
    });
    const data = await response.json();
    if (response.ok) {
      console.log("data logout::: ", data);
    } else {
      // localStorage.removeItem("token");
      console.log("data logout::: ", data);
    }
  };

  const handleLogout = () => {
    logOutCall();
    setShapeId(null);
    setForms([]);
    setFormData([]);
    logout();
    setLogoutClicked(false);
    navigate("/");
  };
  return (
    <div className="logout">
      <span className="logout_quit_btn" onClick={() => setLogoutClicked(false)}>
        X
      </span>
      <button className="logout_button" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

export default LogOut;
