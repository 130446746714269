import xmlbuilder from "xmlbuilder";

function generateOdooDomain(conditions) {
  if (!conditions || conditions.length === 0) return "";

  let domain = "[";

  conditions.forEach((condition, index) => {
    if (Array.isArray(condition)) {
      const value = condition[2].startsWith("x_o2b_")
        ? condition[2]
        : `"${condition[2]}"`;
      // Convert each condition array to a tuple string
      domain += `("${condition[0]}", "${condition[1]}", ${value})`;
      // domain += `("${condition[0]}", "${condition[1]}", ${condition[2]})`;
    } else if (condition === "&" || condition === "|") {
      // Add logical operators (& or |)
      domain += ` ${condition} `;
    }

    // Add commas between conditions if not the last element
    if (index !== conditions.length - 1) {
      domain += ",";
    }
  });

  domain += "]"; // Close the domain array

  return domain;
}

const generateOdooFormViewXml = (
  jsonData,
  stateName,
  statePrevName,
  processName,
  maxTitleLength,
  isReferenceNumber
) => {
  const formAttributes = { string: "Example Form", delete: "false" };
  const formElement = xmlbuilder.create("form", formAttributes);
  let isChatter = false;
  const findSmartButtonIndex = (arrays) => {
    for (let outerIndex = 0; outerIndex < arrays.length; outerIndex++) {
      const innerArray = arrays[outerIndex];
      const found = innerArray.some(
        (obj) =>
          obj.type === "object" &&
          ["default-statinfo", "statinfo"].includes(obj.widget)
      );
      if (found) {
        return outerIndex;
      }
    }
    return -1;
  };

  // Add header with statusbar field
  const headerElement = formElement.ele("header");
  let stausbarVisible;
  if (statePrevName && statePrevName !== null && statePrevName !== undefined) {
    stausbarVisible = `${statePrevName},${stateName}`;
  } else {
    stausbarVisible = stateName;
  }
  const statusbarElementAttributes = {
    name: "x_o2b_stage",
    widget: "statusbar",
    statusbar_visible: stausbarVisible,
  };
  if (isReferenceNumber) {
    const referenceBlock = headerElement.ele("div", {
      class: "d-flex justify-content-start align-items-start gap-2",
    });
    referenceBlock.ele("span", { style: "font-weight: 500" }, "Reference No:");
    referenceBlock.ele("span", { style: "font-weight: 500" }).ele("field", {
      name: "x_reference_no",
      nolabel: "1",
      readonly: "1",
    });
  }
  headerElement.ele("field", statusbarElementAttributes);
  // style for all label field
  const labelStyle = {
    style: {
      "#text": `
        label.o_form_label, .total_field_label {
            width: ${maxTitleLength}ch;
            min-width: max-content;
            text-align: left; 
            white-space: nowrap;
        }
        .no-label .o_form_label {
            display: none;
        }
        .requiredField label::after {
          content: "* ";
          color: red;
      }
      `,
    },
  };
  // creating hidden field for remark user
  formElement.ele("field", { name: "remark_user", invisible: "1" });
  // Create sheet element
  const sheetElement = formElement.ele("sheet");
  sheetElement.ele(labelStyle);

  // Adding ribbon in form view
  if (Array.isArray(jsonData[0]) && jsonData[0][0].type === "ribbon") {
    sheetElement.ele("widget", {
      name: "web_ribbon",
      title: jsonData[0][0].title || "Archived",
      bg_color: jsonData[0][0].ribbonBgColor || "bg-danger",
    });
  }
  // Adding smart button in form view
  const smartButtonBox = sheetElement.ele("div", {
    class: "oe_button_box",
    name: "button_box",
  });
  const smartIndex = findSmartButtonIndex(jsonData);
  if (smartIndex !== -1) {
    const parentElement = smartButtonBox;
    // const parentElement = sheetElement.ele("div", {
    //   class: "oe_button_box",
    //   name: "button_box",
    // });
    jsonData[smartIndex].forEach((col) => {
      parentElement.ele("button", {
        name: col.technicalName,
        string: col.title,
        type: "button",
        class: "oe_stat_button",
        icon: col.smartIcon,
      });
    });
  }
  smartButtonBox.ele("todolist");
  smartButtonBox.ele("checklist");

  // method for group permission attrs
  // Method for group permission attrs
  // const generatePermissionFieldXML = (
  //   fieldData,
  //   fieldAttributes,
  //   parentElement
  // ) => {
  //   const attrs = {};
  //   const readonlyConditions = [];
  //   const invisibleConditions = [];

  //   // Process group permissions
  //   fieldData.groupVisible.forEach((groupObj) => {
  //     const groupName = groupObj.group;
  //     const permission = groupObj.perm;

  //     if (permission === "read") {
  //       readonlyConditions.push(
  //         `('user_id.groups_id', 'in', [ref('${groupName}')])`
  //       );
  //     }

  //     invisibleConditions.push(
  //       `('user_id.groups_id', 'not in', [ref('${groupName}')])`
  //     );
  //   });

  //   // Combine readonly conditions
  //   if (readonlyConditions.length > 1) {
  //     attrs.readonly = `[('|', ${readonlyConditions.join(", ")})]`;
  //   } else if (readonlyConditions.length === 1) {
  //     attrs.readonly = `[${readonlyConditions[0]}]`;
  //   }

  //   // Combine invisible conditions
  //   if (invisibleConditions.length > 1) {
  //     const invisibleExpression = invisibleConditions
  //       .slice(1)
  //       .reduce(
  //         (acc, condition) => `('&', ${acc}, ${condition})`,
  //         invisibleConditions[0]
  //       );
  //     attrs.invisible = `[${invisibleExpression}]`;
  //   } else if (invisibleConditions.length === 1) {
  //     attrs.invisible = `[${invisibleConditions[0]}]`;
  //   }

  //   // Convert attributes to Odoo-compatible XML format
  //   const attrsString = Object.entries(attrs)
  //     .map(([key, value]) => `'${key}': ${value}`)
  //     .join(", ");

  //   // Adjust fieldAttributes
  //   if (attrsString && fieldAttributes.readonly) {
  //     delete fieldAttributes.readonly;
  //   }
  //   delete fieldAttributes.groups;

  //   // Create XML
  //   parentElement.ele("field", {
  //     ...fieldAttributes,
  //     ...(attrsString ? { attrs: `{${attrsString}}` } : {}),
  //   });
  // };

  // process field code
  const processField = (field, parentElement) => {
    const fieldAttributes = {
      // string: `${field.title}${field.isRequired === true ? "*" : ""}`,
      string: `${field.title}`,
      placeholder: field.placeholder,
      help: field.tooltip,
    };

    switch (field.type) {
      case "create_uid":
        fieldAttributes.name = "create_uid";
        break;
      case "employee_id":
        fieldAttributes.name = "employee_id";
        break;
      case "department":
        fieldAttributes.name = "department";
        break;
      case "create_date":
        fieldAttributes.name = "create_date";
        break;
      case "write_uid":
        fieldAttributes.name = "write_uid";
        break;
      case "write_date":
        fieldAttributes.name = "write_date";
        break;
      case "logged_in_user":
        fieldAttributes.name = "logged_in_user";
        break;

      default:
        fieldAttributes.name = field.technicalName;
        fieldAttributes.widget = field.widget;
        break;
    }
    // }
    if (Array.isArray(field.groupVisible) && field.groupVisible.length > 0)
      fieldAttributes.groups = field.groupVisible.join(",");
    if (field.widget === "password") fieldAttributes.password = "True";
    if (field.widget === "file")
      fieldAttributes.filename = `${field.technicalName}_filename`;
    let tableFieldName;
    let tableFieldAttributes;
    if (typeof field !== "object") {
      tableFieldName = field;
      // tableFieldName = "x_o2b_" + field.toLowerCase();
      tableFieldAttributes = { name: tableFieldName, string: field };
      if (Array.isArray(field.groupVisible) && field.groupVisible.length > 0)
        tableFieldAttributes.groups = field.groupVisible.join(",");
    }
    if (["many2many", "many2one", "one2many"].includes(field.type)) {
      const fieldDomain = generateOdooDomain(field.domain);

      // const opts = {
      //   no_create: field.isCreate ? "False" : "True",
      //   no_edit: field.isEdit ? "False" : "True",
      //   no_delete: field.isDelete ? "False" : "True",
      // };
      // const opts = {
      //   no_create: !field.isCreate,
      //   no_edit: !field.isEdit,
      //   no_delete: !field.isDelete,
      // };
      if (field.type === "many2one")
        fieldAttributes.options = JSON.stringify(
          field.isCreate ? { no_create: 0 } : { no_create: 1 }
        );
      if (field.type === "many2many") {
        const opts = {
          no_create: field.isCreate ? 0 : 1,
          no_edit: field.isEdit ? 0 : 1,
          no_delete: field.isDelete ? 0 : 1,
        };
        fieldAttributes.options = JSON.stringify(opts);
      }
      if (field.type === "one2many") {
        const opts = {
          no_edit: field.isEdit ? 0 : 1,
          no_delete: field.isDelete ? 0 : 1,
        };
        fieldAttributes.options = JSON.stringify(opts);
      }
      // const opts = {
      //   no_create: field.isCreate ? 0 : 1,
      //   no_edit: field.isEdit ? 0 : 1,
      //   no_delete: field.isDelete ? 0 : 1,
      // };
      fieldAttributes.domain = `${fieldDomain}`;
      // fieldAttributes.options = JSON.stringify(opts);
      // fieldAttributes.create = `${field.isCreate ? "true" : "false"}`;
      // fieldAttributes.edit = `${field.isEdit ? "true" : "false"}`;
      // fieldAttributes.delete = `${field.isDelete ? "true" : "false"}`;
    }
    // if (field.isRequired) fieldAttributes.required = "1";
    if (field.isReadOnly) fieldAttributes.readonly = "1";
    if (field.isInvisible) fieldAttributes.invisible = "1";
    if (field.type === "oe_avatar") fieldAttributes.class = "oe_avatar";
    if (
      field.type === "object" &&
      ["default-statinfo", "statinfo"].includes(field.widget)
    ) {
      return;
    }
    if (field.type === "many2one" && field.widget === "res_partner_many2one") {
      fieldAttributes.context =
        "{'res_partner_search_mode': 'customer', 'show_address': 1, 'show_vat': True}";
      fieldAttributes.options = `{"always_reload": True}`;
      // fieldAttributes.options = "{&quot;always_reload&quot;: True}";
    }
    // if (field.widget === "remark_field") {
    //   return;
    // }
    if (field.widget === "file") {
      parentElement.ele("field", {
        name: `${field.technicalName}_filename`,
        invisible: "1",
      });
    }
    if (field.widget === "one2many") fieldAttributes.widget = "one2many_list";
    // if (
    //   typeof field === "object" &&
    //   Array.isArray(field.groupVisible) &&
    //   field.groupVisible.length > 0
    // ) {
    //   generatePermissionFieldXML(field, fieldAttributes, parentElement);
    //   return;
    // }
    parentElement.ele(
      "field",
      typeof field === "object" ? fieldAttributes : tableFieldAttributes
    );
  };

  // process label code
  const processLabel = (field, parentElement) => {
    const labelAttributes = {};
    if (field.isInvisible) labelAttributes.invisible = "1";
    if (Array.isArray(field.groupVisible) && field.groupVisible.length > 0)
      labelAttributes.groups = field.groupVisible.join(",");
    const alignmentMap = {
      center: "center",
      right: "flex-end",
      left: "flex-start",
    };

    labelAttributes.style = `width: 100%; display: flex; flex-direction: row; justify-content: ${
      alignmentMap[field.alignment] || "flex-start"
    };`;

    const divBox = parentElement.ele("div", labelAttributes);
    const spanFontSize = field?.fontSize || "24";
    const spanFontWeight = field?.fontWeight || "500";
    const spanAttributes = {
      style: `font-size: ${spanFontSize}px; font-weight: ${spanFontWeight}`,
    };

    divBox.ele("span", spanAttributes, field?.title);
  };
  // process static form image code
  const processStaticImage = (field, parentElement) => {
    const staticImageAttributes = {};
    if (field.isInvisible) staticImageAttributes.invisible = "1";

    const alignmentMap = {
      center: "center",
      right: "flex-end",
      left: "flex-start",
    };

    staticImageAttributes.style = `width: 100%; display: flex; flex-direction: row; justify-content: ${
      alignmentMap[field.alignment] || "flex-start"
    };`;

    const divBox = parentElement.ele("div", staticImageAttributes);
    const imgAttributes = {
      src: field.defaultValue,
      width: "100px",
      height: "100px",
      border: "1px solid #383838",
      alt: `${field.title}`,
    };

    divBox.ele("img", imgAttributes);
  };

  // process row for form code
  const processRow = (row, parentElement) => {
    const isSomeButtonInRow = row?.some((col) =>
      ["button", "object", "action"].includes(col.type)
    );
    const isEveryButtonInRow = row?.every((col) =>
      ["button", "object", "action"].includes(col.type)
    );
    const isSomeLabelInRow = row?.some((col) => col.type === "label");
    const isSomeStaticImageInRow = row?.some(
      (col) => col.type === "static_image"
    );

    // console.log("isSomeStaticImageInRow::: ", isSomeStaticImageInRow);
    let btnGroupElement;
    let groupElement;
    if (isEveryButtonInRow) {
      btnGroupElement = parentElement.ele("div", {
        style:
          "width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center;",
      });
    } else if (
      isSomeButtonInRow ||
      isSomeLabelInRow ||
      isSomeStaticImageInRow
    ) {
      btnGroupElement = parentElement.ele("div", {
        style:
          "width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center;",
      });
      groupElement = parentElement.ele("group", { col: row.length });
    } else {
      groupElement = parentElement.ele("group", { col: row.length });
    }

    row.forEach((col) => {
      if (
        ["button", "object", "action"].includes(col.type) &&
        !["default-statinfo", "statinfo"].includes(col.widget)
      ) {
        const btnContainerAttributes = {};
        const alignmentMap = {
          center: "center",
          right: "flex-end",
          left: "flex-start",
        };
        btnContainerAttributes.style = `width:100%; display: flex; flex-direction: row; justify-content: ${
          alignmentMap[col.alignment] || "flex-start"
        };`;
        if (col.isInvisible) btnContainerAttributes.invisible = "1";
        const colElement = btnGroupElement.ele("div", btnContainerAttributes);
        const buttonAttributes = {
          name: col.technicalName,
          string: col.title,
          type: col.type,
          class: col.odooButtonColor || "btn-outline-dark",
        };
        if (Array.isArray(col.groupVisible) && col.groupVisible.length > 0)
          buttonAttributes.groups = col.groupVisible.join(",");
        colElement.ele("button", buttonAttributes);
      } else if (
        col.type === "object" &&
        ["default-statinfo", "statinfo"].includes(col.widget)
      ) {
        return;
      } else if (col.type === "table") {
        const tableFieldAttributes = {
          name: col.tableRelatedField,
          string: col.title,
          help: col.tooltip,
        };
        if (col.isInvisible) tableFieldAttributes.invisible = "1";
        if (Array.isArray(col.groupVisible) && col.groupVisible.length > 0)
          tableFieldAttributes.groups = col.groupVisible.join(",");
        const colElement =
          col.technicalName === "x_o2b_remark_table"
            ? groupElement
                .ele("group", { class: "no-label" })
                .ele("field", {
                  ...tableFieldAttributes,
                  mode: "tree",
                  options:
                    "{'no_create': True, 'no_edit': True, 'no_delete': True}",
                  readonly: "1",
                })
                .ele("tree", {
                  edit: "false",
                  create: "false",
                  delete: "false",
                })
            : groupElement
                .ele("field", tableFieldAttributes)
                .ele("tree", { editable: "bottom" });
        // console.log("col data: ", col);
        if (col.columns.length > 0) {
          col.columns.forEach((column) => {
            processField(column, colElement);
          });
        }

        // if total field is available
        if (col.isTotalField) {
          // const totalElementGroup = parentElement.ele("group", {
          //   class: "oe_right",
          // });
          // const totalField = groupElement.ele("div", {
          // const totalField = totalElementGroup.ele("div", {
          //   class: "d-flex justify-content-center gap-2",
          // });
          const totalField = parentElement.ele("div", {
            class: "d-flex justify-content-end gap-2",
          });
          totalField.ele(
            "div",
            { class: "total_field_label", style: "font-size: 16px;" },
            `Total ${
              col?.columns?.find(
                (field) => field?.technicalName === col?.totalfield_field
              )?.title
            }: `
          );
          totalField.ele("strong", { style: "font-size: 16px;" }).ele("field", {
            name: `x_total_${col?.tableRelatedField}`,
            readonly: "1",
            nolabel: "1",
          });
          parentElement.ele("separator");
        }
        // processField(col.columns,colElement)
      } else if (col.type === "separator") {
        processSeparator(col, groupElement);
      } else if (col.type === "ribbon") {
        // processSeparator(col, groupElement);
        return;
      } else if (col.type === "oe_avatar") {
        parentElement.ele("field", {
          name: col.technicalName,
          class: "oe_avatar",
          widget: "image",
        });
      } else if (col.type === "label") {
        const containerElement = btnGroupElement
          ? btnGroupElement
          : groupElement;
        processLabel(col, containerElement);
        // processLabel(col, groupElement);
        // processLabel(col, parentElement);
      } else if (col.type === "static_image") {
        const containerElement = btnGroupElement
          ? btnGroupElement
          : groupElement;
        processStaticImage(col, containerElement);
        // processStaticImage(col, groupElement);
        // processStaticImage(col, parentElement);
      } else {
        const colElement = col.isRequired
          ? groupElement.ele("group", { class: "requiredField" })
          : groupElement.ele("group");
        processField(col, colElement);
      }
    });
  };
  // process separator code
  const processSeparator = (field, parentElement) => {
    const separatorAttributes = { help: field.tooltip };
    if (field.isInvisible) separatorAttributes.invisible = "1";
    if (Array.isArray(field.groupVisible) && field.groupVisible.length > 0)
      separatorAttributes.groups = field.groupVisible.join(",");
    parentElement.ele("separator", separatorAttributes);
  };

  // process group code
  const processGroup = (groupField, parentElement) => {
    const mainGroupAttributes = {
      col: 1,
      string: groupField.title,
      class: "border border-1 mb-3",
    };
    if (groupField.isInvisible) mainGroupAttributes.invisible = "1";
    if (
      Array.isArray(groupField.groupVisible) &&
      groupField.groupVisible.length > 0
    )
      mainGroupAttributes.groups = groupField.groupVisible.join(",");
    const mainGroupElement = parentElement.ele("group", mainGroupAttributes);
    groupField.fields.forEach((row) => {
      processRow(row, mainGroupElement);
    });
  };

  // process tab code
  const processTab = (tab, parentElement) => {
    const tabAttributes = {};
    if (tab.isInvisible) tabAttributes.invisible = "1";
    if (tab.type === "remark_history") tabAttributes.nolabel = "1";

    if (Array.isArray(tab.groupVisible) && tab.groupVisible.length > 0)
      tabAttributes.groups = tab.groupVisible.join(",");
    const notebookElement = parentElement.ele("notebook", tabAttributes);
    tab.tabs.forEach((tabPage) => {
      const pageAttributes = { string: tabPage.tabName };
      if (tabPage.isInvisible) pageAttributes.invisible = "1";
      if (
        Array.isArray(tabPage.groupVisible) &&
        tabPage.groupVisible.length > 0
      )
        pageAttributes.groups = tabPage.groupVisible.join(",");
      const pageElement = notebookElement.ele("page", pageAttributes);
      tabPage.content.forEach((row) => {
        if (row[0]?.type === "group") {
          processGroup(row[0], pageElement);
        } else {
          processRow(row, pageElement);
        }
      });
    });
  };

  // process chatter code
  const processChatter = (parentElement) => {
    // Create the root element
    const chatterXML = parentElement.ele("div", { class: "oe_chatter" });
    chatterXML.ele("field", {
      name: "message_follower_ids",
      widget: "mail_followers",
    });
    chatterXML.ele("field", { name: "message_ids", widget: "mail_thread" });
    chatterXML.ele("field", { name: "activity_ids" });
    chatterXML.ele("field", { name: "message_attachment_count" });
  };

  // main code for running all field
  jsonData.forEach((row) => {
    if (row.length > 1) {
      processRow(row, sheetElement);
    } else {
      const field = row[0];
      if (["tab", "remark_history"].includes(field.type)) {
        processTab(field, sheetElement);
      } else if (field.type === "group") {
        processGroup(field, sheetElement);
      } else if (field.type === "chatter") {
        isChatter = true;
      } else {
        processRow([field], sheetElement);
      }
    }
  });

  // Adding chatter in the form view
  if (isChatter) {
    processChatter(formElement);
  }

  return formElement.end({ pretty: true });
};

export default generateOdooFormViewXml;
