import React, { useState, useEffect, memo, useCallback } from "react";
import Select from "react-select";
import "./FormDetailUpdate.css";
import { CiEdit } from "react-icons/ci";
import {
  MdDeleteOutline,
  MdEditNote,
  MdOutlineCheck,
  MdOutlineClose,
  MdOutlineEditNote,
} from "react-icons/md";
import { useFormContext } from "../../../context/FormContext";
import { useNavigate } from "react-router-dom";
import { GiCancel } from "react-icons/gi";
import BASE_URL, { API_KEY } from "../../../../config";
import { useProcessContext } from "../../../context/ProcessContext";
import DomainComponent from "../../../template/domain_component/DomainComponent";
import { TbPointFilled } from "react-icons/tb";
import CustomDropdown from "../../../template/custom_dropdown/CustomDropdown";
import { RxDragHandleDots2 } from "react-icons/rx";
import { useNodeContext } from "../../../context/NodeContext";
import { FaDeleteLeft } from "react-icons/fa6";
// Custom styles for react-select
const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    height: "30px",
  }),
  control: (provided) => ({
    ...provided,
    width: "100%",
    minHeight: "25px",
    outline: "none",
    color: "rgb(95, 95, 95)",
    border: "1px solid #ddd",
    padding: "0px 5px",
    borderRadius: "5px",
    fontSize: "14px",
    backgroundColor: "white",
  }),
  menu: (provided) => ({
    ...provided,
    width: "100%",
    borderRadius: "5px",
    zIndex: 9999,
  }),
  option: (provided, state) => ({
    ...provided,
    width: "100%",
    padding: "2px 2px 2px 10px",
    fontSize: "14px",
    backgroundColor: state.isFocused ? "#007485" : "white",
    color: state.isFocused ? "white" : "black",
    "&:hover": {
      backgroundColor: "#007485",
    },
  }),
};

const FormDetailUpdate = ({
  widgets,
  odooButtonColors,
  tableFieldTypes,
  odooIcons,
}) => {
  const {
    forms,
    formData,
    setFormData,
    showFieldDetail,
    setShowFieldDetail,
    setSelectedFieldData,
    selectedFieldData,
    buttonData,
    setButtonData,
    setPythonCode,
    parseFormData,
    isCustomModelForm,
    customModelDetail,
    isTodoFormBuilder,
    setIsCustomModelForm,
    setIsTodoFormBuilder,
    setIsDocumentFormBuilder,
    setTodosData,
    setDocumentsData,
  } = useFormContext();
  const {
    groups,
    topRightAlert,
    newProcessId,
    fetchAllGroups,
    processDetail,
    centerAlert,
  } = useProcessContext();
  const { setNodes } = useNodeContext();
  const [updateFieldData, setUpdateFieldData] = useState({
    title: "",
    technicalName: "x_o2b_",
    type: "",
    widget: "",
    defaultValue: "",
    options: null,
    odooButtonColor: "",
    placeholder: "",
    tooltip: "",
    relatedModel: "",
    relationTable: "",
    relationField: "",
    groupVisible: [],
    pythonCode: null,
    isRequired: false,
    isReadOnly: false,
    isTracking: false,
    isCreatedBy: false,
    isLoggedinUser: false,
    isOnChange: false,
    isCreate: false,
    isEdit: false,
    isDelete: false,
    on_change_relation: "",
    isInvisible: false,
  });

  const navigate = useNavigate();
  const [odooModels, setOdooModels] = useState(null);
  const [selectedModalFields, setSelectedModalFields] = useState(null);
  const [filteredRelatedField, setFilteredRelatedField] = useState(null);
  // const [technicalNameError, setTechnicalNameError] = useState("");

  // Array data for all input types
  const inputTypes =
    updateFieldData.type === "button" ||
    updateFieldData.type === "object" ||
    updateFieldData.type === "action"
      ? ["object", "action"]
      : [
          "text",
          "char",
          "date",
          "datetime",
          "binary",
          "boolean",
          "float",
          "html",
          "integer",
          "json",
          "many2many",
          "many2one",
          "many2one_reference",
          "monetary",
          "one2many",
          "reference",
          "create_uid",
          "create_date",
          "write_uid",
          "write_date",
          "logged_in_user",
          "static_image",
          "remark_history",
          "age",
          "employee_id",
          "department",
        ];

  // data for mapping options into object
  const smart_icons = odooIcons?.map((icon) => ({
    value: icon,
    label: icon,
  }));
  // Handler for when an option is selected
  const handleSmartIconChange = (selectedOption) => {
    const event = {
      target: {
        name: "smartIcon",
        value: selectedOption ? selectedOption.value : "",
      },
    };
    handleChangeData(event);
  };
  // Update the form data state when selected field data changes
  useEffect(() => {
    if (selectedFieldData && selectedFieldData.tabName !== undefined) {
      setUpdateFieldData({
        ...updateFieldData,
        title: selectedFieldData.tabName,
        type: "tabPage",
        groupVisible: selectedFieldData.groupVisible || [],
      });
    } else if (selectedFieldData) {
      if (selectedFieldData.tabGroupField !== undefined) {
        if (selectedFieldData?.tabGroupField?.type === "label") {
          updateFieldData.alignment =
            selectedFieldData?.tabGroupField?.alignment || "left";
          updateFieldData.fontSize =
            selectedFieldData?.tabGroupField?.fontSize || "24";
          updateFieldData.fontWeight =
            selectedFieldData?.tabGroupField?.fontWeight || "500";
        }
        if (
          selectedFieldData?.tabGroupField?.type === "many2many" ||
          selectedFieldData?.tabGroupField?.type === "many2one" ||
          selectedFieldData?.tabGroupField?.type === "one2many"
        ) {
          updateFieldData.domain =
            selectedFieldData?.tabGroupField?.domain || null;
          updateFieldData.isModelSaved =
            selectedFieldData?.tabGroupField?.isModelSaved || false;
        }
        if (selectedFieldData?.tabGroupField?.type === "table") {
          updateFieldData.tableRelatedField =
            selectedFieldData?.tabGroupField?.tableRelatedField || null;

          updateFieldData.tableType =
            selectedFieldData?.tabGroupField?.tableType || null;

          updateFieldData.isTotalField =
            selectedFieldData?.tabGroupField?.isTotalField || false;
          updateFieldData.totalfield_field =
            selectedFieldData?.tabGroupField?.totalfield_field || "";
          updateFieldData.totalfield_operator =
            selectedFieldData?.tabGroupField?.totalfield_operator || "+";
        }
        if (selectedFieldData?.tabGroupField?.type === "oe_avatar") {
          updateFieldData.defaultValue =
            selectedFieldData?.tabGroupField?.defaultValue || "";
          updateFieldData.fileName =
            selectedFieldData?.tabGroupField?.fileName || null;
          updateFieldData.alignment =
            selectedFieldData?.tabGroupField?.alignment || "right";
        }
        if (
          selectedFieldData?.tabGroupField?.type === "button" ||
          selectedFieldData?.tabGroupField?.type === "object" ||
          selectedFieldData?.tabGroupField?.type === "action"
        ) {
          updateFieldData.alignment =
            selectedFieldData?.tabGroupField?.alignment || "center";
        }
        let relatedModel;
        if (
          selectedFieldData?.tabGroupField?.type === "many2one" &&
          selectedFieldData?.tabGroupField?.widget === "res_partner_many2one"
        ) {
          relatedModel = "res.partner";
        } else {
          relatedModel = selectedFieldData?.tabGroupField?.relatedModel
            ? selectedFieldData?.tabGroupField?.relatedModel
            : null;
        }
        let relationTable =
          selectedFieldData?.tabGroupField?.type === "many2many"
            ? selectedFieldData?.tabGroupField?.relationTable
            : null;
        let relationField =
          selectedFieldData?.tabGroupField?.type === "one2many"
            ? selectedFieldData?.tabGroupField?.relationField
            : null;
        let odooButtonColor =
          selectedFieldData?.tabGroupField?.type === "button" ||
          selectedFieldData?.tabGroupField?.type === "object" ||
          selectedFieldData?.tabGroupField?.type === "action"
            ? selectedFieldData?.tabGroupField?.odooButtonColor
            : "btn-outline-dark";
        setUpdateFieldData({
          ...updateFieldData,
          title: selectedFieldData.tabGroupField.title || "",
          type: selectedFieldData.tabGroupField.type || "",
          technicalName: selectedFieldData.tabGroupField.technicalName || "",
          widget: selectedFieldData.tabGroupField.widget || "",
          defaultValue: selectedFieldData.tabGroupField.defaultValue || "",
          options: selectedFieldData.tabGroupField.options || null,
          columns: selectedFieldData.tabGroupField.columns || null,
          placeholder: selectedFieldData.tabGroupField.placeholder || "",
          tooltip: selectedFieldData.tabGroupField.tooltip || "",
          groupVisible: selectedFieldData.tabGroupField.groupVisible || [],
          pythonCode: selectedFieldData.tabGroupField.pythonCode || null,
          isRequired: selectedFieldData.tabGroupField.isRequired || false,
          isReadOnly: selectedFieldData.tabGroupField.isReadOnly || false,
          isTracking: selectedFieldData.tabGroupField.isTracking || false,
          isOnChange: selectedFieldData.tabGroupField.isOnChange || false,
          on_change_relation:
            selectedFieldData?.tabGroupField?.on_change_relation || "",
          on_change_relation_model_field:
            selectedFieldData?.tabGroupField?.on_change_relation_model_field ||
            "",
          isInvisible: selectedFieldData.tabGroupField.isInvisible || false,
          isCreate: selectedFieldData.tabGroupField.isCreate || false,
          isEdit: selectedFieldData.tabGroupField.isEdit || false,
          isDelete: selectedFieldData.tabGroupField.isDelete || false,
          relatedModel: relatedModel,
          relationTable: relationTable,
          relationField: relationField,
          odooButtonColor: odooButtonColor,
        });
      } else if (selectedFieldData.tabField !== undefined) {
        if (selectedFieldData?.tabField?.type === "group") {
          updateFieldData.fields = selectedFieldData?.tabField?.fields || [];
        }
        if (selectedFieldData?.tabField?.type === "label") {
          updateFieldData.alignment =
            selectedFieldData?.tabField?.alignment || "left";
          updateFieldData.fontSize =
            selectedFieldData?.tabField?.fontSize || "24";
          updateFieldData.fontWeight =
            selectedFieldData?.tabField?.fontWeight || "500";
        }
        if (
          selectedFieldData?.tabField?.type === "many2many" ||
          selectedFieldData?.tabField?.type === "many2one" ||
          selectedFieldData?.tabField?.type === "one2many"
        ) {
          updateFieldData.domain = selectedFieldData?.tabField?.domain || null;
          updateFieldData.isModelSaved =
            selectedFieldData?.tabField?.isModelSaved || false;
        }
        if (selectedFieldData?.tabField?.type === "table") {
          updateFieldData.tableRelatedField =
            selectedFieldData?.tabField?.tableRelatedField || null;

          updateFieldData.tableType =
            selectedFieldData?.tabField?.tableType || null;

          updateFieldData.isTotalField =
            selectedFieldData?.tabField?.isTotalField || false;
          updateFieldData.totalfield_field =
            selectedFieldData?.tabField?.totalfield_field || "";
          updateFieldData.totalfield_operator =
            selectedFieldData?.tabField?.totalfield_operator || "+";
        }
        if (selectedFieldData?.tabField?.type === "oe_avatar") {
          updateFieldData.defaultValue =
            selectedFieldData?.tabField?.defaultValue || "";
          updateFieldData.fileName =
            selectedFieldData?.tabField?.fileName || null;
          updateFieldData.alignment =
            selectedFieldData?.tabField?.alignment || "right";
        }
        if (
          selectedFieldData?.tabField?.type === "button" ||
          selectedFieldData?.tabField?.type === "object" ||
          selectedFieldData?.tabField?.type === "action"
        ) {
          updateFieldData.alignment =
            selectedFieldData?.tabField?.alignment || "center";
        }
        let relatedModel;
        if (
          selectedFieldData?.tabField?.type === "many2one" &&
          selectedFieldData?.tabField?.widget === "res_partner_many2one"
        ) {
          relatedModel = "res.partner";
        } else {
          relatedModel = selectedFieldData?.tabField?.relatedModel
            ? selectedFieldData?.tabField?.relatedModel
            : null;
        }
        let relationTable =
          selectedFieldData?.tabField?.type === "many2many"
            ? selectedFieldData?.tabField?.relationTable
            : null;
        let relationField =
          selectedFieldData?.tabField?.type === "one2many"
            ? selectedFieldData?.tabField?.relationField
            : null;
        let odooButtonColor =
          selectedFieldData?.tabField?.type === "button" ||
          selectedFieldData?.tabField?.type === "object" ||
          selectedFieldData?.tabField?.type === "action"
            ? selectedFieldData?.tabField?.odooButtonColor
            : "btn-outline-dark";
        setUpdateFieldData({
          ...updateFieldData,
          title: selectedFieldData.tabField.title || "",
          type: selectedFieldData.tabField.type || "",
          technicalName: selectedFieldData.tabField.technicalName || "",
          widget: selectedFieldData.tabField.widget || "",
          defaultValue: selectedFieldData.tabField.defaultValue || "",
          options: selectedFieldData.tabField.options || null,
          columns: selectedFieldData.tabField.columns || null,
          placeholder: selectedFieldData.tabField.placeholder || "",
          tooltip: selectedFieldData.tabField.tooltip || "",
          groupVisible: selectedFieldData.tabField.groupVisible || [],
          pythonCode: selectedFieldData.tabField.pythonCode || null,
          isRequired: selectedFieldData.tabField.isRequired || false,
          isReadOnly: selectedFieldData.tabField.isReadOnly || false,
          isTracking: selectedFieldData.tabField.isTracking || false,
          isOnChange: selectedFieldData.tabField.isOnChange || false,
          on_change_relation:
            selectedFieldData?.tabField?.on_change_relation || "",
          on_change_relation_model_field:
            selectedFieldData?.tabField?.on_change_relation_model_field || "",
          isInvisible: selectedFieldData.tabField.isInvisible || false,
          isCreate: selectedFieldData.tabField.isCreate || false,
          isEdit: selectedFieldData.tabField.isEdit || false,
          isDelete: selectedFieldData.tabField.isDelete || false,
          relatedModel: relatedModel,
          relationTable: relationTable,
          relationField: relationField,
          odooButtonColor: odooButtonColor,
        });
      } else if (selectedFieldData.groupField) {
        if (selectedFieldData?.groupField?.type === "label") {
          updateFieldData.alignment =
            selectedFieldData?.groupField?.alignment || "left";
          updateFieldData.fontSize =
            selectedFieldData?.groupField?.fontSize || "24";
          updateFieldData.fontWeight =
            selectedFieldData?.groupField?.fontWeight || "500";
        }
        if (
          selectedFieldData?.groupField?.type === "many2many" ||
          selectedFieldData?.groupField?.type === "many2one" ||
          selectedFieldData?.groupField?.type === "one2many"
        ) {
          updateFieldData.domain =
            selectedFieldData?.groupField?.domain || null;
          updateFieldData.isModelSaved =
            selectedFieldData?.groupField?.isModelSaved || false;
        }
        if (selectedFieldData?.groupField?.type === "table") {
          updateFieldData.tableRelatedField =
            selectedFieldData?.groupField?.tableRelatedField || null;
          updateFieldData.tableType =
            selectedFieldData?.groupField?.tableType || null;

          updateFieldData.isTotalField =
            selectedFieldData?.groupField?.isTotalField || false;
          updateFieldData.totalfield_field =
            selectedFieldData?.groupField?.totalfield_field || "";
          updateFieldData.totalfield_operator =
            selectedFieldData?.groupField?.totalfield_operator || "+";
        }
        if (selectedFieldData?.groupField?.type === "oe_avatar") {
          updateFieldData.defaultValue =
            selectedFieldData?.groupField?.defaultValue || "";
          updateFieldData.fileName =
            selectedFieldData?.groupField?.fileName || null;
          updateFieldData.alignment =
            selectedFieldData?.groupField?.alignment || "right";
        }
        if (
          selectedFieldData?.groupField?.type === "button" ||
          selectedFieldData?.groupField?.type === "object" ||
          selectedFieldData?.groupField?.type === "action"
        ) {
          updateFieldData.alignment =
            selectedFieldData?.groupField?.alignment || "center";
        }
        let relatedModel;
        if (
          selectedFieldData?.groupField?.type === "many2one" &&
          selectedFieldData?.groupField?.widget === "res_partner_many2one"
        ) {
          relatedModel = "res.partner";
        } else {
          relatedModel = selectedFieldData?.groupField?.relatedModel
            ? selectedFieldData?.groupField?.relatedModel
            : null;
        }
        let relationTable =
          selectedFieldData?.groupField?.type === "many2many"
            ? selectedFieldData?.groupField?.relationTable
            : null;
        let relationField =
          selectedFieldData?.groupField?.type === "one2many"
            ? selectedFieldData?.groupField?.relationField
            : null;
        let odooButtonColor =
          selectedFieldData?.groupField?.type === "button" ||
          selectedFieldData?.groupField?.type === "object" ||
          selectedFieldData?.groupField?.type === "action"
            ? selectedFieldData?.groupField?.odooButtonColor
            : "btn-outline-dark";
        setUpdateFieldData({
          ...updateFieldData,
          title: selectedFieldData.groupField.title || "",
          type: selectedFieldData.groupField.type || "",
          technicalName: selectedFieldData.groupField.technicalName || "",
          widget: selectedFieldData.groupField.widget || "",
          defaultValue: selectedFieldData.groupField.defaultValue || "",
          options: selectedFieldData.groupField.options || null,
          columns: selectedFieldData.groupField.columns || null,
          placeholder: selectedFieldData.groupField.placeholder || "",
          tooltip: selectedFieldData.groupField.tooltip || "",
          groupVisible: selectedFieldData.groupField.groupVisible || [],
          pythonCode: selectedFieldData.groupField.pythonCode || null,
          isRequired: selectedFieldData.groupField.isRequired || false,
          isReadOnly: selectedFieldData.groupField.isReadOnly || false,
          isTracking: selectedFieldData.groupField.isTracking || false,
          isOnChange: selectedFieldData.groupField.isOnChange || false,
          on_change_relation:
            selectedFieldData?.groupField?.on_change_relation || "",
          on_change_relation_model_field:
            selectedFieldData?.groupField?.on_change_relation_model_field || "",
          isInvisible: selectedFieldData.groupField.isInvisible || false,
          isCreate: selectedFieldData.groupField.isCreate || false,
          isEdit: selectedFieldData.groupField.isEdit || false,
          isDelete: selectedFieldData.groupField.isDelete || false,
          relatedModel: relatedModel,
          relationTable: relationTable,
          relationField: relationField,
          odooButtonColor: odooButtonColor,
        });
      } else {
        if (
          selectedFieldData?.field?.type === "tab" ||
          selectedFieldData?.field?.type === "remark_history"
        ) {
          setUpdateFieldData({
            ...updateFieldData,
            title: selectedFieldData?.field?.title || "",
            technicalName: selectedFieldData?.field?.technicalName || "",
            type: selectedFieldData?.field?.type || "",
            widget: selectedFieldData?.field?.widget || "",
            defaultValue: selectedFieldData?.field?.defaultValue || "",
            options: selectedFieldData?.field?.options || null,
            columns: selectedFieldData?.field?.columns || null,
            placeholder: selectedFieldData?.field?.placeholder || "",
            tooltip: selectedFieldData?.field?.tooltip || "",
            groupVisible: selectedFieldData?.field?.groupVisible || [],
            pythonCode: selectedFieldData?.field?.pythonCode || null,
            isRequired: selectedFieldData?.field?.isRequired || false,
            isReadOnly: selectedFieldData?.field?.isReadOnly || false,
            isTracking: selectedFieldData?.field?.isTracking || false,
            isOnChange: selectedFieldData?.field?.isOnChange || false,
            isCreate: selectedFieldData.field.isCreate || false,
            isEdit: selectedFieldData.field.isEdit || false,
            isDelete: selectedFieldData.field.isDelete || false,
            on_change_relation:
              selectedFieldData?.field?.on_change_relation || "",
            on_change_relation_model_field:
              selectedFieldData?.field?.on_change_relation_model_field || "",
            isInvisible: selectedFieldData?.field?.isInvisible || false,
            tabs: selectedFieldData?.field?.tabs || [],
            activeTab: selectedFieldData?.field?.activeTab || 0,
          });
        } else if (selectedFieldData?.field?.type === "group") {
          let relatedModel;
          if (
            selectedFieldData?.field?.type === "many2one" &&
            selectedFieldData?.field?.widget === "res_partner_many2one"
          ) {
            relatedModel = "res.partner";
          } else {
            relatedModel = selectedFieldData?.field?.relatedModel
              ? selectedFieldData?.field?.relatedModel
              : null;
          }
          let relationTable =
            selectedFieldData?.field?.type === "many2many"
              ? selectedFieldData?.field?.relationTable
              : null;
          let relationField =
            selectedFieldData?.field?.type === "one2many"
              ? selectedFieldData?.field?.relationField
              : null;
          let odooButtonColor =
            selectedFieldData?.field?.type === "button" ||
            selectedFieldData?.field?.type === "object" ||
            selectedFieldData?.field?.type === "action"
              ? selectedFieldData?.field?.odooButtonColor
              : "btn-outline-dark";

          setUpdateFieldData({
            ...updateFieldData,
            title: selectedFieldData?.field?.title || "",
            technicalName: selectedFieldData?.field?.technicalName || "",
            type: selectedFieldData?.field?.type || "",
            widget: selectedFieldData?.field?.widget || "",
            defaultValue: selectedFieldData?.field?.defaultValue || "",
            options: selectedFieldData?.field?.options || null,
            columns: selectedFieldData?.field?.columns || null,
            placeholder: selectedFieldData.placeholder || "",
            tooltip: selectedFieldData?.field?.tooltip || "",
            groupVisible: selectedFieldData?.field?.groupVisible || [],
            pythonCode: selectedFieldData?.field?.pythonCode || null,
            isRequired: selectedFieldData?.field?.isRequired || false,
            isReadOnly: selectedFieldData?.field?.isReadOnly || false,
            isTracking: selectedFieldData?.field?.isTracking || false,
            isOnChange: selectedFieldData?.field?.isOnChange || false,
            on_change_relation:
              selectedFieldData?.field?.on_change_relation || "",
            on_change_relation_model_field:
              selectedFieldData?.field?.on_change_relation_model_field || "",
            isInvisible: selectedFieldData?.field?.isInvisible || false,
            isCreate: selectedFieldData.field.isCreate || false,
            isEdit: selectedFieldData.field.isEdit || false,
            isDelete: selectedFieldData.field.isDelete || false,
            fields: selectedFieldData?.field?.fields || [],
            relatedModel: relatedModel,
            relationTable: relationTable,
            relationField: relationField,
            odooButtonColor: odooButtonColor,
          });
        } else {
          if (selectedFieldData?.field?.type === "label") {
            if (
              !["default-statinfo", "statinfo"].includes(
                selectedFieldData?.field?.widget
              )
            ) {
              updateFieldData.alignment =
                selectedFieldData?.field?.alignment || "left";
            }
            updateFieldData.fontSize =
              selectedFieldData?.field?.fontSize || "24";
            updateFieldData.fontWeight =
              selectedFieldData?.field?.fontWeight || "500";
          }
          if (
            selectedFieldData?.field?.type === "many2many" ||
            selectedFieldData?.field?.type === "many2one" ||
            selectedFieldData?.field?.type === "one2many"
          ) {
            updateFieldData.domain = selectedFieldData?.field?.domain || null;
            updateFieldData.isModelSaved =
              selectedFieldData?.field?.isModelSaved || false;
          }
          if (selectedFieldData?.field?.type === "ribbon") {
            updateFieldData.ribbonBgColor =
              selectedFieldData?.field?.ribbonBgColor || "bg-danger";
          }
          if (selectedFieldData?.field?.type === "table") {
            updateFieldData.tableRelatedField =
              selectedFieldData?.field?.tableRelatedField || null;
            updateFieldData.tableType =
              selectedFieldData?.field?.tableType || null;

            updateFieldData.isTotalField =
              selectedFieldData?.field?.isTotalField || false;
            updateFieldData.totalfield_field =
              selectedFieldData?.field?.totalfield_field || "";
            updateFieldData.totalfield_operator =
              selectedFieldData?.field?.totalfield_operator || "+";
          }
          if (
            ["static_image", "oe_avatar"].includes(
              selectedFieldData?.field?.type
            )
          ) {
            updateFieldData.defaultValue =
              selectedFieldData?.field?.defaultValue || "";
            updateFieldData.fileName =
              selectedFieldData?.field?.fileName || null;
            if (
              !["default-statinfo", "statinfo"].includes(
                selectedFieldData?.field?.widget
              )
            ) {
              updateFieldData.alignment =
                selectedFieldData?.field?.alignment || "right";
            }
            if (selectedFieldData?.field?.widget !== "static_image") {
              updateFieldData.alignment =
                selectedFieldData?.field?.alignment || "right";
            }
          }
          if (selectedFieldData?.field?.widget === "default-statinfo") {
            updateFieldData.todos_field =
              selectedFieldData?.field?.todos_field || null;
            updateFieldData.documents_field =
              selectedFieldData?.field?.documents_field || null;
          }
          if (
            selectedFieldData?.field?.type === "button" ||
            selectedFieldData?.field?.type === "object" ||
            selectedFieldData?.field?.type === "action"
          ) {
            if (
              !["default-statinfo", "statinfo"].includes(
                selectedFieldData?.field?.widget
              )
            ) {
              updateFieldData.alignment =
                selectedFieldData?.field?.alignment || "center";
            } else {
              updateFieldData.smartIcon =
                selectedFieldData?.field?.smartIcon || "select";
            }
          }
          let relatedModel;
          if (
            selectedFieldData?.field?.type === "many2one" &&
            selectedFieldData?.field?.widget === "res_partner_many2one"
          ) {
            relatedModel = "res.partner";
          } else {
            relatedModel = selectedFieldData?.field?.relatedModel
              ? selectedFieldData?.field?.relatedModel
              : null;
          }
          let relationTable =
            selectedFieldData?.field?.type === "many2many"
              ? selectedFieldData?.field?.relationTable
              : null;
          let relationField =
            selectedFieldData?.field?.type === "one2many"
              ? selectedFieldData?.field?.relationField
              : null;
          let odooButtonColor =
            selectedFieldData?.field?.type === "button" ||
            selectedFieldData?.field?.type === "object" ||
            selectedFieldData?.field?.type === "action"
              ? selectedFieldData?.field?.odooButtonColor
              : "btn-outline-dark";
          let pythonCode =
            selectedFieldData?.field?.type === "button" ||
            selectedFieldData?.field?.type === "object" ||
            selectedFieldData?.field?.type === "action"
              ? selectedFieldData?.field?.pythonCode
              : null;
          let on_change_relation;
          if (selectedFieldData?.field?.isOnChange) {
            on_change_relation =
              selectedFieldData?.field?.on_change_relation || "";
          }
          setUpdateFieldData({
            ...updateFieldData,
            title: selectedFieldData?.field?.title || "",
            type: selectedFieldData?.field?.type || "",
            technicalName: selectedFieldData?.field?.technicalName || "",
            widget: selectedFieldData?.field?.widget || "",
            defaultValue: selectedFieldData?.field?.defaultValue || "",
            options: selectedFieldData?.field?.options || null,
            columns: selectedFieldData?.field?.columns || null,
            placeholder: selectedFieldData?.field?.placeholder || "",
            tooltip: selectedFieldData?.field?.tooltip || "",
            groupVisible: selectedFieldData?.field?.groupVisible || [],
            isRequired: selectedFieldData?.field?.isRequired || false,
            isReadOnly: selectedFieldData?.field?.isReadOnly || false,
            isTracking: selectedFieldData?.field?.isTracking || false,
            isOnChange: selectedFieldData?.field?.isOnChange || false,
            on_change_relation_model_field:
              selectedFieldData?.field?.on_change_relation_model_field || "",
            isInvisible: selectedFieldData?.field?.isInvisible || false,
            isCreate: selectedFieldData.field.isCreate || false,
            isEdit: selectedFieldData.field.isEdit || false,
            isDelete: selectedFieldData.field.isDelete || false,
            relatedModel: relatedModel,
            on_change_relation: on_change_relation,
            relationTable: relationTable,
            relationField: relationField,
            odooButtonColor: odooButtonColor,
            pythonCode: pythonCode || null,
          });
        }
      }
    }
  }, [selectedFieldData]);

  // useEffect for fetching all models in odoo
  useEffect(() => {
    const fetchModels = async () => {
      try {
        // const midUrl = isCustomModelForm
        //   ? `database_url=${customModelDetail.database_url}`
        //   : `database_url=${processDetail.database_url}`;
        const response = await fetch(
          `${BASE_URL}/odoo/models?database_url=${
            isCustomModelForm
              ? customModelDetail.database_url
              : processDetail.database_url
          }&api_key=${API_KEY}`
        );

        // const response = await fetch(
        //   `${BASE_URL}/odoo/models?process_id=${newProcessId}&api_key=${API_KEY}`
        // );
        const data = await response.json();
        if (response.ok) {
          if (Array.isArray(data.data)) {
            setOdooModels(data.data);
          }
        } else {
          console.error("Error in fetch: ", data);
        }
      } catch (error) {
        console.log("server error: ", error);
      }
    };
    // if (selectedFieldData) {
    fetchModels();
    // }
  }, [setSelectedFieldData]);

  // useEffect for fetching all fields and many2one fields in selected related modal
  useEffect(() => {
    if (updateFieldData?.relatedModel !== "") {
      const fetchModalFields = async () => {
        try {
          const response = await fetch(
            `${BASE_URL}/odoo/field?model=${
              updateFieldData.relatedModel
            }&database_url=${
              isCustomModelForm
                ? customModelDetail.database_url
                : processDetail.database_url
            }&api_key=${API_KEY}`
          );
          const data = await response.json();
          if (response.ok) {
            if (Array.isArray(data.data)) {
              // console.log("related field data:::::::: ", data);
              // console.log("related fields:::::: ", JSON.stringify(data.data));
              setSelectedModalFields(data.data);
            }
          } else {
            console.error("Error in fetch: ", data);
          }
        } catch (error) {
          console.log("server error: ", error);
        }
      };
      const fetchRelatedFields = async () => {
        try {
          const response = await fetch(
            `${BASE_URL}/odoo/fields?model=${
              updateFieldData.relatedModel
            }&database_url=${
              isCustomModelForm
                ? customModelDetail.database_url
                : processDetail.database_url
            }&api_key=${API_KEY}`
          );
          const data = await response.json();
          if (response.ok) {
            if (Array.isArray(data.data)) {
              // console.log("related field data:::::::: ", data);
              // console.log("related fields:::::: ", JSON.stringify(data.data));
              setFilteredRelatedField(data.data);
            }
          } else {
            console.error("Error in fetch: ", data);
          }
        } catch (error) {
          console.log("server error: ", error);
        }
      };
      fetchModalFields();
      fetchRelatedFields();
    }
  }, [updateFieldData.relatedModel]);

  // useEffect for updating technical name
  useEffect(() => {
    if (updateFieldData.widget === "remark_field") {
      setUpdateFieldData((prevData) => ({
        ...prevData,
        technicalName: `x_${prevData.title
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]/g, "_")}`,
      }));
    } else if (updateFieldData.widget === "defaultField") {
      setUpdateFieldData((prevData) => ({
        ...prevData,
        technicalName: `x_${prevData.title
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]/g, "_")}`,
      }));
    } else {
      setUpdateFieldData((prevData) => ({
        ...prevData,
        technicalName: `x_o2b_${prevData.title
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]/g, "_")}`,
      }));
    }
  }, [updateFieldData.title]);

  // useEffect code for fething all field data from odoo model for on change
  const [allFieldRelatedModel, setAllFieldRelatedModel] = useState(null);
  useEffect(() => {
    if (updateFieldData.on_change_relation && formData) {
      const field = parseFormData(formData);

      const relatedField = field.find(
        (fld) => fld.technicalName === updateFieldData.on_change_relation
      );

      if (relatedField) {
        const fetchModalFields = async () => {
          try {
            const response = await fetch(
              `${BASE_URL}/odoo/field?model=${
                relatedField.relatedModel
              }&database_url=${
                isCustomModelForm
                  ? customModelDetail.database_url
                  : processDetail.database_url
              }&api_key=${API_KEY}`
            );
            const data = await response.json();
            if (response.ok) {
              if (Array.isArray(data.data)) {
                setAllFieldRelatedModel(data.data);
              }
            } else {
              console.error("Error in fetch model fields: ", data);
            }
          } catch (error) {
            console.log("server error: ", error);
          }
        };
        fetchModalFields();
      }
    }
  }, [updateFieldData.on_change_relation, formData]);

  // method for reseting selected field data
  const handleReset = () => {
    setUpdateFieldData({
      title: "",
      technicalName: "x_o2b_",
      type: "",
      widget: "",
      options: null,
      odooButtonColor: "",
      placeholder: "",
      tooltip: "",
      pythonCode: null,
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isCreatedBy: false,
      isOnChange: false,
      isInvisible: false,
    });
    setShowFieldDetail(false);
  };

  // method for final submit to update field data
  const handleFieldDataSubmit = () => {
    // let updatedFormData = [...formData];
    let updatedFormData = JSON.parse(JSON.stringify(formData));

    if (selectedFieldData.tabGroupField !== undefined) {
      // updating field inside tabGroupfield
      const {
        rowIndex,
        columnIndex,
        tabIndex,
        tabRowIndex,
        tabFieldIndex,
        tabGroupRowIndex,
        tabGroupFieldIndex,
      } = selectedFieldData;
      if (tabGroupRowIndex !== undefined && tabGroupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex].fields[tabGroupRowIndex][tabGroupFieldIndex] = {
          ...updateFieldData,
        };
      } else if (tabGroupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex].fields[tabGroupFieldIndex] = { ...updateFieldData };
      } else {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex] = { ...updateFieldData };
      }
    } else if (selectedFieldData.groupField !== undefined) {
      // updating field inside group
      const { rowIndex, columnIndex, groupRowIndex, groupFieldIndex } =
        selectedFieldData;
      if (groupRowIndex !== undefined && groupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].fields[groupRowIndex][
          groupFieldIndex
        ] = { ...updateFieldData };
      } else if (groupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].fields[groupFieldIndex] = {
          ...updateFieldData,
        };
      } else {
        updatedFormData[rowIndex][columnIndex] = { ...updateFieldData };
      }
    } else if (selectedFieldData.tabName !== undefined) {
      const { rowIndex, columnIndex, tabIndex } = selectedFieldData;
      updatedFormData[rowIndex][columnIndex].tabs[tabIndex].tabName =
        updateFieldData.title;
    } else if (selectedFieldData.tabField !== undefined) {
      // updating field inside tabfield
      const { rowIndex, columnIndex, tabIndex, tabRowIndex, tabFieldIndex } =
        selectedFieldData;
      if (tabRowIndex !== undefined && tabFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex] = { ...updateFieldData };
      } else if (tabFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabFieldIndex
        ] = { ...updateFieldData };
      } else {
        updatedFormData[rowIndex][columnIndex] = { ...updateFieldData };
      }
    } else {
      // updating top level field
      updatedFormData = updatedFormData.map((row) =>
        row.map((field) =>
          field === selectedFieldData.field ? { ...updateFieldData } : field
        )
      );
    }

    setFormData(updatedFormData);
  };
  // method for taking input field data
  const handleChangeData = (e) => {
    const { name, value, type, checked } = e.target;

    setUpdateFieldData({
      ...updateFieldData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        updateFieldData.defaultValue = reader.result;
        updateFieldData.fileName = file.name;
        console.log("selected file::::: ", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFileRemove = (e) => {
    e.preventDefault();
    updateFieldData.defaultValue = "";
    handleBlurData();
  };
  // console.log("selected file:::::::: ", selectedFile);
  const handleCheckBoxChange = (e) => {
    const { name, checked } = e.target;
    setUpdateFieldData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  // handle field update form submit
  const handleUpdateFieldDataSubmit = (e) => {
    e.preventDefault();

    handleFieldDataSubmit();
    setPythonCode(null);
    handleReset();
  };

  // method for saving field detail when field is blured
  const handleBlurData = (e) => {
    let clickedFieldData = { ...selectedFieldData };
    if (clickedFieldData.tabName !== undefined) {
      clickedFieldData.tabName = updateFieldData.title;
    } else if (clickedFieldData.tabGroupField !== undefined) {
      if (clickedFieldData.tabGroupField.type === "table") {
        const tableRelatedField =
          clickedFieldData.tabGroupField.tableRelatedField;
        updateFieldData.tableRelatedField = tableRelatedField;
        clickedFieldData.tabGroupField = { ...updateFieldData };
      } else {
        clickedFieldData.tabGroupField = { ...updateFieldData };
      }
    } else if (clickedFieldData.tabField !== undefined) {
      if (clickedFieldData.tabField.type === "table") {
        const tableRelatedField = clickedFieldData.tabField.tableRelatedField;
        updateFieldData.tableRelatedField = tableRelatedField;
        clickedFieldData.tabField = { ...updateFieldData };
      } else {
        clickedFieldData.tabField = { ...updateFieldData };
      }
    } else if (clickedFieldData.groupField !== undefined) {
      if (clickedFieldData.groupField.type === "table") {
        const tableRelatedField = clickedFieldData.groupField.tableRelatedField;
        updateFieldData.tableRelatedField = tableRelatedField;
        clickedFieldData.groupField = { ...updateFieldData };
      } else {
        clickedFieldData.groupField = { ...updateFieldData };
      }
    } else {
      if (clickedFieldData.field.type === "table") {
        const tableRelatedField = clickedFieldData.field.tableRelatedField;
        updateFieldData.tableRelatedField = tableRelatedField;
        clickedFieldData.field = { ...updateFieldData };
      } else {
        clickedFieldData.field = { ...updateFieldData };
      }
    }
    setSelectedFieldData({ ...clickedFieldData });
    let updatedFormData = JSON.parse(JSON.stringify(formData));

    if (selectedFieldData.tabGroupField !== undefined) {
      // updating field inside tabGroupfield
      const {
        rowIndex,
        columnIndex,
        tabIndex,
        tabRowIndex,
        tabFieldIndex,
        tabGroupRowIndex,
        tabGroupFieldIndex,
      } = selectedFieldData;
      if (tabGroupRowIndex !== undefined && tabGroupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex].fields[tabGroupRowIndex][tabGroupFieldIndex] = {
          ...updateFieldData,
        };
      } else if (tabGroupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex].fields[tabGroupFieldIndex] = { ...updateFieldData };
      } else {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex] = { ...updateFieldData };
      }
    } else if (selectedFieldData.groupField !== undefined) {
      // updating field inside group
      const { rowIndex, columnIndex, groupRowIndex, groupFieldIndex } =
        selectedFieldData;
      if (groupRowIndex !== undefined && groupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].fields[groupRowIndex][
          groupFieldIndex
        ] = { ...updateFieldData };
      } else if (groupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].fields[groupFieldIndex] =
          updateFieldData;
      } else {
        updatedFormData[rowIndex][columnIndex] = { ...updateFieldData };
      }
    } else if (selectedFieldData.tabName !== undefined) {
      const { rowIndex, columnIndex, tabIndex } = selectedFieldData;
      updatedFormData[rowIndex][columnIndex].tabs[tabIndex].tabName =
        updateFieldData.title;
    } else if (selectedFieldData.tabField !== undefined) {
      // updating field inside tabfield
      const { rowIndex, columnIndex, tabIndex, tabRowIndex, tabFieldIndex } =
        selectedFieldData;
      if (tabRowIndex !== undefined && tabFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex] = { ...updateFieldData };
      } else if (tabFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabFieldIndex
        ] = { ...updateFieldData };
      } else {
        updatedFormData[rowIndex][columnIndex] = { ...updateFieldData };
      }
    } else {
      const { rowIndex, columnIndex } = selectedFieldData;
      updatedFormData[rowIndex][columnIndex] = { ...updateFieldData };
    }

    setFormData(updatedFormData);
  };

  // method for deleting selected field
  const handleDeleteField = () => {
    let updatedFormData = [...formData];
    if (selectedFieldData.tabGroupField !== undefined) {
      const {
        rowIndex,
        columnIndex,
        tabIndex,
        tabRowIndex,
        tabFieldIndex,
        tabGroupRowIndex,
        tabGroupFieldIndex,
      } = selectedFieldData;
      if (tabGroupFieldIndex !== undefined) {
        // Locate and delete the specific field in the nested group
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex].fields[tabGroupRowIndex] = updatedFormData[rowIndex][
          columnIndex
        ].tabs[tabIndex].content[tabRowIndex][tabFieldIndex].fields[
          tabGroupRowIndex
        ].filter((_, index) => index !== tabGroupFieldIndex);

        // Remove the row if it's empty
        if (
          updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
            tabRowIndex
          ][tabFieldIndex].fields[tabGroupRowIndex].length === 0
        ) {
          updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
            tabRowIndex
          ][tabFieldIndex].fields = updatedFormData[rowIndex][columnIndex].tabs[
            tabIndex
          ].content[tabRowIndex][tabFieldIndex].fields.filter(
            (_, index) => index !== tabGroupRowIndex
          );
        }
      } else {
        // Delete the field at the specified columnIndex
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ] = updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ].filter((_, index) => index !== tabFieldIndex);
      }
    } else if (selectedFieldData.groupField !== undefined) {
      const { rowIndex, columnIndex, groupRowIndex, groupFieldIndex } =
        selectedFieldData;
      if (groupFieldIndex !== undefined) {
        // Locate and delete the specific field in the nested group
        updatedFormData[rowIndex][columnIndex].fields[groupRowIndex] =
          updatedFormData[rowIndex][columnIndex].fields[groupRowIndex].filter(
            (_, index) => index !== groupFieldIndex
          );

        // Remove the row if it's empty
        if (
          updatedFormData[rowIndex][columnIndex].fields[groupRowIndex]
            .length === 0
        ) {
          updatedFormData[rowIndex][columnIndex].fields = updatedFormData[
            rowIndex
          ][columnIndex].fields.filter((_, index) => index !== groupRowIndex);
        }
      } else {
        // Delete the field at the specified columnIndex
        updatedFormData[rowIndex] = updatedFormData[rowIndex].filter(
          (_, index) => index !== columnIndex
        );
      }
    } else if (selectedFieldData.tabName !== undefined) {
      // deleting whole tab from tab field
      const { rowIndex, columnIndex, tabIndex } = selectedFieldData;

      if (
        tabIndex !== undefined &&
        tabIndex === updatedFormData[rowIndex][columnIndex].tabs.length - 1 &&
        updatedFormData[rowIndex][columnIndex].tabs.length - 1 === 0
      ) {
        updatedFormData[rowIndex] = updatedFormData[rowIndex].filter(
          (_, index) => index !== columnIndex
        );
      } else if (
        tabIndex !== undefined &&
        tabIndex === updatedFormData[rowIndex][columnIndex].tabs.length - 1
      ) {
        updatedFormData[rowIndex][columnIndex].tabs = updatedFormData[rowIndex][
          columnIndex
        ].tabs.filter((_, index) => index !== tabIndex);
        formData[rowIndex][columnIndex].activeTab = 0;
      } else if (tabIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs = updatedFormData[rowIndex][
          columnIndex
        ].tabs.filter((_, index) => index !== tabIndex);
      } else {
        // Delete the field at the specified columnIndex
        updatedFormData[rowIndex] = updatedFormData[rowIndex].filter(
          (_, index) => index !== columnIndex
        );
      }
    } else if (selectedFieldData.tabField !== undefined) {
      const { rowIndex, columnIndex, tabIndex, tabRowIndex, tabFieldIndex } =
        selectedFieldData;
      if (tabFieldIndex !== undefined) {
        // Locate and delete the specific field in the nested group
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ] = updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ].filter((_, index) => index !== tabFieldIndex);

        // Remove the row if it's empty
        if (
          updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
            tabRowIndex
          ].length === 0
        ) {
          updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content =
            updatedFormData[rowIndex][columnIndex].tabs[
              tabIndex
            ].content.filter((_, index) => index !== tabRowIndex);
        }
      } else {
        // Delete the field at the specified columnIndex
        updatedFormData[rowIndex] = updatedFormData[rowIndex].filter(
          (_, index) => index !== columnIndex
        );
      }
    } else {
      // General case: filter out the selected field from all rows
      updatedFormData = updatedFormData.map((row) =>
        row.filter((field) => field !== selectedFieldData.field)
      );
    }

    // Filter out any rows that are now empty
    updatedFormData = updatedFormData.filter((row) => row.length > 0);
    setFormData(updatedFormData);
    topRightAlert("success", "Field removed...");
    handleReset();
  };

  // method for handling option delete
  const handleOptionDelete = (e, optIndex) => {
    e.preventDefault();
    updateFieldData?.options?.splice(optIndex, 1);
    setUpdateFieldData({ ...updateFieldData });
    handleBlurData();
  };

  const [showInputFieldOpt, setShowInputFieldOpt] = useState(false);
  const [newOpt, setNewOpt] = useState("");
  const handleAddMoreOptChange = (e) => {
    setNewOpt(e.target.value);
  };
  const handleAddNewOpt = () => {
    if (newOpt && newOpt !== "") {
      updateFieldData.options.push(newOpt);
      handleBlurData();
      setNewOpt("");
      setShowInputFieldOpt(false);
    }
  };

  // handling drag and drop for option
  // Drag and drop logic
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default to allow drop
  };

  const handleDrop = (e, dropIndex) => {
    const dragIndex = e.dataTransfer.getData("text");
    if (dragIndex !== dropIndex) {
      const newOptions = [...updateFieldData.options];
      const draggedItem = newOptions.splice(dragIndex, 1)[0]; // Remove the dragged item
      newOptions.splice(dropIndex, 0, draggedItem); // Insert the dragged item at the new index
      updateFieldData.options = newOptions;
      handleBlurData();
    }
    e.dataTransfer.clearData();
  };

  // handling table columns =====================================================
  const [showSelectColumn, setShowSelectColumn] = useState(false);
  const [showSubtotalColumn, setShowSubtotalColumn] = useState(false);
  const [newColumn, setNewColumn] = useState("");
  const [newOptNewTableSelectionField, setNewOptNewTableSelectionField] =
    useState("");
  const [
    newRelatedModelNewTableRelationField,
    setNewRelatedModelNewTableRelationField,
  ] = useState(null);
  const [newDomainNewTableRelationField, setNewDomainNewTableRelationField] =
    useState(null);
  const [newtableColumnFields, setNewTableColumnFields] = useState({
    title: "",
    technicalName: "x_o2b_",
    type: "char",
    isSubtotalField: false,
  });
  const [on_change, setOnChange] = useState({
    isOnchange: false,
    on_change_relation: "",
    on_change_relation_model_field: "",
  });
  const [allOne2ManyFields, setAllOne2ManyFields] = useState([]);
  const [tableRelatedModelFields, setTableRelatedModelFields] = useState([]);
  const [allFields, setAllFields] = useState(null);
  const [fieldsRelatedModelNewTable, setFieldsRelatedModelNewTable] =
    useState(null);
  const [newTableColumnEditClick, setNewTableColumnEditClick] = useState({
    status: false,
    index: null,
  });
  const [subtotalFieldEquation, setSubtotalFieldEquation] = useState("");
  const [subtotalEquationOperand, setSubtotalEquationOperand] = useState("");
  const [subtotalEquationOperator, setSubtotalEquationOperator] = useState("");

  const handleChangeOnchange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "isOnchange") {
      setOnChange((prevData) => ({
        ...prevData,
        isOnchange: type === "checkbox" ? checked : value,
      }));
    } else {
      setOnChange((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };
  // useEffect for all fields
  useEffect(() => {
    if (forms) {
      const oprs = [];
      forms.forEach((form) => {
        if (form.formbuilder_data) {
          const opr = parseFormData(form.formbuilder_data);
          opr.forEach((op) => {
            oprs.push(op);
          });
        }
      });
      setAllFields(oprs);
    }
  }, [forms]);
  // useEffect for getting all one2many fields
  useEffect(() => {
    // Step 1: Filter data where type is 'one2many'
    const filteredData = allFields?.filter((item) => item.type === "one2many");
    // console.log("filteredData: ", filteredData);
    // Step 2: Ensure uniqueness based on 'technical_name'
    const seenTechnicalNames = new Set();
    const uniqueFilteredData = filteredData?.filter((item) => {
      if (!seenTechnicalNames.has(item.technicalName)) {
        seenTechnicalNames.add(item.technicalName);
        return true;
      }
      return false;
    });
    // console.log("seenTechnicalName: ", seenTechnicalNames);
    // console.log("uniqueFilteredData: ", uniqueFilteredData);

    setAllOne2ManyFields(uniqueFilteredData);
  }, [allFields]);
  const handleFetchRelatedNormalField = async (modelName) => {
    try {
      const response = await fetch(
        `${BASE_URL}/odoo/field?model=${modelName}&database_url=${
          isCustomModelForm
            ? customModelDetail.database_url
            : processDetail.database_url
        }&api_key=${API_KEY}`
      );
      const data = await response.json();
      if (response.ok) {
        setTableRelatedModelFields(data.data);
        // console.log("response data for fields:::::: ", data);
      } else {
        console.log("error in fetching: ", data);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    if (on_change.isOnchange && on_change.on_change_relation) {
      const modelName = updateFieldData.columns.find(
        (field) => field.technicalName === on_change?.on_change_relation
      )?.relatedModel;
      handleFetchRelatedNormalField(modelName);
    }
  }, [
    on_change.isOnchange,
    on_change.on_change_relation,
    updateFieldData.columns,
  ]);

  // useEffect for getting all fields for related model of table column field
  useEffect(() => {
    if (newRelatedModelNewTableRelationField) {
      // const field = parseFormData(formData);

      // const relatedField = field.find(
      //   (fld) => fld.technicalName === updateFieldData.on_change_relation
      // );

      // if (relatedField) {
      const fetchModalFields = async () => {
        try {
          const response = await fetch(
            `${BASE_URL}/odoo/field?model=${newRelatedModelNewTableRelationField}&database_url=${
              isCustomModelForm
                ? customModelDetail.database_url
                : processDetail.database_url
            }&api_key=${API_KEY}`
          );
          const data = await response.json();
          if (response.ok) {
            if (Array.isArray(data.data)) {
              setFieldsRelatedModelNewTable(data.data);
            }
          } else {
            console.error("Error in fetch model fields: ", data);
          }
        } catch (error) {
          console.log("server error: ", error);
        }
      };
      fetchModalFields();
      // }
    }
  }, [updateFieldData.columns, newRelatedModelNewTableRelationField]);
  // console.log("fieldsRelatedModelNewTable:::::: ", fieldsRelatedModelNewTable);
  // console.log(
  //   "newRelatedModelNewTableRelationField:::::: ",
  //   newRelatedModelNewTableRelationField
  // );

  // handle for existing table column value change
  const handleTableColumnChange = (e) => {
    setNewColumn(e.target.value);
  };
  // handle for new table column value change
  const handleNewTableColumnChange = (e) => {
    const value = e.target.value;
    if (e.target.name === "type") {
      setNewTableColumnFields({ ...newtableColumnFields, type: value });
    } else if (e.target.name === "options") {
      setNewOptNewTableSelectionField(e.target.value);
    } else {
      const technicalName = `x_o2b_${value
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, "_")}`;
      setNewTableColumnFields({
        ...newtableColumnFields,
        title: value,
        technicalName,
      });
    }
  };
  // handle for add column button click existing table
  const handleAddNewColumn = () => {
    if (newColumn && newColumn !== "") {
      updateFieldData.columns.push(newColumn);
      handleBlurData();
      setNewColumn("");
      setShowSelectColumn(false);
    }
  };
  // handle for add column button click new table
  const handleAddNewTableColumn = (e) => {
    e.preventDefault();
    if (
      newtableColumnFields?.title !== "" &&
      newtableColumnFields.type !== ""
    ) {
      const field = { ...newtableColumnFields };
      if (
        on_change.isOnchange === true &&
        (on_change.on_change_relation === "" ||
          on_change.on_change_relation === null ||
          on_change.on_change_relation === undefined)
      ) {
        centerAlert("error", "Related Field is not set.");
        return;
      } else if (
        on_change.isOnchange === true &&
        (on_change.on_change_relation_model_field === "" ||
          on_change.on_change_relation_model_field === null ||
          on_change.on_change_relation_model_field === undefined)
      ) {
        centerAlert("error", "Related Model Field is not set.");
        return;
      }
      field.isOnChange = on_change.isOnchange || false;
      field.on_change_relation = on_change.on_change_relation;
      field.on_change_relation_model_field =
        on_change.on_change_relation_model_field;
      // }
      if (subtotalFieldEquation) {
        field.equation = subtotalFieldEquation;
        if (field.type === "char") {
          field.type = "float";
        }
        field.isSubtotalField = true;
        setSubtotalFieldEquation("");
      } else {
        field.isSubtotalField = false;
      }
      if (field.type === "selection") {
        const opt = newOptNewTableSelectionField.trim().split(",");
        field.options = opt.map((opt) => opt.trim());
        setNewOptNewTableSelectionField("");
      }
      if (["many2one", "many2many", "one2many"].includes(field.type)) {
        if (
          !newRelatedModelNewTableRelationField ||
          newRelatedModelNewTableRelationField === ""
        ) {
          centerAlert("error", "Related Model field is mandatory to fill");
          return;
        } else {
          field.relatedModel = newRelatedModelNewTableRelationField;
          field.domain = newDomainNewTableRelationField;
        }
        // field.relatedModel = newRelatedModelNewTableRelationField;
        // field.domain = newDomainNewTableRelationField;
        if (field.type === "many2many") {
          field.relationTable = `x_o2b_${processDetail.process_name
            .trim()
            .toLowerCase()
            .replace(/ /g, "_")}_${newRelatedModelNewTableRelationField}`;
        }
        if (field.type === "one2many") {
          field.relationField = `x_o2b_${processDetail.process_name
            .trim()
            .toLowerCase()
            .replace(/ /g, "_")}_${newRelatedModelNewTableRelationField}`;
        }

        switch (field.type) {
          case "many2one":
            field.widget = "many2one";
            break;
          case "many2many":
            field.widget = "many2many_tags";
            break;
          case "one2many":
            field.widget = "one2many";
            break;

          default:
            break;
        }
      }
      updateFieldData.columns.push({ ...field });
      setNewRelatedModelNewTableRelationField(null);
      handleBlurData();
      setNewTableColumnFields({
        title: "",
        technicalName: "x_o2b_",
        type: "char",
        isSubtotalField: false,
      });
      setOnChange({
        isOnchange: false,
        on_change_relation: "",
        on_change_relation_model_field: "",
      });
      setShowSelectColumn(false);
      setShowSubtotalColumn(false);
      setNewDomainNewTableRelationField(null);
      setNewTableColumnEditClick({
        status: false,
        index: null,
      });
    }
  };
  // handle for edit column field button click
  const handleUpdateNewTableColumn = (e) => {
    e.preventDefault();
    if (
      newtableColumnFields?.title !== "" &&
      newtableColumnFields.type !== ""
    ) {
      const allCols = [...updateFieldData.columns];
      const field = { ...newtableColumnFields };
      if (
        on_change.isOnchange === true &&
        (on_change.on_change_relation === "" ||
          on_change.on_change_relation === null ||
          on_change.on_change_relation === undefined)
      ) {
        centerAlert("error", "Related Field is not set.");
        return;
      } else if (
        on_change.isOnchange === true &&
        (on_change.on_change_relation_model_field === "" ||
          on_change.on_change_relation_model_field === null ||
          on_change.on_change_relation_model_field === undefined)
      ) {
        centerAlert("error", "Related Model Field is not set.");
        return;
      }
      field.isOnChange = on_change.isOnchange || false;
      field.on_change_relation = on_change.on_change_relation;
      field.on_change_relation_model_field =
        on_change.on_change_relation_model_field;
      // }
      if (subtotalFieldEquation) {
        field.equation = subtotalFieldEquation;
        field.isSubtotalField = true;
        setSubtotalFieldEquation("");
      } else {
        field.isSubtotalField = false;
      }

      if (field.type === "selection") {
        const opt = newOptNewTableSelectionField.trim().split(",");
        field.options = opt.map((opt) => opt.trim());
        setNewOptNewTableSelectionField("");
      }
      if (["many2one", "many2many", "one2many"].includes(field.type)) {
        if (
          !newRelatedModelNewTableRelationField ||
          newRelatedModelNewTableRelationField === ""
        ) {
          centerAlert("error", "Related Model field is mandatory to fill");
          return;
        } else {
          field.relatedModel = newRelatedModelNewTableRelationField;
          field.domain = newDomainNewTableRelationField;
        }
        // field.relatedModel = newRelatedModelNewTableRelationField;
        if (field.type === "many2many") {
          field.relationTable = `x_o2b_${processDetail.process_name
            .trim()
            .toLowerCase()
            .replace(/ /g, "_")}_${newRelatedModelNewTableRelationField}`;
        }
        if (field.type === "one2many") {
          field.relationField = `x_o2b_${processDetail.process_name
            .trim()
            .toLowerCase()
            .replace(/ /g, "_")}_${newRelatedModelNewTableRelationField}`;
        }
        setNewRelatedModelNewTableRelationField(null);
        switch (field.type) {
          case "many2one":
            field.widget = "many2one";
            break;
          case "many2many":
            field.widget = "many2many_tags";
            break;
          case "one2many":
            field.widget = "one2many";
            break;

          default:
            break;
        }
      }
      allCols.splice(newTableColumnEditClick.index, 1, field);
      updateFieldData.columns = [...allCols];
      handleBlurData();
      setNewTableColumnFields({
        title: "",
        technicalName: "x_o2b_",
        type: "char",
        isSubtotalField: false,
      });
      setOnChange({
        isOnchange: false,
        on_change_relation: "",
        on_change_relation_model_field: "",
      });
      setShowSelectColumn(false);
      setShowSubtotalColumn(false);
      setNewTableColumnEditClick({ status: false, index: null });
      setNewDomainNewTableRelationField(null);
    }
  };

  // method for delete condition set from decision activity
  const handleActivityDeleteDecisionConditionSet = (fieldTechnicalName) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        if (node.type === "decision" && Array.isArray(node.data.next_name)) {
          const filteredNextSteps = node.data.next_name.filter((step) => {
            if (Array.isArray(step.condition)) {
              return !step.condition.some((condition) =>
                Array.isArray(condition)
                  ? condition.includes(fieldTechnicalName)
                  : condition === fieldTechnicalName
              );
            }
            return true;
          });

          return {
            ...node,
            data: {
              ...node.data,
              next_name: filteredNextSteps,
            },
          };
        }
        return node;
      })
    );
  };

  // handle for delete column
  const handleColumnDelete = (e, clmnIndex) => {
    e.preventDefault();
    const deletableColumn = updateFieldData?.columns[clmnIndex];

    if (
      deletableColumn.isSubtotalField ||
      ["integer", "float"].includes(deletableColumn.type)
    ) {
      if (deletableColumn.technicalName === updateFieldData.totalfield_field) {
        handleActivityDeleteDecisionConditionSet(
          `x_total_${updateFieldData.tableRelatedField}`
        );
        updateFieldData.isTotalField = false;
        updateFieldData.totalfield_field = "";
        updateFieldData.totalfield_operator = "";
      }
    }
    // updateFieldData?.columns?.splice(clmnIndex, 1);
    // setUpdateFieldData({ ...updateFieldData });

    if (updateFieldData?.columns?.length > 1) {
      updateFieldData?.columns?.splice(clmnIndex, 1);
      setUpdateFieldData({ ...updateFieldData });
      handleBlurData();
    } else {
      if (selectedFieldData) {
        handleDeleteField();
      }
    }
  };
  // handle for new table column edit column
  const handleNewTableColumnEditClick = (e, clmnIndex) => {
    e.preventDefault();
    // setOnChange({
    //   isOnchange: false,
    //   on_change_relation: "",
    //   on_change_relation_model_field: "",
    // });
    const cols = [...updateFieldData?.columns];
    setNewTableColumnFields({ ...cols[clmnIndex] });
    setNewTableColumnEditClick((prevData) => ({
      ...prevData,
      status: true,
      index: clmnIndex,
    }));
    if (updateFieldData.columns[clmnIndex].equation) {
      setSubtotalFieldEquation(updateFieldData.columns[clmnIndex].equation);
    }
    if (updateFieldData.columns[clmnIndex].isOnChange) {
      setOnChange({
        isOnchange: updateFieldData.columns[clmnIndex].isOnChange,
        on_change_relation:
          updateFieldData.columns[clmnIndex].on_change_relation,
        on_change_relation_model_field:
          updateFieldData.columns[clmnIndex].on_change_relation_model_field,
      });
    }

    if (updateFieldData.columns[clmnIndex].options) {
      // if (newtableColumnFields.type === "selection") {
      // const options = ;
      setNewOptNewTableSelectionField(
        updateFieldData.columns[clmnIndex].options.join(",")
      );
    }
    if (updateFieldData.columns[clmnIndex].relatedModel) {
      setNewRelatedModelNewTableRelationField(
        updateFieldData.columns[clmnIndex].relatedModel
      );
    }
    if (updateFieldData.columns[clmnIndex].domain) {
      setNewDomainNewTableRelationField(
        updateFieldData.columns[clmnIndex].domain
      );
    } else {
      setNewDomainNewTableRelationField(null);
    }
    setShowSelectColumn(true);
    setShowSubtotalColumn(false);
  };

  // methods for subtotal fields
  const handleEquationAddOperand = (value) => {
    if (!subtotalEquationOperand) {
      setSubtotalEquationOperand(value);
      setSubtotalFieldEquation((prevEquation) => prevEquation + value);
      setSubtotalEquationOperator("");
    }
  };
  // Handler for setting the selected operator
  const handleEquationSetOperator = (value) => {
    if (subtotalFieldEquation && !subtotalEquationOperator) {
      setSubtotalEquationOperator(value);
      setSubtotalFieldEquation(subtotalFieldEquation + value);
      setSubtotalEquationOperand("");
    }
  };
  // Handler for clearing the equation
  const handleEquationClear = (e) => {
    e.preventDefault();
    setSubtotalFieldEquation("");
    setSubtotalEquationOperator("");
    setSubtotalEquationOperand("");
  };

  // table column field code over ===============================================

  // handling group visibilty for fields ========================================
  // const [newGrp, setNewGrp] = useState("");
  const [showGrpInput, setShowGrpInput] = useState(true);
  const [filteredGroup, setFilteredGroup] = useState(null);
  //  old working code for group add ===========
  const handleAddNewGroup = (e) => {
    const { value } = e.target;
    if (value && value !== "") {
      updateFieldData.groupVisible.push(value);
      handleBlurData();
      setShowGrpInput(false);
    }
  };
  //  new code for group select ===============
  // const handleAddNewGroup = (e) => {
  //   const { value } = e.target;
  //   if (value && value !== "") {
  //     const grpObj = { group: value, perm: "read" };
  //     updateFieldData.groupVisible.push(grpObj);
  //     handleBlurData();
  //     setShowGrpInput(false);
  //   }
  // };
  // ====================

  // method for group permission update
  // const handleGroupPermission = (e, index) => {
  //   const { name, value } = e.target;
  //   const grpObj = updateFieldData.groupVisible[index];
  //   grpObj.perm = value;
  //   updateFieldData.groupVisible[index] = grpObj;
  //   handleBlurData();
  // };
  const handleGrpDelete = (e, grpIndex) => {
    e.preventDefault();
    updateFieldData.groupVisible.splice(grpIndex, 1);
    // const grps = updateFieldData.groupVisible.filter(
    //   (_, index) => index !== grpIndex
    // );
    // setUpdateFieldData({ ...updateFieldData, groupVisible: grps });
    handleBlurData();
  };

  // useEffect for filter group by database
  useEffect(() => {
    if (groups.length > 0) {
      const grps = groups.filter(
        (group) => group.database_obj === processDetail?.database_obj
      );
      const filteredGrps = grps?.filter(
        (group) => group.category_name === processDetail?.process_group_category
      );
      setFilteredGroup(filteredGrps || []);
    } else {
      const getGroups = async () => await fetchAllGroups();
      getGroups();
    }
  }, [groups]);

  // method for python code editor
  const handleCodeEditorClick = (e) => {
    e.preventDefault();
    // setButtonData(selectedFieldData);
    if (selectedFieldData.tabField !== undefined) {
      setButtonData({
        ...selectedFieldData,
        tabField: { ...updateFieldData, pythonCode: null },
      });
    } else if (selectedFieldData.groupField !== undefined) {
      setButtonData({
        ...buttonData,
        groupField: { ...updateFieldData, pythonCode: null },
      });
    } else {
      setButtonData({ ...updateFieldData, pythonCode: null });
    }
    if (
      updateFieldData.pythonCode !== null &&
      updateFieldData.pythonCode !== undefined
    ) {
      setPythonCode(updateFieldData.pythonCode);
    }
    handleFieldDataSubmit();
    navigate("/code-editor");
  };

  // object for styling selection arrow
  const selectStyle = {
    appearance: updateFieldData.type === "char" ? "auto" : "none", // for most modern browsers
    WebkitAppearance: updateFieldData.type === "char" ? "auto" : "none", // for WebKit browsers
    MozAppearance: updateFieldData.type === "char" ? "auto" : "none", // for Mozilla browsers
  };

  // handling on change field data
  const [many2oneFields, setMany2oneFields] = useState(null);
  // useEffect for getting all many2one fields
  useEffect(() => {
    if (formData) {
      const field = parseFormData(formData);
      // const many2one = field.filter(
      //   (field) => !["many2one", "many2many", "many2one"].includes(field.type)
      // );
      const many2one = field.filter((field) => field.type === "many2one");
      setMany2oneFields([...many2one]);
    }
  }, [formData]);

  // code for todo form builder open
  const handleOpenTodoFormBuilder = (e) => {
    e.preventDefault();
    handleFieldDataSubmit();
    setShowFieldDetail(false);
    setIsTodoFormBuilder(true);
    setIsCustomModelForm(false);
    setIsDocumentFormBuilder(false);
    setTodosData([]);
    navigate("/form-builder/todos-builder");
  };
  // code for documents form builder open
  const handleOpenDocumentFormBuilder = (e) => {
    e.preventDefault();
    handleFieldDataSubmit();
    setShowFieldDetail(false);
    setIsCustomModelForm(false);
    setIsTodoFormBuilder(false);
    setIsDocumentFormBuilder(true);
    setDocumentsData([]);
    navigate("/form-builder/documents-builder");
  };
  // code for edit todo field by form builder
  const handleTodosFieldEdit = (e) => {
    e.preventDefault();
    handleFieldDataSubmit();
    setShowFieldDetail(false);
    setIsTodoFormBuilder(true);
    setTodosData(updateFieldData?.todos_field || []);
    navigate("/form-builder/todos-builder");
  };

  // code for edit todo field by form builder
  const handleDocumentsFieldEdit = (e) => {
    e.preventDefault();
    handleFieldDataSubmit();
    setShowFieldDetail(false);
    setIsCustomModelForm(false);
    setIsTodoFormBuilder(false);
    setIsDocumentFormBuilder(true);
    setDocumentsData(updateFieldData?.documents_field || []);
    navigate("/form-builder/documents-builder");
  };
  // method for todos field delete
  const handleTodosFieldDelete = (e) => {
    e.preventDefault();
    setUpdateFieldData((prevFieldData) => ({
      ...prevFieldData,
      todos_field: null,
    }));
    handleFieldDataSubmit();
  };
  const handleDocumentsFieldDelete = (e) => {
    e.preventDefault();
    setUpdateFieldData((prevFieldData) => ({
      ...prevFieldData,
      documents_field: null,
    }));
    handleFieldDataSubmit();
  };

  // console.log(
  //   "FormData--------------========>>>>>>: ",
  //   JSON.stringify(formData)
  // );
  console.log("FormData--------------========>>>>>>: ", formData);
  // console.log("selectedFieldData::::::::: ", JSON.stringify(selectedFieldData));
  // console.log("updateFieldData::: ", JSON.stringify(updateFieldData));
  console.log("odooModels::: ", odooModels);
  console.log("updateFieldData::: ", updateFieldData);
  console.log("updateFieldData::: ", JSON.stringify(updateFieldData));
  console.log("selectedFieldData::: ", selectedFieldData);
  // console.log("selectedModalFields:::::: ", selectedModalFields);

  return (
    <div className="fb_detailUpdate">
      <h2>Field Details</h2>
      {showFieldDetail && (
        <div className="fb_field_details">
          {/* code for field delete button */}
          {updateFieldData.widget !== "remark_field" &&
            updateFieldData.technicalName !== "x_o2b_remark_table" && (
              <button
                className="btn btn-danger fb_field_details_btn"
                onClick={handleDeleteField}
              >
                <MdDeleteOutline className="fb_field_details_btn_icon" />
              </button>
            )}
          <form
            action=""
            className="fb_field_details_form"
            onSubmit={handleUpdateFieldDataSubmit}
          >
            {/* field code for label field */}
            <div className="fb_field_details_form_group">
              <label htmlFor="title">Label</label>
              <input
                type="text"
                name="title"
                id="title"
                value={updateFieldData.title}
                onChange={handleChangeData}
                onBlur={handleBlurData}
                readOnly={
                  [
                    // "create_uid",
                    // "create_date",
                    // "write_uid",
                    // "write_date",
                    // "logged_in_user",
                    "remark_history",
                  ].includes(updateFieldData.type) ||
                  updateFieldData.widget === "remark_field" ||
                  updateFieldData.widget === "defaultField" ||
                  updateFieldData.widget === "default-statinfo" ||
                  updateFieldData.technicalName === "x_o2b_remark_table"
                    ? true
                    : false
                }
              />
              {updateFieldData.widget !== "remark_field" &&
                updateFieldData.technicalName !== "x_o2b_remark_table" &&
                ![
                  "create_uid",
                  "employee_id",
                  "department",
                  "create_date",
                  "write_uid",
                  "write_date",
                  "logged_in_user",
                  "static_image",
                  "button",
                  "object",
                  "action",
                  "remark_history",
                  "separator",
                  "table",
                  "group",
                  "tab",
                  "chatter",
                  "ribbon",
                  "label",
                ].includes(updateFieldData.type) &&
                selectedFieldData.tabName === undefined && (
                  <p
                    style={{
                      fontSize: "12px",
                      width: "100%",
                      marginBottom: "0rem",
                    }}
                  >
                    Technical Name:{" "}
                    <span
                      style={{ wordBreak: "break-word", lineBreak: "anywhere" }}
                    >
                      {updateFieldData.technicalName}
                    </span>
                  </p>
                )}
              {/* {updateFieldData.type !== "label" &&
                updateFieldData.type !== "ribbon" &&
                updateFieldData.type !== "chatter" &&
                updateFieldData.type !== "tab" &&
                updateFieldData.type !== "group" &&
                updateFieldData.type !== "table" &&
                updateFieldData.type !== "separator" &&
                updateFieldData.type !== "remark_history" &&
                updateFieldData.widget !== "remark_field" &&
                updateFieldData.technicalName !== "x_o2b_remark_table" &&
                updateFieldData.type !== "button" &&
                updateFieldData.type !== "object" &&
                updateFieldData.type !== "action" &&
                ![
                  "create_uid",
                  "create_date",
                  "write_uid",
                  "write_date",
                  "logged_in_user",
                  "static_image",
                ].includes(updateFieldData.type) &&
                selectedFieldData.tabName === undefined && (
                  <p
                    style={{
                      fontSize: "12px",
                      width: "100%",
                      marginBottom: "0rem",
                    }}
                  >
                    Technical Name:{" "}
                    <span
                      style={{ wordBreak: "break-word", lineBreak: "anywhere" }}
                    >
                      {updateFieldData.technicalName}
                    </span>
                  </p>
                )} */}
            </div>
            {/* field code for technical field */}
            {/* {updateFieldData.type !== "tab" &&
              updateFieldData.type !== "table" &&
              updateFieldData.type !== "separator" &&
              updateFieldData.type !== "button" &&
              updateFieldData.type !== "object" &&
              updateFieldData.type !== "action" &&
              selectedFieldData.tabName === undefined && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="technicalName">Technical Name</label>
                  <input
                    type="text"
                    name="technicalName"
                    id="technicalName"
                    value={updateFieldData.technicalName}
                    onChange={handleTechnicalNameChange}
                  />
                  {technicalNameError !== "" && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {technicalNameError}
                    </span>
                  )}
                </div>
              )} */}

            {/* field code for type field */}
            {![
              "group",
              "selection",
              "chatter",
              "tab",
              "remark_history",
              "table",
              "separator",
              "label",
              "ribbon",
              "static_image",
              "oe_avatar",
            ].includes(updateFieldData.type) &&
              !["remark_field"].includes(updateFieldData.widget) &&
              selectedFieldData.tabName === undefined && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputTypeSelect">Field Type</label>
                  <select
                    id="inputTypeSelect"
                    name="type"
                    value={updateFieldData.type}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                    disabled
                    // disabled={updateFieldData.type === "char" ? false : true}
                    style={selectStyle}
                  >
                    {inputTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            {/* field code for widget field */}
            {![
              "group",
              "selection",
              "chatter",
              "tab",
              "remark_history",
              "table",
              "separator",
              "label",
              "ribbon",
              "static_image",
              "oe_avatar",
              "tabPage",
              "button",
              "object",
              "action",
            ].includes(updateFieldData.type) &&
              !["remark_field"].includes(updateFieldData.widget) &&
              updateFieldData.type !== "group" && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputWidgetSelect">Widget</label>
                  <select
                    id="inputWidgetSelect"
                    name="widget"
                    value={updateFieldData.widget}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                    disabled
                    // disabled={updateFieldData.widget === "char" ? false : true}
                    style={selectStyle}
                  >
                    {widgets.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            {/* field code for label alignment, font weight, font size field */}
            {["label", "button", "action", "object", "static_image"].includes(
              updateFieldData.type
            ) &&
            !(
              updateFieldData.type === "object" &&
              ["default-statinfo", "statinfo"].includes(updateFieldData.widget)
            ) ? (
              <>
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputAlignmentSelect">Alignment</label>
                  <CustomDropdown
                    name="alignment"
                    defaultValue={updateFieldData.alignment}
                    options={[
                      { label: "Left Align", value: "left" },
                      { label: "Center Align", value: "center" },
                      { label: "Right Align", value: "right" },
                    ]}
                    onChange={(event) => handleChangeData(event)}
                    onBlur={handleBlurData}
                  />
                </div>
                {updateFieldData.type !== "static_image" &&
                  updateFieldData.type !== "object" &&
                  updateFieldData.type !== "action" &&
                  updateFieldData.type !== "button" && (
                    <>
                      <div className="fb_field_details_form_group">
                        <label htmlFor="inputFontSizeSelect">Font Size</label>
                        <CustomDropdown
                          name="fontSize"
                          defaultValue={updateFieldData.fontSize || "24"}
                          options={[
                            "14",
                            "16",
                            "18",
                            "20",
                            "24",
                            "28",
                            "32",
                            "36",
                          ]}
                          onChange={(event) => handleChangeData(event)}
                          onBlur={handleBlurData}
                        />
                      </div>
                      <div className="fb_field_details_form_group">
                        <label htmlFor="inputFontWeightSelect">
                          Font Weight
                        </label>
                        <CustomDropdown
                          name="fontWeight"
                          defaultValue={updateFieldData.fontWeight || "500"}
                          options={["400", "500", "600", "700", "800"]}
                          onChange={(event) => handleChangeData(event)}
                          onBlur={handleBlurData}
                        />
                      </div>
                    </>
                  )}
              </>
            ) : null}
            {/* field code for default value field */}
            {![
              "group",
              "selection",
              "chatter",
              "tab",
              "remark_history",
              "table",
              "separator",
              "label",
              "ribbon",
              "oe_avatar",
              "tabPage",
              "button",
              "object",
              "action",
            ].includes(updateFieldData.type) &&
              !["remark_field", "res_partner_many2one"].includes(
                updateFieldData.widget
              ) && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputDefaultValue">Default Value</label>
                  {["oe_avatar", "static_image"].includes(
                    updateFieldData.type
                  ) ? (
                    <>
                      {updateFieldData?.defaultValue !== "" ? (
                        <div
                          className="d-flex justify-content-between align-items-center ms-3"
                          style={{ width: "90%" }}
                        >
                          <li>{updateFieldData?.fileName}</li>
                          <button
                            className="fdu_group_delete"
                            onClick={(e) => handleFileRemove(e)}
                          >
                            <GiCancel />
                          </button>
                        </div>
                      ) : (
                        <input
                          type="file"
                          accept="image/*"
                          id="inputDefaultValue"
                          name="defaultValue"
                          placeholder="Enter default value"
                          className="form-control"
                          onChange={handleFileChange}
                          onBlur={handleBlurData}
                        />
                      )}
                    </>
                  ) : updateFieldData?.type === "date" ? (
                    <input
                      type="date"
                      id="inputDefaultValue"
                      name="defaultValue"
                      placeholder="Enter default value"
                      value={updateFieldData.defaultValue}
                      onChange={handleChangeData}
                      onBlur={handleBlurData}
                    />
                  ) : updateFieldData?.type === "datetime" ? (
                    <input
                      type="datetime-local"
                      id="inputDefaultValue"
                      name="defaultValue"
                      placeholder="Enter default value"
                      value={updateFieldData.defaultValue}
                      onChange={handleChangeData}
                      onBlur={handleBlurData}
                    />
                  ) : (
                    <input
                      type="text"
                      id="inputDefaultValue"
                      name="defaultValue"
                      placeholder="Enter default value"
                      value={updateFieldData.defaultValue}
                      onChange={handleChangeData}
                      onBlur={handleBlurData}
                    />
                  )}
                </div>
              )}
            {/* code for selection field options */}
            {![
              "group",
              "chatter",
              "tab",
              "remark_history",
              "table",
              "separator",
              "label",
              "ribbon",
              "tabPage",
            ].includes(updateFieldData.type) &&
              updateFieldData.options && (
                <div className="fb_field_details_form_group">
                  <details style={{ width: "100%" }}>
                    <summary>
                      <label htmlFor="inputSelectionOptions">Options</label>
                    </summary>
                    <ul
                      className="d-flex flex-column gap-1 mb-0 ps-4"
                      style={{ width: "100%" }}
                    >
                      {updateFieldData.options &&
                        updateFieldData.options.map((option, index) => (
                          <li
                            key={index}
                            className="d-flex justify-content-between align-items-center gap-3"
                            draggable
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, index)}
                            style={{
                              cursor: "move",
                              fontSize: "14px",
                              padding: "5px 0",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            <div className="d-flex justify-content-start align-items-center gap-1">
                              <RxDragHandleDots2 style={{ fontSize: "20px" }} />
                              <span>{option}</span>
                            </div>
                            <button
                              className="select_option_delete"
                              onClick={(e) => handleOptionDelete(e, index)}
                            >
                              <MdDeleteOutline
                                style={{ fontSize: "20px", color: "#ddd" }}
                              />
                            </button>
                          </li>
                        ))}

                      {!showInputFieldOpt && (
                        <li
                          className="d-flex justify-content-start align-items-center gap-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowInputFieldOpt(true)}
                        >
                          <CiEdit style={{ fontSize: "20px" }} />
                          <span className="selection_option_add_more_button">
                            Add more
                          </span>
                        </li>
                      )}

                      {showInputFieldOpt && (
                        <div
                          className="d-flex justify-content-center align-items-center gap-2"
                          style={{}}
                        >
                          <input
                            type="text"
                            value={newOpt}
                            placeholder="enter option"
                            className="rounded-1"
                            style={{
                              backgroundColor: "white",
                              color: "#222222",
                              paddingLeft: "5px",
                            }}
                            onChange={handleAddMoreOptChange}
                          />
                          <button
                            className="fdu_group_add_button"
                            onClick={handleAddNewOpt}
                          >
                            <MdOutlineCheck />
                          </button>
                          <button
                            className="fdu_group_close_button"
                            onClick={() => setShowInputFieldOpt(false)}
                          >
                            <MdOutlineClose />
                          </button>
                        </div>
                      )}
                    </ul>
                  </details>
                </div>
              )}
            {/* field code for relational fields */}
            {["many2many", "many2one", "one2many"].includes(
              updateFieldData.type
            ) ? (
              <ManyToManyFields
                updateFieldData={updateFieldData}
                handleChangeData={handleChangeData}
                odooModels={odooModels}
                filteredRelatedField={filteredRelatedField}
                handleBlurData={handleBlurData}
                selectedModalFields={selectedModalFields}
              />
            ) : null}
            {/* field code for placeholder field */}
            {![
              "group",
              "selection",
              "chatter",
              "tab",
              "remark_history",
              "table",
              "separator",
              "label",
              "ribbon",
              "static_image",
              "oe_avatar",
              "tabPage",
              "button",
              "object",
              "action",
            ].includes(updateFieldData.type) &&
              !["remark_field"].includes(updateFieldData.widget) &&
              selectedFieldData.tabName === undefined && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="fieldPlaceHolder">Placeholder</label>
                  <input
                    type="text"
                    id="fieldPlaceHolder"
                    name="placeholder"
                    placeholder="Enter placeholder"
                    value={updateFieldData.placeholder}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                  />
                </div>
              )}

            {/* code for button class name */}
            {["button", "object", "action"].includes(updateFieldData.type) &&
              !["default-statinfo", "statinfo"].includes(
                updateFieldData.widget
              ) && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputClassSelect">Class</label>
                  <CustomDropdown
                    name="odooButtonColor"
                    defaultValue={
                      updateFieldData.odooButtonColor || "btn-outline-dark"
                    }
                    options={odooButtonColors}
                    onChange={(event) => handleChangeData(event)}
                    onBlur={handleBlurData}
                  />
                </div>
              )}
            {/* code for smart button icon */}
            {updateFieldData.type === "object" &&
              ["default-statinfo", "statinfo"].includes(
                updateFieldData.widget
              ) && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputIconSelect">Icon</label>
                  <Select
                    id="inputIconSelect"
                    name="smartIcon"
                    value={
                      updateFieldData.smartIcon
                        ? smart_icons.find(
                            (icon) => icon.value === updateFieldData.smartIcon
                          )
                        : { value: "select", label: "Select" }
                    }
                    onChange={handleSmartIconChange}
                    onBlur={handleBlurData}
                    options={smart_icons}
                    isClearable
                    unstyled
                    styles={customStyles}
                  />
                </div>
              )}
            {/* code for todos data smart button */}
            {updateFieldData.type === "object" &&
              ["default-statinfo"].includes(updateFieldData.widget) &&
              updateFieldData.technicalName === "x_o2b_todos" && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputIconSelect">Todos Field</label>
                  {updateFieldData.todos_field === null ||
                  updateFieldData.todos_field === undefined ? (
                    <button
                      onClick={handleOpenTodoFormBuilder}
                      className="domain_condition_btn"
                    >
                      Add Todos Field
                    </button>
                  ) : (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ width: "90%" }}
                    >
                      <span style={{ color: "white", fontSize: "12px" }}>
                        Todos Form View
                      </span>
                      <div
                        className="d-flex justify-content-center align-items-center gap-2"
                        // style={{ width: "20%" }}
                      >
                        <button
                          className="todos_edit_button"
                          onClick={handleTodosFieldEdit}
                        >
                          <MdEditNote style={{ fontSize: "20px" }} />
                        </button>
                        <button
                          className="todos_del_button"
                          onClick={handleTodosFieldDelete}
                        >
                          <FaDeleteLeft style={{ fontSize: "15px" }} />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}

            {/* code for documents data smart button */}
            {updateFieldData.type === "object" &&
              ["default-statinfo"].includes(updateFieldData.widget) &&
              updateFieldData.technicalName === "x_o2b_documents" && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputIconSelect">Documents Field</label>
                  {updateFieldData.documents_field === null ||
                  updateFieldData.documents_field === undefined ? (
                    <button
                      onClick={handleOpenDocumentFormBuilder}
                      className="domain_condition_btn"
                    >
                      Add Documents Field
                    </button>
                  ) : (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ width: "90%" }}
                    >
                      <span style={{ color: "white", fontSize: "12px" }}>
                        Documents Form View
                      </span>
                      <div
                        className="d-flex justify-content-center align-items-center gap-2"
                        // style={{ width: "20%" }}
                      >
                        <button
                          className="todos_edit_button"
                          onClick={handleDocumentsFieldEdit}
                        >
                          <MdEditNote style={{ fontSize: "20px" }} />
                        </button>
                        <button
                          className="todos_del_button"
                          onClick={handleDocumentsFieldDelete}
                        >
                          <FaDeleteLeft style={{ fontSize: "15px" }} />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}

            {/* field code for tooltip field */}
            {![
              "chatter",
              "remark_history",
              "ribbon",
              "static_image",
              "oe_avatar",
            ].includes(updateFieldData.type) &&
              selectedFieldData.tabName === undefined &&
              !["remark_field"].includes(updateFieldData.widget) &&
              updateFieldData.technicalName !== "x_o2b_remark_table" && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="fieldToolTip">Tooltip</label>
                  <input
                    type="text"
                    id="fieldToolTip"
                    name="tooltip"
                    placeholder="Enter tooltip text"
                    value={updateFieldData.tooltip}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                  />
                </div>
              )}

            {/* field code for table columns field */}
            {updateFieldData?.type === "table" &&
              updateFieldData.technicalName !== "x_o2b_remark_table" &&
              updateFieldData?.columns && (
                <div className="fb_field_details_form_group">
                  <details style={{ width: "100%" }}>
                    <summary>
                      <label htmlFor="inputSelectionOptions">Columns</label>
                    </summary>
                    <ul
                      className="d-flex flex-column gap-1 mb-0 ps-4"
                      style={{ width: "100%" }}
                    >
                      {/* code for display existing columns */}
                      {updateFieldData?.columns?.map((column, index) => (
                        <li
                          key={index}
                          className="d-flex justify-content-between align-items-start gap-3"
                          style={{ width: "100%" }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                              width: "80%",
                              wordBreak: "break-all",
                              wordWrap: "break-word",
                              lineBreak: "anywhere",
                            }}
                          >
                            <strong className="me-2">{index + 1}</strong>
                            {updateFieldData?.tableType === "existing"
                              ? column
                              : column.title}
                          </span>
                          <div className="d-flex justify-content-center align-items-center gap-0">
                            {" "}
                            <button
                              className="select_option_edit"
                              onClick={(e) =>
                                handleNewTableColumnEditClick(e, index)
                              }
                            >
                              <MdOutlineEditNote
                                style={{ fontSize: "20px", color: "#ddd" }}
                              />
                            </button>
                            <button
                              className="select_option_delete"
                              onClick={(e) => handleColumnDelete(e, index)}
                            >
                              <MdDeleteOutline
                                style={{ fontSize: "20px", color: "#ddd" }}
                              />
                            </button>
                          </div>
                        </li>
                      ))}
                      {/* code for table show table new field input box */}
                      {!showSelectColumn && (
                        <li
                          className="d-flex justify-content-start align-items-center gap-1"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            setShowSelectColumn(true);
                            setShowSubtotalColumn(false);
                          }}
                        >
                          <CiEdit style={{ fontSize: "20px" }} />
                          <span style={{ fontSize: "14px" }}>Add more</span>
                        </li>
                      )}
                      {/* code for table show table new subtotal field button */}
                      {!showSubtotalColumn &&
                        updateFieldData?.columns?.filter((field) =>
                          ["integer", "float"].includes(field.type)
                        )?.length > 0 && (
                          <li
                            className="d-flex justify-content-start align-items-center gap-1"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              setShowSubtotalColumn(true);
                              setShowSelectColumn(false);
                              setSubtotalFieldEquation("");
                              setSubtotalEquationOperand("");
                              setSubtotalEquationOperator("");
                            }}
                          >
                            <CiEdit style={{ fontSize: "20px" }} />
                            <span style={{ fontSize: "14px" }}>
                              Add Subtotal Field
                            </span>
                          </li>
                        )}
                      {/* code for existing table add field */}
                      {showSelectColumn &&
                        updateFieldData?.tableType === "existing" && (
                          <div className="d-flex justify-content-center align-items-center gap-2">
                            <select
                              name=""
                              id=""
                              onChange={handleTableColumnChange}
                              value={newColumn || ""}
                            >
                              <option value="">Select column</option>
                              {tableRelatedModelFields?.map((field) => (
                                <option key={field}>{field}</option>
                              ))}
                            </select>
                            <button
                              className="fdu_group_add_button"
                              onClick={handleAddNewColumn}
                            >
                              <MdOutlineCheck />
                            </button>
                            <button
                              className="fdu_group_close_button"
                              onClick={() => setShowSelectColumn(false)}
                            >
                              <MdOutlineClose />
                            </button>
                          </div>
                        )}

                      {/* code for new table add field */}
                      {showSelectColumn &&
                        updateFieldData?.tableType === "new" && (
                          <div className="d-flex flex-column justify-content-center align-items-start gap-1">
                            <label htmlFor="">Field Details:</label>
                            <div
                              className="d-flex flex-column justify-content-center align-content-start gap-1"
                              style={{
                                width: "95%",
                                marginTop: "-5px",
                                marginLeft: "5px",
                              }}
                            >
                              <label htmlFor="newTableColumnName">
                                Field Name
                              </label>
                              <input
                                type="text"
                                id="newTableColumnName"
                                name="title"
                                value={newtableColumnFields.title}
                                onChange={handleNewTableColumnChange}
                                placeholder="enter field name"
                                style={{
                                  width: "100%",
                                  height: "25px",
                                  fontSize: "14px",
                                }}
                              />
                              <span
                                style={{ fontSize: "10px", marginLeft: "5px" }}
                              >
                                Technical Name:{" "}
                                {newtableColumnFields.technicalName}
                              </span>
                            </div>
                            <div
                              className="d-flex flex-column justify-content-center align-content-start gap-1"
                              style={{ width: "95%", marginLeft: "5px" }}
                            >
                              <label htmlFor="newTableColumnType">
                                Field Type
                              </label>
                              <select
                                name="type"
                                id="newTableColumnType"
                                onChange={handleNewTableColumnChange}
                                value={newtableColumnFields.type || "char"}
                                style={{
                                  width: "100%",
                                  height: "25px",
                                  fontSize: "14px",
                                }}
                              >
                                {tableFieldTypes?.map((type) => (
                                  <option key={type}>{type}</option>
                                ))}
                              </select>
                            </div>
                            {newtableColumnFields &&
                              newtableColumnFields.type === "selection" && (
                                <div
                                  className="d-flex flex-column justify-content-center align-content-start gap-1"
                                  style={{
                                    width: "95%",
                                    marginTop: "-5px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <label htmlFor="newTableColumnSelectOption">
                                    Field Options
                                  </label>
                                  <input
                                    type="text"
                                    id="newTableColumnSelectOption"
                                    name="options"
                                    value={newOptNewTableSelectionField}
                                    onChange={handleNewTableColumnChange}
                                    placeholder="Option 1, Option 2, Option 3"
                                    style={{
                                      width: "100%",
                                      height: "25px",
                                      fontSize: "14px",
                                    }}
                                  />
                                  <span
                                    style={{
                                      fontSize: "11px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    Enter comma(,) separated options.
                                  </span>
                                </div>
                              )}
                            {/* code for related model field in the new table field */}
                            {newtableColumnFields &&
                              ["many2many", "many2one", "one2many"].includes(
                                newtableColumnFields.type
                              ) && (
                                <div
                                  className="d-flex flex-column justify-content-center align-content-start gap-1"
                                  style={{
                                    width: "95%",
                                    marginTop: "-5px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <label htmlFor="newTableColumnSelectOption">
                                    Related Model
                                  </label>
                                  <CustomDropdown
                                    name="relatedModel"
                                    defaultValue={
                                      newRelatedModelNewTableRelationField
                                    }
                                    options={odooModels?.map((option) => ({
                                      value: option.model,
                                      label: option.model,
                                    }))}
                                    onChange={(event) =>
                                      setNewRelatedModelNewTableRelationField(
                                        event.target.value
                                      )
                                    }
                                    width={"95%"}
                                    height={"35px"}
                                    border="1px solid gray"
                                  />
                                </div>
                              )}
                            {/* code for domain field in the new table field */}
                            {newtableColumnFields.type &&
                              ["many2many", "many2one", "one2many"].includes(
                                newtableColumnFields.type
                              ) && (
                                <div
                                  className="form-group"
                                  style={{ width: "100%", marginBottom: "5px" }}
                                >
                                  <label>Domain</label>
                                  {newDomainNewTableRelationField ? (
                                    <div
                                      style={{ width: "90%" }}
                                      className="ms-3 d-flex justify-content-between align-items-center"
                                    >
                                      <p className="mb-0">
                                        <span>
                                          <TbPointFilled />
                                        </span>
                                        <span
                                          style={{
                                            fontSize: "14px",
                                            wordBreak: "break-all",
                                            lineBreak: "anywhere",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          {newDomainNewTableRelationField}
                                        </span>
                                      </p>
                                      <button
                                        className="fdu_group_delete"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setNewDomainNewTableRelationField(
                                            null
                                          );
                                        }}
                                      >
                                        <GiCancel />
                                      </button>
                                    </div>
                                  ) : (
                                    <DomainComponent
                                      selectedModalFields={
                                        fieldsRelatedModelNewTable
                                      }
                                      newTableField={newtableColumnFields}
                                      setNewTableRelatedModelDomain={
                                        setNewDomainNewTableRelationField
                                      }
                                    />
                                  )}
                                </div>
                              )}
                            {/* code for onchange field for table fields */}
                            {!newtableColumnFields.isSubtotalField && (
                              <div
                                className="fb_field_details_form_group_checkbox_container mt-1 mb-1"
                                style={{ width: "100%" }}
                              >
                                <label
                                  htmlFor="fieldtableisOnChange"
                                  className="label_checkbox"
                                  style={{ fontSize: "14px" }}
                                >
                                  On-Change
                                </label>
                                <label className="checkbox_container">
                                  <input
                                    type="checkbox"
                                    id="fieldtableisOnChange"
                                    name="isOnchange"
                                    checked={on_change.isOnchange || false}
                                    onChange={handleChangeOnchange}
                                  />
                                  <span className="checkmark" />
                                </label>
                              </div>
                            )}
                            {!newtableColumnFields.isSubtotalField &&
                              on_change.isOnchange && (
                                <>
                                  <div
                                    className="fb_field_details_form_group"
                                    style={{ width: "100%" }}
                                  >
                                    <label htmlFor="fieldRelatedField">
                                      Related Field
                                    </label>
                                    <select
                                      name="on_change_relation"
                                      id="fieldRelatedField"
                                      onChange={handleChangeOnchange}
                                      value={on_change.on_change_relation || ""}
                                      style={{ width: "95%" }}
                                    >
                                      <option value="">Select</option>
                                      {updateFieldData.columns &&
                                        updateFieldData.columns
                                          ?.filter(
                                            (field) => field.type === "many2one"
                                          )
                                          .map((field) => (
                                            <option
                                              key={`${field.technicalName}_${field.title}`}
                                              value={field.technicalName}
                                            >
                                              {field.title}
                                            </option>
                                          ))}
                                    </select>
                                  </div>
                                  <div
                                    className="fb_field_details_form_group mb-1"
                                    style={{ width: "100%" }}
                                  >
                                    <label htmlFor="fieldRelatedModelFields">
                                      Related Model Fields
                                    </label>
                                    <select
                                      name="on_change_relation_model_field"
                                      id="fieldRelatedModelFields"
                                      onChange={handleChangeOnchange}
                                      value={
                                        on_change.on_change_relation_model_field ||
                                        ""
                                      }
                                      style={{ width: "95%" }}
                                    >
                                      <option value="">Select</option>
                                      {/* {fieldsRelatedModelNewTable &&
                                        fieldsRelatedModelNewTable */}
                                      {tableRelatedModelFields &&
                                        tableRelatedModelFields
                                          .filter(
                                            (field) =>
                                              field !== "" &&
                                              field !== null &&
                                              field !== undefined
                                          )
                                          .map((field, index) => (
                                            <option
                                              key={`${field}_${field}_${index}`}
                                              value={field}
                                            >
                                              {field}
                                            </option>
                                          ))}
                                    </select>
                                  </div>
                                </>
                              )}

                            {/* code for update equation for subtotal field on new table */}
                            {newtableColumnFields.isSubtotalField && (
                              <div style={{ width: "100%" }}>
                                <label htmlFor="">Generate Equation </label>
                                <input
                                  type="text"
                                  name="equation"
                                  className="form-control"
                                  id="tableFieldName"
                                  value={subtotalFieldEquation}
                                  placeholder="create equation"
                                  style={{ height: "35px", fontSize: "14px" }}
                                  readOnly
                                />
                                <div className="d-flex flex-column justify-content-start align-items-start gap-2 mt-1">
                                  <select
                                    value={subtotalEquationOperand}
                                    onChange={(e) => {
                                      handleEquationAddOperand(e.target.value);
                                    }}
                                  >
                                    <option value="">Select Operand</option>
                                    {updateFieldData?.columns?.filter((field) =>
                                      ["float", "integer"].includes(field.type)
                                    ) &&
                                      updateFieldData?.columns
                                        ?.filter((field) =>
                                          ["float", "integer"].includes(
                                            field.type
                                          )
                                        )
                                        .map((field) => (
                                          <option
                                            key={field.technicalName}
                                            value={field.technicalName}
                                          >
                                            {field.title}
                                          </option>
                                        ))}
                                  </select>
                                  <select
                                    value={subtotalEquationOperator}
                                    onChange={(e) =>
                                      handleEquationSetOperator(e.target.value)
                                    }
                                  >
                                    <option value="">Select Operator</option>
                                    {["+", "-", "*", "/"].map((op) => (
                                      <option key={op} value={op}>
                                        {op}
                                      </option>
                                    ))}
                                  </select>
                                  <button
                                    className="table_newfield_add_button"
                                    onClick={handleEquationClear}
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            )}

                            <div className="d-flex justify-content-center align-content-start gap-1">
                              <button
                                className="fdu_group_add_button"
                                onClick={
                                  newTableColumnEditClick.status === true
                                    ? handleUpdateNewTableColumn
                                    : handleAddNewTableColumn
                                }
                              >
                                <MdOutlineCheck />
                              </button>
                              <button
                                className="fdu_group_close_button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowSelectColumn(false);
                                  setOnChange({
                                    isOnchange: false,
                                    on_change_relation: "",
                                    on_change_relation_model_field: "",
                                  });
                                  setNewDomainNewTableRelationField(null);
                                  setNewRelatedModelNewTableRelationField(null);
                                  setNewTableColumnFields({
                                    title: "",
                                    technicalName: "x_o2b_",
                                    type: "char",
                                    isSubtotalField: false,
                                  });
                                  setNewTableColumnEditClick({
                                    status: false,
                                    index: null,
                                  });
                                }}
                              >
                                <MdOutlineClose />
                              </button>
                            </div>
                          </div>
                        )}
                      {showSubtotalColumn &&
                        updateFieldData?.columns?.filter((field) =>
                          ["integer", "float"].includes(field.type)
                        )?.length > 0 &&
                        updateFieldData?.tableType === "new" && (
                          <div className="d-flex flex-column justify-content-center align-items-start gap-1">
                            <label htmlFor="">Field Details:</label>
                            <div
                              className="d-flex flex-column justify-content-center align-content-start gap-1"
                              style={{
                                width: "95%",
                                marginTop: "-5px",
                                marginLeft: "5px",
                              }}
                            >
                              <label htmlFor="newTableColumnName">
                                Field Name
                              </label>
                              <input
                                type="text"
                                id="newTableColumnName"
                                name="title"
                                value={newtableColumnFields.title}
                                onChange={handleNewTableColumnChange}
                                placeholder="enter field name"
                                style={{
                                  width: "100%",
                                  height: "25px",
                                  fontSize: "14px",
                                }}
                              />
                              <span
                                style={{ fontSize: "10px", marginLeft: "5px" }}
                              >
                                Technical Name:{" "}
                                {newtableColumnFields.technicalName}
                              </span>
                            </div>
                            <div
                              className="d-flex flex-column justify-content-center align-content-start gap-1"
                              style={{ width: "95%", marginLeft: "5px" }}
                            >
                              <label htmlFor="newTableColumnType">
                                Field Type
                              </label>
                              <select
                                name="type"
                                id="newTableColumnType"
                                onChange={handleNewTableColumnChange}
                                value={newtableColumnFields.type || "float"}
                                style={{
                                  width: "100%",
                                  height: "25px",
                                  fontSize: "14px",
                                }}
                              >
                                {tableFieldTypes
                                  ?.filter((ftype) =>
                                    ["float", "integer"].includes(ftype)
                                  )
                                  ?.map((type) => (
                                    <option key={type}>{type}</option>
                                  ))}
                              </select>
                            </div>
                            <div style={{ width: "100%" }}>
                              <label htmlFor="">Generate Equation </label>
                              <input
                                type="text"
                                name="equation"
                                className="form-control"
                                id="tableFieldName"
                                value={subtotalFieldEquation}
                                placeholder="create equation"
                                style={{ height: "35px", fontSize: "14px" }}
                                readOnly
                              />
                              <div className="d-flex flex-column justify-content-start align-items-start gap-2 mt-1">
                                <select
                                  value={subtotalEquationOperand}
                                  onChange={(e) => {
                                    handleEquationAddOperand(e.target.value);
                                  }}
                                >
                                  <option value="">Select Operand</option>
                                  {updateFieldData?.columns?.filter((field) =>
                                    ["float", "integer"].includes(field.type)
                                  ) &&
                                    updateFieldData?.columns
                                      ?.filter((field) =>
                                        ["float", "integer"].includes(
                                          field.type
                                        )
                                      )
                                      .map((field) => (
                                        <option
                                          key={field.technicalName}
                                          value={field.technicalName}
                                        >
                                          {field.title}
                                        </option>
                                      ))}
                                </select>
                                <select
                                  value={subtotalEquationOperator}
                                  onChange={(e) =>
                                    handleEquationSetOperator(e.target.value)
                                  }
                                >
                                  <option value="">Select Operator</option>
                                  {["+", "-", "*", "/"].map((op) => (
                                    <option key={op} value={op}>
                                      {op}
                                    </option>
                                  ))}
                                </select>
                                <button
                                  className="table_newfield_add_button"
                                  onClick={handleEquationClear}
                                >
                                  Clear
                                </button>
                              </div>
                            </div>

                            <div className="d-flex justify-content-center align-content-start gap-1">
                              <button
                                className="fdu_group_add_button"
                                onClick={
                                  newTableColumnEditClick.status === true
                                    ? handleUpdateNewTableColumn
                                    : handleAddNewTableColumn
                                }
                              >
                                <MdOutlineCheck />
                              </button>
                              <button
                                className="fdu_group_close_button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowSubtotalColumn(false);
                                  setSubtotalFieldEquation("");
                                  setOnChange({
                                    isOnchange: false,
                                    on_change_relation: "",
                                    on_change_relation_model_field: "",
                                  });
                                  setNewDomainNewTableRelationField(null);
                                  setNewRelatedModelNewTableRelationField(null);
                                  setNewTableColumnFields({
                                    title: "",
                                    technicalName: "x_o2b_",
                                    type: "char",
                                    isSubtotalField: false,
                                  });
                                  setNewTableColumnEditClick({
                                    status: false,
                                    index: null,
                                  });
                                }}
                              >
                                <MdOutlineClose />
                              </button>
                            </div>
                          </div>
                        )}
                    </ul>
                  </details>
                  {updateFieldData.columns?.filter((field) =>
                    ["float", "integer"].includes(field.type)
                  )?.length > 0 && (
                    <>
                      <div className="fb_field_details_form_group_checkbox_container mt-2">
                        <label
                          htmlFor="fieldTotalTable"
                          className="label_checkbox"
                        >
                          Add Total Field
                        </label>
                        <label className="checkbox_container">
                          <input
                            type="checkbox"
                            id="fieldTotalTable"
                            name="isTotalField"
                            checked={updateFieldData.isTotalField}
                            onChange={handleCheckBoxChange}
                            onBlur={handleBlurData}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      {updateFieldData.isTotalField && (
                        <div className="d-flex justify-content-start align-items-start gap-2 mt-1">
                          <select
                            name="totalfield_field"
                            value={updateFieldData.totalfield_field}
                            onChange={handleChangeData}
                            onBlur={handleBlurData}
                          >
                            <option value="">Select Field</option>
                            {updateFieldData.columns
                              ?.filter((field) =>
                                ["float", "integer"].includes(field.type)
                              )
                              ?.map((field) => (
                                <option
                                  key={field.technicalName}
                                  value={field.technicalName}
                                >
                                  {field.title}
                                </option>
                              ))}
                          </select>
                          <select
                            name="totalfield_operator"
                            value={updateFieldData.totalfield_operator || "+"}
                            onChange={handleChangeData}
                            onBlur={handleBlurData}
                          >
                            <option value="">Select Operator</option>
                            {["+", "-", "*", "/"].map((op) => (
                              <option key={op} value={op}>
                                {op}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

            {/* field code for group field */}
            {selectedFieldData.tabName === undefined &&
              updateFieldData.type !== "chatter" &&
              !["remark_field"].includes(updateFieldData.widget) &&
              updateFieldData.type !== "static_image" &&
              updateFieldData.technicalName !== "x_o2b_remark_table" &&
              updateFieldData.type !== "ribbon" && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="availGroups">Group Visibilty</label>
                  <div className="fdu_group_box">
                    {/* old working code========= */}
                    {updateFieldData.groupVisible &&
                      updateFieldData.groupVisible.length > 0 && (
                        <ul>
                          <>
                            {updateFieldData.groupVisible.length > 0 &&
                              updateFieldData.groupVisible.map(
                                (names, index) => (
                                  <li key={index} className="fdu_group_list">
                                    <span style={{ fontSize: "14px" }}>
                                      {index + 1}.
                                    </span>
                                    <span className="fdu_group_name_span">
                                      {names}
                                    </span>
                                    <button
                                      className="fdu_group_delete"
                                      onClick={(e) => handleGrpDelete(e, index)}
                                    >
                                      <GiCancel />
                                    </button>
                                  </li>
                                )
                              )}
                          </>
                        </ul>
                      )}
                    {/* new code for group======= */}
                    {/* {updateFieldData.groupVisible &&
                      updateFieldData.groupVisible.length > 0 && (
                        <ul>
                          <>
                            {updateFieldData.groupVisible.length > 0 &&
                              updateFieldData.groupVisible.map((grp, index) => (
                                <li
                                  key={index}
                                  className="d-flex flex-column justify-content-center align-items-start mb-0"
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    className="d-flex justify-content-between align-items-center"
                                    style={{ width: "80%" }}
                                  >
                                    <p className="m-0">
                                      <span style={{ fontSize: "14px" }}>
                                        {index + 1}.
                                      </span>
                                      <span className="fdu_group_name_span">
                                        {grp.group}
                                      </span>
                                    </p>
                                    <button
                                      className="fdu_group_delete"
                                      onClick={(e) => handleGrpDelete(e, index)}
                                    >
                                      <GiCancel />
                                    </button>
                                  </div>
                                  <div
                                    className="d-flex justify-content-start align-items-center gap-3"
                                    style={{ width: "80%" }}
                                  >
                                    <div className="d-flex justify-content-start align-items-center gap-1">
                                      <input
                                        type="radio"
                                        style={{ width: "12px" }}
                                        id={`r_${index}`}
                                        name={`gprw_${index}`}
                                        value="read"
                                        checked={grp.perm === "read"}
                                        onChange={(e) =>
                                          handleGroupPermission(e, index)
                                        }
                                      />
                                      <label for={`r_${index}`}>R</label>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-center gap-1">
                                      <input
                                        type="radio"
                                        style={{ width: "12px" }}
                                        id={`rw_${index}`}
                                        name={`gprw_${index}`}
                                        value="read_write"
                                        checked={grp.perm === "read_write"}
                                        onChange={(e) =>
                                          handleGroupPermission(e, index)
                                        }
                                      />
                                      <label for={`rw_${index}`}>R/W</label>
                                    </div>
                                  </div>
                                </li>
                              ))}
                          </>
                        </ul>
                      )} */}
                    {/* ================================ */}
                    {showGrpInput ? (
                      <div className="fdu_group_input_box">
                        {/* old working code for group select========= */}
                        <select
                          name="group"
                          id="group"
                          onChange={handleAddNewGroup}
                        >
                          <option value="">Select group access</option>
                          {filteredGroup
                            ?.filter(
                              (grp) =>
                                !updateFieldData?.groupVisible?.includes(
                                  `base.${grp.group_internal}`
                                )
                            )
                            ?.map((group, index) => (
                              <option
                                key={index}
                                value={`base.${group.group_internal}`}
                              >
                                {group.group_name}
                              </option>
                            ))}
                        </select>
                        {/* new working code for group select========= */}
                        {/* <select
                          name="group"
                          id="group"
                          onChange={handleAddNewGroup}
                        >
                          <option value="">Select group access</option>
                          {filteredGroup
                            ?.filter(
                              (grp) =>
                                !updateFieldData.groupVisible
                                  ?.map((grpData) => grpData.group)
                                  .includes(`base.${grp.group_internal}`)
                            )
                            ?.map((group, index) => (
                              <option
                                key={index}
                                value={`base.${group.group_internal}`}
                              >
                                {group.group_name}
                              </option>
                            ))}
                        </select> */}
                        {/* ============================ */}
                        <button
                          className="fdu_group_close_button"
                          onClick={() => setShowGrpInput(false)}
                        >
                          <MdOutlineClose />
                        </button>
                      </div>
                    ) : (
                      <>
                        <span
                          onClick={() => setShowGrpInput(true)}
                          className="fdu_group_show_input"
                        >
                          Add group access
                        </span>
                      </>
                    )}
                  </div>
                </div>
              )}

            {/* field code for invisible field */}
            {!["chatter", "ribbon"].includes(updateFieldData.type) &&
              updateFieldData.technicalName !== "x_o2b_remark_table" &&
              updateFieldData.widget !== "remark_field" && (
                <div className="fb_field_details_form_group_checkbox_container">
                  <label htmlFor="fieldInvisible" className="label_checkbox">
                    Hidden
                  </label>
                  <label className="checkbox_container">
                    <input
                      type="checkbox"
                      id="fieldInvisible"
                      name="isInvisible"
                      checked={updateFieldData.isInvisible}
                      onChange={handleCheckBoxChange}
                      onBlur={handleBlurData}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              )}
            {/* field code for checkboxes and required and readonly field */}
            {![
              "group",
              "chatter",
              "tab",
              "remark_history",
              "table",
              "separator",
              "label",
              "ribbon",
              "static_image",
              "button",
              "object",
              "action",
              "create_uid",
              "employee_id",
              "department",
              "create_date",
              "write_uid",
              "write_date",
              "logged_in_user",
            ].includes(updateFieldData.type) &&
              // !["remark_field"].includes(updateFieldData.widget) &&
              selectedFieldData.tabName === undefined && (
                <>
                  {/* {![
                    "create_uid",
                    "create_date",
                    "write_uid",
                    "write_date",
                    "logged_in_user",
                  ].includes(updateFieldData.type) && ( */}
                  <div className="fb_field_details_form_group_checkbox_container">
                    <label htmlFor="fieldRequired" className="label_checkbox">
                      Mandatory
                    </label>
                    <label className="checkbox_container">
                      <input
                        type="checkbox"
                        id="fieldRequired"
                        name="isRequired"
                        checked={updateFieldData.isRequired}
                        onChange={handleCheckBoxChange}
                        onBlur={handleBlurData}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  {/* )} */}
                  {!["remark_field"].includes(updateFieldData.widget) && (
                    <div className="fb_field_details_form_group_checkbox_container">
                      <label htmlFor="fieldReadOnly" className="label_checkbox">
                        Read-only
                      </label>
                      <label className="checkbox_container">
                        <input
                          type="checkbox"
                          id="fieldReadOnly"
                          name="isReadOnly"
                          checked={updateFieldData.isReadOnly}
                          onChange={handleCheckBoxChange}
                          onBlur={handleBlurData}
                          disabled={
                            [
                              "create_uid",
                              "employee_id",
                              "department",
                              "create_date",
                              "write_uid",
                              "write_date",
                              "logged_in_user",
                            ].includes(updateFieldData.type) && true
                          }
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  )}
                  {!["many2many", "one2many"].includes(
                    updateFieldData.type
                  ) && (
                    <div className="fb_field_details_form_group_checkbox_container">
                      <label htmlFor="fieldTracking" className="label_checkbox">
                        Trackable
                      </label>
                      <label className="checkbox_container">
                        <input
                          type="checkbox"
                          id="fieldTracking"
                          name="isTracking"
                          checked={updateFieldData.isTracking}
                          onChange={handleCheckBoxChange}
                          onBlur={handleBlurData}
                          disabled={
                            [
                              "create_uid",
                              "employee_id",
                              "department",
                              "create_date",
                              "write_uid",
                              "write_date",
                              "logged_in_user",
                            ].includes(updateFieldData.type) && true
                          }
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  )}
                  {!["remark_field"].includes(updateFieldData.widget) && (
                    <div className="fb_field_details_form_group_checkbox_container">
                      <label
                        htmlFor="checkcustomOne"
                        className="label_checkbox"
                      >
                        On-change
                      </label>
                      <label className="checkbox_container">
                        <input
                          type="checkbox"
                          id="checkcustomOne"
                          name="isOnChange"
                          checked={updateFieldData.isOnChange}
                          onChange={handleCheckBoxChange}
                          onBlur={handleBlurData}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  )}
                </>
              )}

            {/* code for can create and update checkbox */}
            {["many2many", "many2one", "one2many"].includes(
              updateFieldData.type
            ) && (
              <div
                className="fb_field_details_form_group"
                // style={{ width: "90%" }}
              >
                <label htmlFor="inputDefaultValue">Optional</label>
                {["many2one", "many2many"].includes(updateFieldData.type) && (
                  <div
                    className="fb_field_details_form_group_checkbox_container"
                    style={{ width: "98%" }}
                  >
                    <label htmlFor="fieldisCreate" className="label_checkbox">
                      Can Create
                    </label>
                    <label className="checkbox_container">
                      <input
                        type="checkbox"
                        id="fieldisCreate"
                        name="isCreate"
                        checked={updateFieldData.isCreate}
                        onChange={handleCheckBoxChange}
                        onBlur={handleBlurData}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                )}
                {["one2many", "many2many"].includes(updateFieldData.type) && (
                  <>
                    <div
                      className="fb_field_details_form_group_checkbox_container"
                      style={{ width: "98%" }}
                    >
                      <label htmlFor="fieldisEdit" className="label_checkbox">
                        Can Edit
                      </label>
                      <label className="checkbox_container">
                        <input
                          type="checkbox"
                          id="fieldisEdit"
                          name="isEdit"
                          checked={updateFieldData.isEdit}
                          onChange={handleCheckBoxChange}
                          onBlur={handleBlurData}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>

                    <div
                      className="fb_field_details_form_group_checkbox_container"
                      style={{ width: "98%" }}
                    >
                      <label htmlFor="fieldisDelete" className="label_checkbox">
                        Can Delete
                      </label>
                      <label className="checkbox_container">
                        <input
                          type="checkbox"
                          id="fieldisDelete"
                          name="isDelete"
                          checked={updateFieldData.isDelete}
                          onChange={handleCheckBoxChange}
                          onBlur={handleBlurData}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </>
                )}
              </div>
            )}

            {/* code for onchange fields data */}
            {updateFieldData.isOnChange && (
              <>
                <div className="fb_field_details_form_group">
                  <label htmlFor="fieldRelatedField">Related Field</label>
                  <select
                    name="on_change_relation"
                    id="fieldRelatedField"
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                    value={updateFieldData.on_change_relation || ""}
                  >
                    <option value="">Select</option>
                    {many2oneFields &&
                      many2oneFields.map((field) => (
                        <option
                          key={`${field.technicalName}_${field.title}`}
                          value={field.technicalName}
                        >
                          {field.title}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="fb_field_details_form_group">
                  <label htmlFor="fieldRelatedModelFields">
                    Related Model Fields
                  </label>
                  <select
                    name="on_change_relation_model_field"
                    id="fieldRelatedModelFields"
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                    value={updateFieldData.on_change_relation_model_field || ""}
                  >
                    <option value="">Select</option>
                    {allFieldRelatedModel &&
                      allFieldRelatedModel
                        .filter(
                          (field) =>
                            field !== "" &&
                            field !== null &&
                            field !== undefined
                        )
                        .map((field, index) => (
                          <option
                            key={`${field}_${field}_${index}`}
                            value={field}
                          >
                            {field}
                          </option>
                        ))}
                  </select>
                </div>
              </>
            )}

            {/* field code for code editor button */}
            {["button", "object", "action"].includes(updateFieldData.type) &&
            updateFieldData.widget !== "default-statinfo" ? (
              <>
                {!updateFieldData.pythonCode && (
                  <button
                    className="code_editor_button"
                    onClick={handleCodeEditorClick}
                  >
                    Write Python Code
                  </button>
                )}
                {updateFieldData.pythonCode && (
                  <div
                    style={{ width: "90%" }}
                    className="d-flex justify-content-between align-content-center"
                  >
                    <span style={{ fontSize: "14px" }}>Python Code</span>
                    <button
                      className="cde_edit_button"
                      onClick={handleCodeEditorClick}
                    >
                      Edit
                    </button>
                  </div>
                )}
              </>
            ) : (
              ""
            )}

            {/* field code for ribbon */}
            {updateFieldData.type === "ribbon" && (
              <>
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputRibbonBgSelect">Background Color</label>
                  <select
                    id="inputRibbonBgSelect"
                    name="ribbonBgColor"
                    value={updateFieldData.ribbonBgColor || "bg-danger"}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                    // style={selectStyle}
                  >
                    <option value="bg-danger">bg-danger</option>
                    <option value="bg-success">bg-success</option>
                    <option value="bg-primary">bg-primary</option>
                    <option value="bg-warning">bg-warning</option>
                    <option value="bg-secondary">bg-secondary</option>
                    <option value="bg-info">bg-info</option>
                    <option value="bg-dark">bg-dark</option>
                  </select>
                </div>
              </>
            )}

            <button type="submit" className="btn fb_field_details_form_submit">
              Save
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default memo(FormDetailUpdate);

const ManyToManyFields = memo(
  ({
    updateFieldData,
    handleChangeData,
    odooModels,
    filteredRelatedField,
    handleBlurData,
    selectedModalFields,
  }) => {
    const options = odooModels?.map((option) => ({
      value: option.model,
      label: option.model,
    }));

    // Handler for when an option is selected
    const handleSelectChange = (selectedOption) => {
      const event = {
        target: {
          name: "relatedModel",
          value: selectedOption ? selectedOption.value : "",
        },
      };
      handleChangeData(event);
    };

    // Handle for domin delete
    const handleDomainDelete = (e) => {
      e.preventDefault();
      updateFieldData.domain = null;
      handleBlurData();
    };
    return (
      <>
        <div className="fb_field_details_form_group">
          <label htmlFor="relatedModelSelect">Related Model</label>
          <Select
            id="relatedModelSelect"
            name="relatedModel"
            value={
              updateFieldData.relatedModel
                ? options?.find(
                    (option) => option.value === updateFieldData.relatedModel
                  )
                : { value: "select", label: "Select" }
            }
            onChange={handleSelectChange}
            onBlur={handleBlurData}
            options={options}
            isClearable
            unstyled
            styles={customStyles}
            isDisabled={
              updateFieldData?.widget === "res_partner_many2one"
                ? true
                : updateFieldData.isModelSaved
                ? true
                : false
            }
          />
        </div>
        {updateFieldData.type !== "many2one" && (
          <>
            {updateFieldData.type === "many2many" && (
              <div className="fb_field_details_form_group">
                <label htmlFor="inputRelationTable">Relation Table</label>
                <input
                  type="text"
                  name="relationTable"
                  id="inputRelationTable"
                  placeholder="Enter details here..."
                  value={updateFieldData.relationTable || ""}
                  onChange={handleChangeData}
                  onBlur={handleBlurData}
                />
              </div>
            )}
            {updateFieldData.type === "one2many" && (
              <div className="fb_field_details_form_group">
                <label htmlFor="inputRelationField">Relation Field</label>
                <input
                  type="text"
                  name="relationField"
                  id="inputRelationField"
                  list="relatedFieldList"
                  placeholder="Enter details here..."
                  value={updateFieldData.relationField || ""}
                  onChange={handleChangeData}
                  onBlur={handleBlurData}
                />
                <datalist id="relatedFieldList">
                  {filteredRelatedField?.map((relatedField, index) => (
                    <option key={index} value={relatedField.name}></option>
                  ))}
                </datalist>
              </div>
            )}
          </>
        )}
        <div className="fb_field_details_form_group">
          <label>Domain</label>
          {updateFieldData?.domain ? (
            <div
              style={{ width: "90%" }}
              className="ms-3 d-flex justify-content-between align-items-center"
            >
              <p className="mb-0">
                <span>
                  <TbPointFilled />
                </span>
                <span
                  style={{
                    fontSize: "14px",
                    wordBreak: "break-all",
                    lineBreak: "anywhere",
                    wordWrap: "break-word",
                  }}
                >
                  {updateFieldData?.domain}
                </span>
              </p>
              <button className="fdu_group_delete" onClick={handleDomainDelete}>
                <GiCancel />
              </button>
            </div>
          ) : (
            <DomainComponent
              selectedModalFields={selectedModalFields}
              updateFieldData={updateFieldData}
              handleBlurData={handleBlurData}
            />
          )}
        </div>
      </>
    );
  }
);
