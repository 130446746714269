import React, { useEffect, useState } from "react";
import "../doctype_modal/DoctypeCreateOverlay.css";
import { useNodeContext } from "../../context/NodeContext";
import { useProcessContext } from "../../context/ProcessContext";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import { IoMdClose } from "react-icons/io";

const TodosCreateOverlay = ({
  shapeData,
  editingTodosIndex,
  setEditingTodosIndex,
  editTodos,
}) => {
  const { setNodes, shapeId } = useNodeContext();
  const { topRightAlert, centerAlert } = useProcessContext();
  const [todos, setTodos] = useState({
    todos_name: "",
    todos_detail: "",
    isMandatory: false,
  });

  useEffect(() => {
    if (editTodos) {
      setTodos(editTodos);
    }
  }, [editTodos]);

  const resetTodosForm = () => {
    setTodos({
      todos_name: "",
      todos_detail: "",
      isMandatory: false,
    });
    setEditingTodosIndex(null);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTodos((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleAddMore = (e) => {
    e.preventDefault();
    if (!todos.todos_name) {
      centerAlert("error", "Name field is mandatory to set.");
      return;
    }

    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? {
              ...node,
              data: {
                ...node.data,
                todos: Array.isArray(shapeData.todos)
                  ? [...shapeData.todos, todos]
                  : [todos],
              },
            }
          : node
      )
    );
    topRightAlert("success", "Todos created successfully.");

    // Reset form fields
    resetTodosForm();
  };

  const handleAddAndClose = (e) => {
    e.preventDefault();
    if (!todos.todos_name) {
      centerAlert("error", "Document Type field is mandatory to set.");
      return;
    }

    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? {
              ...node,
              data: {
                ...node.data,
                todos:
                  editingTodosIndex !== null
                    ? shapeData.todos.map((item, idx) =>
                        idx === editingTodosIndex ? todos : item
                      )
                    : [...(shapeData.todos || []), todos],
              },
            }
          : node
      )
    );

    topRightAlert(
      "success",
      `Todos ${
        editingTodosIndex !== null ? "updated" : "created"
      } successfully.`
    );

    resetTodosForm();
    closeModal();
  };
  const closeModal = () => {
    const modalElement = document.getElementById("todosCreateModal");
    const modalInstance = bootstrapBundleMin.Modal.getInstance(modalElement);
    modalInstance.hide();
  };

  const handleCancel = () => {
    // Close form without submitting and reset fields
    resetTodosForm();
  };
  return (
    <div
      className="modal fade"
      id="todosCreateModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="todosCreateModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content form_modal_content">
          <div className="modal-header form_modal_header">
            <h1 className="modal-title fs-5" id="formModalLabel">
              {editingTodosIndex !== null ? "Edit Todos" : "Create Todos"}
            </h1>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                position: "absolute",
                right: "20px",
                fontSize: "25px",
                color: "white",
              }}
              onClick={handleCancel}
            >
              <IoMdClose />
            </button>
          </div>
          <form
            method="post"
            onSubmit={handleAddAndClose}
            className="form_create_modal"
          >
            <div className="modal-body form_modal_body">
              <div className="formbuilderModalForm">
                <div className="formbuilderModalForm_inputField">
                  <label htmlFor="todos_type">Todos Name*:</label>
                  <input
                    id="todos_type"
                    type="text"
                    name="todos_name"
                    placeholder="Enter todos"
                    value={todos.todos_name}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    required
                  />
                </div>
                <div className="formbuilderModalForm_inputField">
                  <label htmlFor="todos_detail">Todos Detail:</label>
                  <textarea
                    name="todos_detail"
                    id="todos_detail"
                    rows={5}
                    value={todos.todos_detail}
                    placeholder="Enter todos detail"
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div
                  className="d-flex justify-content-start align-items-center gap-4"
                  style={{ color: "#383838", width: "100%" }}
                >
                  <label className="checkbox_container">
                    <input
                      id="fieldIsMandatory1"
                      type="checkbox"
                      name="isMandatory"
                      checked={todos.isMandatory}
                      onChange={handleChange}
                    />
                    <span className="checkmark" />
                  </label>
                  <label htmlFor="fieldIsMandatory1" className="label_checkbox">
                    Is Mandatory
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-footer form_modal_footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleCancel}
              >
                Close
              </button>
              {editingTodosIndex === null && (
                <button
                  type="submit"
                  className="btn modal_save_button"
                  onClick={handleAddMore}
                >
                  Save & Next
                </button>
              )}
              <button
                type="submit"
                className="btn modal_save_button"
                onClick={handleAddAndClose}
              >
                {editingTodosIndex !== null ? "Update" : "Save & Close"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TodosCreateOverlay;
