// NodeContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { useProcessContext } from "./ProcessContext";
import BASE_URL, { PAYLOAD_SECRET } from "../../config";

const NodeContext = createContext();

const initialNodes = [
  {
    id: "node-1",
    type: "start",
    position: { x: 0, y: 0 },
    data: {
      value: "start",
      action: "",
      form: "",
      isFormSelected: false,
      isFormConsent: false,
      kanbanData: null,
      prev: null,
      current: null,
      next: null,
    },
  },
];

const initialEdges = [];

export const NodeProvider = ({ children }) => {
  const { topRightAlert, newProcessId, encryptData } = useProcessContext();
  const [nodes, setNodes] = useState(() => {
    const savedNodes = sessionStorage.getItem("nodes");
    return savedNodes ? JSON.parse(savedNodes) : initialNodes;
  });
  const [edges, setEdges] = useState(() => {
    const savedEdges = sessionStorage.getItem("edges");
    return savedEdges ? JSON.parse(savedEdges) : initialEdges;
  });
  const [nodesJsonData, setNodesJsonData] = useState(JSON.stringify(nodes));
  const [edgesJsonData, setEdgesJsonData] = useState(JSON.stringify(edges));
  const [showShapeUpdateForm, setShowShapeUpdateForm] = useState(true);
  const [shapeId, setShapeId] = useState(() => {
    const savedShapeId = sessionStorage.getItem("shapeId");
    return savedShapeId ? sessionStorage.getItem("shapeId") : null;
  });
  const [nodeAction, setNodeAction] = useState([]);

  useEffect(() => {
    setNodesJsonData(JSON.stringify(nodes));
  }, [nodes]);

  useEffect(() => {
    setEdgesJsonData(JSON.stringify(edges));
  }, [edges]);
  useEffect(() => {
    sessionStorage.setItem("nodes", JSON.stringify(nodes));
  }, [nodes]);

  useEffect(() => {
    sessionStorage.setItem("edges", JSON.stringify(edges));
  }, [edges]);

  useEffect(() => {
    sessionStorage.setItem("shapeId", shapeId);
  }, [shapeId]);

  // method for remove edge on change
  const removeEdge = (sourceId, targetId) => {
    console.log("edge remove::: inside method:: sourceId: ", sourceId);
    console.log("edge remove::: inside method:: targetId: ", targetId);

    let allEdges = JSON.parse(JSON.stringify(edges)) || [];
    const existEdge = allEdges.find(
      (edge) => edge.source === sourceId && edge.target === targetId
    );
    console.log("edge remove::: inside method:: exist edge: ", existEdge);

    if (existEdge) {
      allEdges = allEdges.filter((edge) => edge !== existEdge);
    }
    setEdges([...allEdges]);
  };

  // method for add new edge
  const addEdge = (sourceId, targetId) => {
    let allEdges = JSON.parse(JSON.stringify(edges)) || [];
    const existEdge = edges.find(
      (edge) => edge.source === sourceId && edge.target === targetId
    );

    if (!existEdge) {
      const newEdge = {
        source: sourceId,
        sourceHandle: "a",
        target: targetId,
        type: "buttonedge",
        markerEnd: {
          type: "arrowclosed",
          color: "#198754",
        },
        style: {
          strokeWidth: 1,
          stroke: "#198754",
        },
        id: `xy-edge__${sourceId}a-${targetId}`,
      };
      allEdges.push(newEdge);
    }
    allEdges = allEdges.filter((edge) => {
      const source = edge.source;
      const target = edge.target;
      const sourceNode = nodes.find((node) => node.id === source);
      const targetNode = nodes.find((node) => node.id === target);
      if (sourceNode && targetNode) {
        return true;
      } else {
        return false;
      }
    });
    setEdges([...allEdges]);
    // setNodes((nds) =>
    //   nds.map((node) => {
    //     if (node.id === sourceId) {
    //       const targetNode = nds.find((n) => n.id === targetId);
    //       // if (!["decision", "email_verify"].includes(node.type)) {
    //       if (!["decision"].includes(node.type)) {
    //         return {
    //           ...node,
    //           data: {
    //             ...node.data,
    //             next: targetNode?.id || null,
    //             next_name: targetNode?.data?.value || null,
    //           },
    //         };
    //       }
    //     }
    //     if (node.id === targetId) {
    //       const sourceNode = nds.find((n) => n.id === sourceId);
    //       return {
    //         ...node,
    //         data: {
    //           ...node.data,
    //           prev: sourceNode?.id || null,
    //           prev_name: sourceNode?.data?.value || null,
    //         },
    //       };
    //     }
    //     return node;
    //   })
    // );
  };

  // method for nodes sequence
  function sequenceNodes(activities) {
    const nodeMap = new Map();
    const visited = new Set();
    const sequencedNodes = [];

    // Create a map of nodes for quick lookup
    activities.forEach((node) => nodeMap.set(node.activity_id, node));

    // Recursive function to traverse nodes
    function traverseNode(nodeId) {
      if (visited.has(nodeId)) return; // Avoid processing the same node twice
      const node = nodeMap.get(nodeId);
      if (!node) return; // Skip if the node does not exist

      visited.add(nodeId);
      sequencedNodes.push(node); // Add the current node to the sequence

      // Skip further traversal for nodes with special types
      if (
        ["end", "discard", "reject", "exception"].includes(node.activity_type)
      ) {
        return;
      }

      if (
        node.activity_type === "decision" &&
        Array.isArray(node.activity_next_name)
      ) {
        // Handle decision nodes with multiple next steps
        node.activity_next_name.forEach((nextStep) => {
          traverseNode(nextStep.nextStepId); // Traverse each nextStepId
        });
      } else if (node.activity_next) {
        // Handle regular nodes with a single next step
        traverseNode(node.activity_next);
      }
    }

    // Start traversal from node with id 'node-1'
    const startNode = activities.find((node) => node.activity_id === "node-1");
    if (startNode) {
      traverseNode(startNode.activity_id);
    }

    // Include any unvisited nodes (to ensure every node is included)
    activities.forEach((node) => {
      if (!visited.has(node.activity_id)) {
        traverseNode(node.activity_id);
      }
    });

    return sequencedNodes;
  }

  // method for remove form when node is deleted
  const handleNodeFormRemove = async (formid) => {
    try {
      let formNodeCount = 0;
      nodes.forEach((node) => {
        if (node.data.form && node.data.form.form_builder_id === formid) {
          formNodeCount++;
        }
      });
      if (formNodeCount === 1) {
        const payload = {
          form_builder_id: formid,
          process_id: newProcessId,
        };
        const encryptedPayload = encryptData(payload, PAYLOAD_SECRET);

        const response = await fetch(`${BASE_URL}/form/delete`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: encryptedPayload }),
          // body: JSON.stringify({
          //   form_builder_id: formid,
          //   process_id: newProcessId,
          // }),
        });
        if (response.ok) {
          const data = await response.json();
          console.log("form deleted response: ", data);
          // topRightAlert("success", data.message);
        } else {
          const data = await response.json();
          console.log("error in deleting form: ", data.message);
          // centerAlert("error", data.message);
        }
      }
    } catch (error) {
      console.log("error in form delete: ", error);
      // centerAlert("error", error);
    }
  };

  // handling delete node from the process
  const handleNodeDelete = (e) => {
    e.preventDefault();
    setNodes((prevNodes) =>
      prevNodes.filter((node) => {
        if (node.id === shapeId) {
          if (node.data.form) {
            handleNodeFormRemove(node.data.form.form_builder_id);
          }
          return false;
        }
        return true;
      })
    );
    topRightAlert("success", "Activity deleted...");
    sessionStorage.setItem("shapeId", null);
    setShapeId(null);
  };

  // method for update prev and next step title with new value
  const updateNodeTitle = (nodes, nodeId, newTitle) => {
    // Find the node to update
    const updatedNodes = nodes.map((node) => {
      if (node.id === nodeId) {
        // Update the title of the current node
        node.data.value = newTitle;
      }

      return node;
    });

    // Now update prev_name and next_name in related nodes
    updatedNodes.forEach((node) => {
      // Update prev_name in next nodes
      if (node.data.prev === nodeId) {
        node.data.prev_name = newTitle;
      }

      // Update next_name for normal nodes
      if (node.data.next === nodeId) {
        node.data.next_name = newTitle;
      }

      // Handle decision nodes with multiple next steps
      if (node.data.type === "decision" && Array.isArray(node.data.next_name)) {
        node.data.next_name.forEach((nextStep) => {
          if (nextStep.nextStepId === nodeId) {
            nextStep.nextStep = newTitle; // Update the nextStep name
          }
        });
      }
    });

    return updatedNodes;
  };

  // method for find previous node data
  const handleFindPrevNode = (currentId) => {
    const currentNode = nodes.find((node) => node.id === currentId);

    // If no current node is found or its prev is null, return null
    if (!currentNode || !currentNode.data.prev) {
      return null;
    }

    const prevNode = nodes.find((node) => node.id === currentNode.data.prev);

    // If no previous node is found, return null
    if (!prevNode) {
      return null;
    }

    // If previous node is not of type "decision", "email", or "email_verify", return it
    if (!["decision", "email", "email_verify"].includes(prevNode.type)) {
      return prevNode;
    }

    // Otherwise, recursively check the previous node of the current previous node
    return handleFindPrevNode(prevNode.id);
  };

  // code to check for start and end node
  const isStartAndEndNode = () => {
    const startNode = nodes.filter((node) => node.type === "start");
    const endNode = nodes.filter((node) => node.type === "end");
    if (startNode.length > 0 && endNode.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleArrowCheck = () => {
    if (nodes && nodes.length > 0 && edges.length > 1) {
      for (const node of nodes) {
        const outgoingEdges = edges.filter((edge) => edge.source === node.id);
        const incomingEdges = edges.filter((edge) => edge.target === node.id);

        switch (node.type) {
          case "start":
          case "webform":
            // Start nodes should only have outgoing edges
            if (
              outgoingEdges.length === 0 ||
              !outgoingEdges[0].target ||
              !outgoingEdges[0].sourceHandle
            ) {
              console.log("error with start node outgoing edges::::::::::::::");
              // return false;
              return {
                status: false,
                report: `Please connect outgoing arrow edge on ${node.data.value} `,
              };
            }
            // if (incomingEdges.length > 0) {
            //   console.log("error: start node has incoming edges::::::::::::::");
            //   // return false;
            //   return {
            //     status: false,
            //     report: `Please connect incoming arrow edge on ${node.data.value} `,
            //   };
            // }
            break;

          case "end":
          case "reject":
          case "discard":
          case "exception":
            // End, reject, discard, and exception nodes should only have incoming edges
            if (incomingEdges.length === 0) {
              console.log(
                "error with end/reject/discard/exception node incoming edges::::::::::::::"
              );
              // return false;
              return {
                status: false,
                report: `Please connect incoming arrow edge on ${node.data.value} `,
              };
            }
            if (outgoingEdges.length > 0) {
              console.log(
                "error: end/reject/discard/exception node has outgoing edges::::::::::::::"
              );
              // return false;
              return {
                status: false,
                report: `Please connect outgoing arrow edge on ${node.data.value} `,
              };
            }
            break;

          case "decision":
            // Decision nodes should have both incoming and multiple outgoing edges
            if (incomingEdges.length === 0) {
              console.log(
                "error with decision node incoming edges::::::::::::::"
              );
              // return false;
              return {
                status: false,
                report: `Please connect incoming arrow edge on ${node.data.value} `,
              };
            }
            if (
              outgoingEdges.length === 0 ||
              outgoingEdges.some((edge) => !edge.target || !edge.sourceHandle)
            ) {
              console.log(
                "error with decision node outgoing edges::::::::::::::"
              );
              // return false;
              return {
                status: false,
                report: `Please connect outgoing arrow edge on ${node.data.value} `,
              };
            }
            break;

          default:
            // Other nodes should have both incoming and outgoing edges
            // if (
            //   incomingEdges.length === 0 ||
            //   outgoingEdges.length === 0 ||
            //   outgoingEdges.some((edge) => !edge.target || !edge.sourceHandle)
            // ) {
            //   console.log(
            //     "error with normal activity node edges::::::::::::::"
            //   );
            //   return false;
            // }
            if (incomingEdges.length === 0) {
              console.log(
                "error with normal activity node edges::::::::::::::"
              );
              // return false;
              return {
                status: false,
                report: `Please connect incoming arrow edge on ${node.data.value} `,
              };
            }
            if (
              outgoingEdges.length === 0 ||
              outgoingEdges.some((edge) => !edge.target || !edge.sourceHandle)
            ) {
              console.log(
                "error with normal activity node edges::::::::::::::"
              );
              // return false;
              return {
                status: false,
                report: `Please connect outgoing arrow edge on ${node.data.value} `,
              };
            }
            break;
        }
      }
      // All nodes are correctly connected
      // return true;
      return { status: true };
    }
    // return false;
    return { status: false, report: "Please add activites first" };
  };

  const handlePrevNextCheck = () => {
    if (nodes && nodes.length > 0) {
      for (const node of nodes) {
        const { prev_name, next_name } = node.data;

        switch (node.type) {
          case "start":
          case "webform":
            if (!next_name) {
              console.log("error with start::::::::::::::");
              return {
                status: false,
                report: `Please set next step for ${node.data.value} activity.`,
              };
            }
            break;

          case "end":
          case "reject":
          case "discard":
          case "exception":
            if (!prev_name) {
              console.log(
                "error with end/reject/discard/exception::::::::::::::"
              );
              // return false;
              return {
                status: false,
                report: `Please set prev step for ${node.data.value} activity.`,
              };
            }
            break;

          case "decision":
            if (!prev_name) {
              console.log("error with decision::::::::::");
              // return false;
              return {
                status: false,
                report: `Please set prev step for ${node.data.value} activity.`,
              };
            }
            if (
              !next_name ||
              !Array.isArray(next_name) ||
              next_name.length === 0
            ) {
              console.log("error with decision:::::::::::");
              // return false;
              return {
                status: false,
                report: `Please set conditions for next step on ${node.data.value} activity.`,
              };
            }
            for (const step of next_name) {
              if (!step.nextStep) {
                console.log("error with decision::::::::::");
                // return false;
                return {
                  status: false,
                  report: `Please set next step for ${node.data.value} activity.`,
                };
              }
            }
            // Ensure elseNextStep exists
            // if (!node.data.elseNextStep) {
            //   console.log("error with decision elseNextStep::::::::::");
            //   // return false;
            //   return {
            //     status: false,
            //     report: `Please set exception step for ${node.data.value} activity.`,
            //   };
            // }
            break;

          // case "email_verify":
          //   if (!prev_name) {
          //     console.log("error with email_verify::::::::::");
          //     // return false;
          //     return {
          //       status: false,
          //       report: `Please set prev step for ${node.data.value} activity.`,
          //     };
          //   }
          //   if (
          //     !next_name ||
          //     !next_name.valid_next?.nextStep ||
          //     !next_name.invalid_next?.nextStep
          //   ) {
          //     console.log("error with email_verify:::::::::::");
          //     // return false;
          //     return {
          //       status: false,
          //       report: `Please set next step on ${node.data.value} activity.`,
          //     };
          //   }

          //   break;

          default:
            // Other nodes should have both prev and next values
            console.log("error with other nodes::::::::::::::");
            // if (!prev_name || !next_name) {
            //   console.log("node name::::::::::: ", node.data.value);
            //   console.log("error with normal activity::::::::::");
            //   // return false;
            //   return {
            //     status: false,
            //     report: `Please set next step for ${node.data.value}`,
            //   };
            // }
            if (!prev_name) {
              console.log("node name::::::::::: ", node.data.value);
              console.log("error with normal activity::::::::::");
              // return false;
              return {
                status: false,
                report: `Please set prev step for ${node.data.value} activity.`,
              };
            }
            if (!next_name) {
              console.log("node name::::::::::: ", node.data.value);
              console.log("error with normal activity::::::::::");
              // return false;
              return {
                status: false,
                report: `Please set next step for ${node.data.value} activity.`,
              };
            }
            break;
        }
      }
      // All nodes have correct prev and next values
      // return true;
      return { status: true };
    }
    // return false;
    return { status: false, report: `Please add activities first` };
  };

  const handleAssignedFormCheck = () => {
    if (nodes && nodes.length > 0) {
      for (const node of nodes) {
        const { isFormSelected } = node.data;

        // Types that should not require a form to be assigned
        const excludedTypes = [
          "decision",
          // "end",
          // "discard",
          // "reject",
          // "exception",
          "email",
          "email_verify",
        ];

        // Check for assigned form only if the node type is not in the excluded list
        if (!excludedTypes.includes(node.type)) {
          if (!isFormSelected) {
            console.log("Form not assigned for node:", node.data.value);
            return { status: false, nodeName: node.data.value };
          }
        }
      }
      // All applicable nodes have forms assigned
      return { status: true };
    }
    return { status: false };
  };
  // method for handle email template validation
  const handleEmailTemplateValidation = () => {
    if (nodes && nodes.length > 0) {
      for (const node of nodes) {
        if (node.type === "email") {
          // Check for empty, null, or undefined template
          if (!node.data.template) {
            console.log("Error with email template::::::::::");
            return {
              status: false,
              title: "Template not Added.",
              report: `Please add email template at ${node.data.value} activity.`,
            };
          }
          if (
            node.data.emailTo === "" ||
            node.data.emailTo === null ||
            node.data.emailTo === undefined
          ) {
            console.log("Error with email template::::::::::");
            return {
              status: false,
              title: "Blank template field.",
              report: `Please select 'Email To' field at ${node.data.value} activity.`,
            };
          }
        }
      }
      // If all email nodes have valid templates, return true
      return { status: true };
    }

    // If there are no nodes or the nodes array is empty
    return { status: false, report: "Please add activities." };
  };
  // method for handle reference number validation
  const handleReferenceNumberValidation = () => {
    if (nodes && nodes.length > 0) {
      for (const node of nodes) {
        if (["start", "webform"].includes(node.type)) {
          // Check for reference checkbox
          // if (!node.data.isCustomReferenceNumber) {
          //   console.log("Error with start node::::::::::");
          //   return {
          //     status: false,
          //     title: "Reference Number not Added.",
          //     report: `Please assign reference number at ${node.data.value} activity.`,
          //   };
          // }
          if (
            node.data.isCustomReferenceNumber &&
            (node.data.customReferenceNumber === "" ||
              node.data.customReferenceNumber === null ||
              node.data.customReferenceNumber === undefined)
          ) {
            console.log("Error with email template::::::::::");
            return {
              status: false,
              title: "Reference Number not Added.",
              report: `Please assign reference number at ${node.data.value} activity.`,
            };
          }
        }
      }
      // If reference number added, return true
      return { status: true };
    }

    // If there are no nodes or the nodes array is empty
    return { status: false, report: "Please add activities." };
  };
  // method for handle email verify validation
  const handleEmailVerifyValidation = () => {
    if (nodes && nodes.length > 0) {
      for (const node of nodes) {
        if (node.type === "email_verify") {
          if (
            Array.isArray(node.data.email_verify_fields) &&
            node.data.email_verify_fields.length < 1
          ) {
            console.log("Error with email verify field::::::::::");
            return {
              status: false,
              title: "Email Field not Added.",
              report: `Please add email field at ${node.data.value} activity's default section.`,
            };
          }
        }
      }
      // If all email nodes have valid templates, return true
      return { status: true };
    }

    // If there are no nodes or the nodes array is empty
    return { status: false, report: "Please add activities." };
  };

  const updateNodeLinks = (nodes) => {
    nodes.forEach((node, index) => {
      node.data.prev = index === 0 ? null : nodes[index - 1].id;
      node.data.current = node.id;
      node.data.next = index === nodes.length - 1 ? null : nodes[index + 1].id;
    });
    return nodes;
  };

  const setNodesWithLinks = (updatedNodes) => {
    setNodes(updateNodeLinks(updatedNodes));
  };

  const addNode = (newNode) => {
    setNodesWithLinks([...nodes, newNode]);
  };

  const [showNodesJson, setShowNodesJson] = useState(false);
  const [showEdgesJson, setShowEdgesJson] = useState(false);

  const handleCloseJson = () => {
    setShowNodesJson(false);
    setShowEdgesJson(false);
  };

  return (
    <NodeContext.Provider
      value={{
        initialNodes,
        nodes,
        setNodes,
        edges,
        setEdges,
        addEdge,
        removeEdge,
        nodesJsonData,
        edgesJsonData,
        showNodesJson,
        setShowNodesJson,
        showEdgesJson,
        setShowEdgesJson,
        handleCloseJson,
        showShapeUpdateForm,
        setShowShapeUpdateForm,
        shapeId,
        setShapeId,
        nodeAction,
        setNodeAction,
        addNode,
        handleNodeDelete,
        isStartAndEndNode,
        handleArrowCheck,
        handlePrevNextCheck,
        handleAssignedFormCheck,
        handleEmailTemplateValidation,
        updateNodeTitle,
        handleFindPrevNode,
        sequenceNodes,
        handleEmailVerifyValidation,
        handleReferenceNumberValidation,
      }}
    >
      {children}
    </NodeContext.Provider>
  );
};

export const useNodeContext = () => {
  return useContext(NodeContext);
};
