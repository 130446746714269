import React, { useEffect, useState } from "react";
import Select from "react-select";

const CustomDropdown = ({
  options,
  onChange,
  value,
  defaultValue,
  onBlur,
  name,
  height,
  width,
  minHeight,
  border,
  placeholder,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  // Function to format options if they are strings or objects
  const formatOptions = (options) => {
    if (Array.isArray(options) && options.length > 0) {
      if (typeof options[0] === "string") {
        return options.map((option) => ({ value: option, label: option }));
      }
      return options.map((option) => ({
        value: option.value || option.label,
        label: option.label || option.value,
      }));
    }
    return [];
  };

  const handleSelectChange = (selectedOption) => {
    const event = {
      target: {
        name: name || "",
        value: selectedOption ? selectedOption.value : "",
      },
    };
    onChange(event);
    setSelectedValue(selectedOption);
  };
  // Set the default value on component mount
  useEffect(() => {
    if (defaultValue) {
      const formattedOptions = formatOptions(options);
      const defaultOption = formattedOptions.find(
        (opt) => opt.value === defaultValue
      );
      setSelectedValue(defaultOption || null);
    } else {
      setSelectedValue(null); // Reset if defaultValue is null
    }
  }, [defaultValue, options]);

  // Custom styles for react-select
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: width || "100%",
      height: height || "30px",
    }),
    control: (provided) => ({
      ...provided,
      width: "100%",
      minHeight: minHeight || "25px", // Set height to 30px
      outline: "none",
      color: "#808080",
      border: border || "1px solid #ddd",
      padding: "0px 5px",
      borderRadius: "5px",
      fontSize: "14px",
      backgroundColor: "white",
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      borderRadius: "5px",
      zIndex: 9999,
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: height || "30px",
      padding: "0 6px",
      color: "#808080",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
      color: "#808080",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: height || "30px",
      color: "#808080",
    }),
    option: (provided, state) => ({
      ...provided,
      maxWidth: "80%",
      height: "30px",
      padding: "4px 4px 4px 10px",
      fontSize: "14px",
      border: "1px solid #ddd",
      backgroundColor: state.isFocused ? "#007485" : "white",
      color: state.isFocused ? "white" : "black",
      cursor: "pointer",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#585858",
    }),
  };

  return (
    <Select
      name={name || ""}
      styles={customStyles}
      options={formatOptions(options)}
      onChange={handleSelectChange}
      onBlur={onBlur || ""}
      unstyled
      value={selectedValue}
      menuScrollIntoView={false}
      isSearchable={true}
      placeholder={placeholder || "Select value"}
    />
  );
};

export default CustomDropdown;
