import React, { useContext, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../config";
import { useAdminContext } from "./AdminContext";
import { BallTriangle } from "react-loader-spinner";
import {
  PacmanLoader,
  MoonLoader,
  PropagateLoader,
  HashLoader,
  GridLoader,
  PuffLoader,
} from "react-spinners";
import SuccessAnimation from "../template/success-animation/SuccessAnimation";

const ProcessContext = React.createContext();

const ProcessStore = ({ children }) => {
  const { setAdminAuthenticated, setAllUsers, setAdminData } =
    useAdminContext();
  // Code for Oflow project.............
  const [logoutClicked, setLogoutClicked] = useState(false);
  // const [processes, setProcesses] = useState(() => {
  //   const savedProcesses = sessionStorage.getItem("processes");
  //   return savedProcesses ? JSON.parse(savedProcesses) : [];
  // });
  const [processes, setProcesses] = useState([]);
  const [customModels, setCustomModels] = useState([]);
  const [customModelRecords, setCustomModelRecords] = useState([]);
  const [processDetail, setProcessDetail] = useState(() => {
    const savedProcessDetail = sessionStorage.getItem("processDetail");
    return savedProcessDetail ? JSON.parse(savedProcessDetail) : null;
  });
  const [groups, setGroups] = useState(() => {
    const savedGroups = sessionStorage.getItem("groups");
    return savedGroups ? JSON.parse(savedGroups) : [];
  });
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [configurations, setConfigurations] = useState(null);
  // const [odooGroups, setOdooGroups] = useState(() => {
  //   const savedGroups = sessionStorage.getItem("odooGroups");
  //   return savedGroups ? JSON.parse(savedGroups) : [];
  // });
  const [newProcessId, setNewProcessId] = useState(() => {
    const savedNewProcessId = sessionStorage.getItem("newProcessId");
    return savedNewProcessId ? savedNewProcessId : null;
  });

  const [emailTemplateId, setEmailTemplateId] = useState(() => {
    const savedEmailTemplateId = sessionStorage.getItem("emailTemplateId");
    return savedEmailTemplateId ? savedEmailTemplateId : null;
  });
  const [isUpdateEmailTemplate, setUpdateEmailTemplate] = useState(false);

  const [authenticated, setAuthenticated] = useState(() => {
    const authState = localStorage.getItem("authenticated");
    return authState ? JSON.parse(authState) : false;
  });

  const [userData, setUserData] = useState(() => {
    const userData = localStorage.getItem("userData");
    return userData ? JSON.parse(userData) : null;
  });
  const [docTypes, setDocTypes] = useState(null);
  const [toDos, setTodos] = useState(null);
  const [isEmailTemplateModalOpen, setEmailTemplateModalOpen] = useState(false);
  const [checkinLoading, setCheckInLoading] = useState(false);
  const [checkinStep, setCheckinStep] = useState("");
  const [checkinLoadingTitle, setCheckinLoadingTitle] = useState("");

  useEffect(() => {
    localStorage.setItem("authenticated", JSON.stringify(authenticated));
  }, [authenticated]);

  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(userData));
  }, [userData]);

  // useEffect(() => {
  //   sessionStorage.setItem("processes", JSON.stringify(processes));
  // }, [processes]);

  useEffect(() => {
    // sessionStorage.setItem("processDetail", JSON.stringify(processDetail));
    try {
      sessionStorage.setItem("processDetail", JSON.stringify(processDetail));
    } catch (e) {
      if (e.code === DOMException.QUOTA_EXCEEDED_ERR) {
        console.error("SessionStorage quota exceeded!");
      }
    }
  }, [processDetail]);

  useEffect(() => {
    sessionStorage.setItem("groups", JSON.stringify(groups));
  }, [groups]);

  // useEffect(() => {
  //   sessionStorage.setItem("odooGroups", JSON.stringify(odooGroups));
  // }, [odooGroups]);

  useEffect(() => {
    sessionStorage.setItem("newProcessId", newProcessId);
  }, [newProcessId]);

  useEffect(() => {
    sessionStorage.setItem("emailTemplateId", emailTemplateId);
  }, [emailTemplateId]);

  // method for logout
  const logout = () => {
    setAuthenticated(false);
    setUserData(null);
    setAllUsers([]);
    setProcesses([]);
    setProcessDetail([]);
    setGroups([]);
    setNewProcessId(null);
    setUserData(null);
    localStorage.removeItem("authenticated");
    sessionStorage.removeItem("adminAuthenticated");
    localStorage.removeItem("userData");
    sessionStorage.removeItem("adminData");
    sessionStorage.removeItem("processDetail");
    sessionStorage.removeItem("groups");
    sessionStorage.removeItem("odooGroups");
    sessionStorage.removeItem("newProcessId");
    sessionStorage.removeItem("nodes");
    sessionStorage.removeItem("edges");
    sessionStorage.removeItem("forms");
    sessionStorage.removeItem("formData");
    sessionStorage.removeItem("newFormId");
    sessionStorage.removeItem("shapeId");
    sessionStorage.removeItem("kanbanDetail");
    sessionStorage.removeItem("isKanbanBuilder");
    sessionStorage.removeItem("emailTemplateId");
    sessionStorage.clear();
    localStorage.clear();
    setAuthenticated(false);
    setAdminAuthenticated(false);
  };

  // method for getting one process data
  const processdata = processes.filter(
    (process) => process.process_id === newProcessId
  );

  const centerAlert = (icon, text) => {
    Swal.fire({
      icon,
      text,
    });
  };
  const centerAlertWithTitle = (title, text, icon) => {
    Swal.fire({
      title,
      text,
      icon,
    });
  };

  const topRightAlert = (icon, text) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: icon,
      title: text,
    });
  };

  // method for fetching all active databases
  const fetchAllActiveDatabase = async () => {
    try {
      const payload = {
        userid: userData.userid,
        configtype: "baseUrl",
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/config/fetch/active`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });
      const data = await response.json();
      // console.log("Fetched data:", data);

      if (response.ok) {
        return data.config;
      } else {
        return data;
      }
    } catch (error) {
      console.error("Error fetching processes:", error);
    }
  };

  // method for custom selection field
  const convertToSelectOptions = (arr) => {
    return arr.map((item) => ({
      label: item,
      value: item.toLowerCase().replace(/\s+/g, "_"),
    }));
  };

  // method for fetching all groups
  const fetchAllGroups = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/group/groups/${userData.userid}`
      );
      const data = await response.json();
      // console.log("Fetched data:", data);

      if (Array.isArray(data.groups)) {
        setGroups(data.groups);
      } else {
        setGroups([]);
        throw new Error("No groups are found on user id: ", userData.userid);
      }
    } catch (error) {
      setGroups([]);
      console.error("Error fetching processes:", error);
    } finally {
      // setLoading(false);
    }
  };
  const fetchAllEmailTemplates = async () => {
    try {
      const payload = {
        user_id: userData.userid,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/email/fetch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });
      const data = await response.json();
      // console.log("Fetched data:", data);

      if (Array.isArray(data.emails)) {
        setEmailTemplates(data.emails);
      } else {
        setEmailTemplates([]);
        throw new Error("No groups are found on user id: ", userData.userid);
      }
    } catch (error) {
      setEmailTemplates([]);
      console.error("Error fetching processes:", error);
    }
  };

  // fetch all process data
  const fetchAllProcessData = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/process/processes/${userData.userid}`
      );
      const data = await response.json();
      // console.log("Fetched data:", data);

      if (Array.isArray(data.processes)) {
        setProcesses(data.processes);
      } else {
        setProcesses([]);
        console.log("error in fetching process data: ", data);
      }
    } catch (error) {
      console.error("Error fetching processes:", error);
      // setError(error);
    }
  };

  // method for fetch configuration value using key
  const fetchConfigurationValue = async (key) => {
    try {
      const payload = {
        key: key,
        apikey: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/configuration/fetch/one`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });
      const data = await response.json();
      if (response.ok) {
        // console.log("response::: fetch key value data: ", data);
        return { isError: false, value: data?.configuration?.value };
      } else {
        console.log("response::: fetch key and value data: ", data);
        return { isError: true, ...data };
      }
    } catch (error) {
      console.log("error: ", error);
      return { isError: true, message: error };
    }
  };

  // Function to encrypt JSON data
  const encryptData = (data, key) => {
    const jsonString = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(jsonString, key).toString();
    return encryptedData;
  };

  // Function to decrypt the encrypted JSON data
  const decryptData = (encryptedData, key) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, key);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  };

  // method for process lock release for deploy
  const handleProcessDeployLockRelease = async () => {
    // for server restart code
    try {
      const payload = {
        process_id: newProcessId,
        key: API_KEY,
      };
      // const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/process/release`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        // body: JSON.stringify({ data: encryptedData }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log("response::: restart server data: ", data);
        return { status: true, data };
      } else {
        console.log("response::: restart server data: ", data);
        return { status: false, data };
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  // method for restart server
  const restartServer = async (databaseUrl) => {
    // for server restart code
    try {
      const payload = {
        // process_id: newProcessId,
        database_url: databaseUrl,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/process/restart`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log("response::: restart server data: ", data);
        return { status: true, data };
      } else {
        console.log("response::: restart server data: ", data);
        return { status: false, data };
      }
    } catch (error) {
      console.log("error: ", error);
    }
    // ===============================================================
  };

  // method for server start status
  const restartServerStatusCheck = async (databaseUrl) => {
    try {
      const payload = {
        database_url: databaseUrl,
        // process_id: newProcessId,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/process/status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: encryptedData,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log("response::: Third api response: ", data);
        return { status: true, data };
      } else {
        console.log("response data: ", data);
        return { status: false, data };
      }
    } catch (err) {
      console.log("error at server running status check::: ", err);
    }
  };

  // method for create model api call
  const createCustomModel = async (modelData, userid) => {
    try {
      const fieldData = [
        [
          {
            title: "Name",
            technicalName: "x_name",
            type: "char",
            widget: "defaultField",
            tooltip: "",
            isRequired: false,
            isReadOnly: false,
            isInvisible: false,
          },
        ],
      ];
      const payload = {
        database: modelData?.database,
        name: modelData?.name,
        model: modelData?.model,
        model_fields: fieldData,
        process_id: modelData?.process_id,
        key: API_KEY,
        user_id: userid,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/custom-model/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: encryptedData,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log("response data create custom model: ", data);
        return data;
      } else {
        console.log("response data create custom model: ", data);
        centerAlert("error", data.message);
      }
    } catch (err) {
      console.log("error at server:: create custom model: ::: ", err);
      centerAlert("error", err);
    }
  };
  // method for create model api call
  const fetchAllCustomModel = async (userid) => {
    try {
      const payload = {
        key: API_KEY,
        user_id: userid,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/custom-model/fetch/all`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: encryptedData,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log("response data fetch custom model: ", data);
        setCustomModels(data.models);
        return data.models;
      } else {
        console.log("response data fetch custom model: ", data);
      }
    } catch (err) {
      console.log("error at server:: fetch custom model: ::: ", err);
    }
  };

  // method for create model api call
  const fetchAllRecords = async (modelData) => {
    try {
      // const payload = {
      //   key: API_KEY,
      //   user_id: userid,
      // };
      const encryptedData = encryptData(modelData, PAYLOAD_SECRET);
      const response = await fetch(
        `${BASE_URL}/custom-model/post/data/model/field/fetch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: encryptedData,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        console.log("response data fetch custom model records: ", data);
        setCustomModelRecords(data.records);
        return data.records;
      } else {
        console.log("response data fetch custom model records: ", data);
      }
    } catch (err) {
      console.log("error at server:: fetch custom model records: ::: ", err);
    }
  };

  // method for rendering loading screen at deploy click
  const handleLoaderScreen = () => {
    if (checkinLoading) {
      switch (checkinStep) {
        case "save":
          return (
            <div className="progress_bar_checkin">
              {/* Loading */}
              <h3>Saving Process Data...</h3>
              <PacmanLoader
                height={100}
                width={100}
                radius={5}
                color="#1a7e8f"
                // ariaLabel="ball-triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          );
        case "write":
          return (
            <div className="progress_bar_checkin">
              <h3>Updating Process Data...</h3>
              <GridLoader
                height={100}
                width={100}
                radius={5}
                color="#1a7e8f"
                // ariaLabel="ball-triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          );
        case "restart":
          return (
            <div className="progress_bar_checkin">
              <h3>{checkinLoadingTitle || "Restarting Server..."}</h3>
              <MoonLoader
                height={100}
                width={100}
                radius={5}
                color="#1a7e8f"
                // ariaLabel="ball-triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          );
        case "upgrade":
          return (
            <div className="progress_bar_checkin">
              <h3>Upgrading Process Module...</h3>
              <HashLoader
                height={100}
                width={100}
                radius={5}
                color="#1a7e8f"
                // ariaLabel="ball-triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          );
        case "process_status":
          return (
            <div className="progress_bar_checkin">
              <h3>{checkinLoadingTitle || "Updating Process Status..."}</h3>
              <PropagateLoader
                height={100}
                width={100}
                radius={5}
                color="#1a7e8f"
                // ariaLabel="ball-triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          );
        case "delay":
          return (
            <div className="progress_bar_checkin">
              <h3>{checkinLoadingTitle || "Waiting for Server Restart..."}</h3>
              <PuffLoader
                height={100}
                width={100}
                radius={5}
                color="#1a7e8f"
                // ariaLabel="ball-triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          );
        case "success":
          return (
            <div className="progress_bar_checkin">
              <h3>{checkinLoadingTitle || "Success"}</h3>
              <SuccessAnimation color="#1a7e8f" />
            </div>
          );
        default:
          return (
            <div className="progress_bar_checkin">
              <h3>Processing...</h3>
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#1a7e8f"
                // ariaLabel="ball-triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          );
      }
    }
  };

  // method for check valid token
  const checkLoginStatus = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const response = await fetch(`${BASE_URL}/user/validate-token`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      if (response.ok) {
        setAuthenticated(true);
        setUserData(data.user);
        setAdminAuthenticated(false);
        setAdminData(null);
      } else {
        localStorage.removeItem("token");
        setAuthenticated(false);
        setUserData(null);
        setAdminAuthenticated(false);
        setAdminData(null);
        window.location.href = "/";
      }
    }
  };

  // const convertDateTimeToTimeZone = (date, fromTimeZone, toTimeZone) => {
  //   const originalDate = new Date(
  //     date.toLocaleString("en-US", { timeZone: fromTimeZone })
  //   );
  //   return originalDate.toLocaleString("en-US", { timeZone: toTimeZone });
  // };
  const convertDateTimeToTimeZone = (date, fromTimeZone, toTimeZone) => {
    // Convert the input date to the fromTimeZone
    const fromDate = new Date(
      date.toLocaleString("en-US", { timeZone: fromTimeZone })
    );

    // Convert to the target timezone
    const toDate = new Date(
      fromDate.toLocaleString("en-US", { timeZone: toTimeZone })
    );

    // Format the date to 'yyyy-MM-ddTHH:mm'
    const year = toDate.getFullYear();
    const month = String(toDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(toDate.getDate()).padStart(2, "0");
    const hours = String(toDate.getHours()).padStart(2, "0");
    const minutes = String(toDate.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  return (
    <ProcessContext.Provider
      value={{
        userData,
        setUserData,
        authenticated,
        setAuthenticated,
        logoutClicked,
        setLogoutClicked,
        logout,
        processes,
        setProcesses,
        processDetail,
        setProcessDetail,
        groups,
        setGroups,
        emailTemplates,
        setEmailTemplates,
        newProcessId,
        setNewProcessId,
        docTypes,
        setDocTypes,
        toDos,
        setTodos,
        processdata,
        centerAlert,
        centerAlertWithTitle,
        topRightAlert,
        fetchAllActiveDatabase,
        convertToSelectOptions,
        fetchAllGroups,
        fetchAllProcessData,
        configurations,
        setConfigurations,
        encryptData,
        decryptData,
        emailTemplateId,
        setEmailTemplateId,
        isEmailTemplateModalOpen,
        setEmailTemplateModalOpen,
        isUpdateEmailTemplate,
        setUpdateEmailTemplate,
        fetchAllEmailTemplates,
        fetchConfigurationValue,
        restartServer,
        restartServerStatusCheck,
        checkinLoading,
        setCheckInLoading,
        checkinStep,
        setCheckinStep,
        checkinLoadingTitle,
        setCheckinLoadingTitle,
        handleLoaderScreen,
        handleProcessDeployLockRelease,
        createCustomModel,
        fetchAllCustomModel,
        customModels,
        setCustomModels,
        checkLoginStatus,
        convertDateTimeToTimeZone,
        fetchAllRecords,
        customModelRecords,
        setCustomModelRecords,
      }}
    >
      {children}
    </ProcessContext.Provider>
  );
};

export default ProcessStore;

export const useProcessContext = () => useContext(ProcessContext);
