import React, { useEffect, useState } from "react";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../config";
import { useProcessContext } from "../../context/ProcessContext";
import { useNodeContext } from "../../context/NodeContext";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin5Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { useAdminContext } from "../../context/AdminContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Users = () => {
  const { topRightAlert, centerAlert, encryptData } = useProcessContext();
  const { adminData, allUsers, setAllUsers, fetchAllUserData } =
    useAdminContext();

  const [loading, setLoading] = useState(true);
  const [visibleRow, setVisibleRow] = useState(null);
  // const navigate = useNavigate();

  const getUsersData = async () => {
    setLoading(true);
    await fetchAllUserData();
    setLoading(false);
  };
  useEffect(() => {
    getUsersData();
  }, [setAllUsers, adminData.userid]);

  const handleDelete = async (userDetail) => {
    try {
      const encryptedData = encryptData(userDetail, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/user/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });

      if (response.ok) {
        const data = await response.json();
        topRightAlert("success", data.message);

        getUsersData();
      } else {
        const data = await response.json();
        topRightAlert("error", data.message);
        console.error("Error deleting process:", data);
      }
    } catch (error) {
      topRightAlert("error", error);
      console.error("Error deleting process:", error);
    }
  };
  // const handleEdit = async (processId) => {
  //   if (processId !== null) {
  //     try {
  //       const response = await fetch(`${BASE_URL}/process/fetch`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           process_id: processId,
  //           user_id: userData.userid,
  //         }),
  //       });

  //       if (response.ok) {
  //         const data = await response.json();
  //         // console.log("Data ProcessDetail:::::::: ", data);
  //         // fetchAllProcessData();
  //         const nodesData = data.processes.process_data_flow.nodesData;
  //         const edgesData = data.processes.process_data_flow.edgesData;
  //         console.log("flowData::::: ", nodesData);
  //         setProcessDetail(data.processes);
  //         setNodes(
  //           data.processes.process_data_flow.nodesData
  //             ? JSON.parse(nodesData)
  //             : initialNodes
  //         );
  //         setEdges(
  //           data.processes?.process_data_flow.edgesData
  //             ? JSON.parse(edgesData)
  //             : []
  //         );
  //         setNewProcessId(processId);
  //         setShapeId(null);
  //         navigate("/process-modeler");
  //       } else {
  //         const data = await response.json();
  //         console.error("Error updating process status:", data);
  //       }
  //     } catch (error) {
  //       console.error("Error updating process status:", error);
  //     }
  //   }
  // };

  if (loading) return <span>Loading....</span>;
  return (
    <div className="donor_dash_right">
      {/* <div className="dashboard_buttons">
        <button>
          <button data-bs-toggle="modal" data-bs-target="#exampleModal">
          Create User
        </button>
        <button data-bs-toggle="modal" data-bs-target="#processImportModal">
          Import Process
        </button>
      </div> */}
      {/* Modal */}
      {/* over modal code */}
      <div className="donor_dash_deatil">
        <div className="donor_kit">
          <h2 className="table_caption_head">List of existing users</h2>
          <div className="donor_kit_desc" />
          <div className="donor_table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  {/* <th>Process Id</th> */}
                  <th>Username</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Company</th>
                  <th>Password</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allUsers.length > 0 ? (
                  allUsers.map((user, index) => (
                    <tr key={user.userid}>
                      <th>{index + 1}</th>
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>{user.company}</td>
                      {/* <td>{user.password}</td> */}
                      <td>
                        {visibleRow === user._id ? `${user.password}` : "*****"}
                      </td>
                      {/* <td>
                        <div
                          className="form-check form-switch"
                          style={{ paddingLeft: "3.5rem" }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheckDefault-${process.process_id}`}
                            checked={process.odoo_checking_status}
                            onChange={(e) =>
                              handleStatusChange(
                                process.process_id,
                                e.target.checked
                              )
                            }
                          />
                        </div>
                      </td> */}
                      <td className="td_action_cell">
                        {visibleRow === user._id ? (
                          <FaEye
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              marginRight: "3px",
                            }}
                            onClick={() => setVisibleRow(null)}
                          />
                        ) : (
                          <FaEyeSlash
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              marginRight: "3px",
                            }}
                            onClick={() => setVisibleRow(user._id)}
                          />
                        )}
                        {/* <CiEdit
                          className="dash_table_icon_edit"
                          //   onClick={() => handleEdit(process.process_id)}
                        /> */}
                        <RiDeleteBin5Line
                          className="dash_table_icon_del"
                          onClick={() =>
                            handleDelete({
                              id: user._id,
                              user_id: user.userid,
                              key: API_KEY,
                            })
                          }
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr key={adminData.userid}>
                    <td colSpan={7} className="text-center ">
                      No existing users...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
