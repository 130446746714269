import React, { useEffect, useState } from "react";
import "./DoctypeCreateOverlay.css";
import { IoMdClose } from "react-icons/io";
import { useNodeContext } from "../../context/NodeContext";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import { useProcessContext } from "../../context/ProcessContext";

const DoctypeCreateOverlay = ({
  shapeData,
  editingDoctypeIndex,
  setEditingDoctypeIndex,
  editDoctype,
}) => {
  const { setNodes, shapeId } = useNodeContext();
  const { topRightAlert, centerAlert } = useProcessContext();
  const [doctype, setDoctype] = useState({
    docType_type: "",
    docType_description: "",
    isMandatory: false,
  });

  useEffect(() => {
    if (editDoctype) {
      setDoctype(editDoctype);
    }
  }, [editDoctype]);

  const resetDoctypeForm = () => {
    setDoctype({
      docType_type: "",
      docType_description: "",
      isMandatory: false,
    });
    setEditingDoctypeIndex(null);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setDoctype((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleAddMore = (e) => {
    e.preventDefault();
    if (!doctype.docType_type) {
      centerAlert("error", "Document Type field is mandatory to set.");
      return;
    }

    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? {
              ...node,
              data: {
                ...node.data,
                doctype: Array.isArray(shapeData.doctype)
                  ? [...shapeData.doctype, doctype]
                  : [doctype],
              },
            }
          : node
      )
    );
    topRightAlert("success", "Doctype created successfully.");

    // Reset form fields
    resetDoctypeForm();
  };

  // const handleAddAndClose = (e) => {
  //   e.preventDefault();
  //   setNodes((prevNodes) =>
  //     prevNodes.map((node) =>
  //       node.id === shapeId
  //         ? {
  //             ...node,
  //             data: {
  //               ...node.data,
  //               doctype: Array.isArray(shapeData.doctype)
  //                 ? [...shapeData.doctype, doctype]
  //                 : [doctype],
  //             },
  //           }
  //         : node
  //     )
  //   );
  //   topRightAlert("success", "Doctype created successfully.");
  //   // Close form and reset fields
  //   resetDoctypeForm();
  //   const modalElement = document.getElementById("doctypeCreateModal");
  //   const modalInstance = bootstrapBundleMin.Modal.getInstance(modalElement);
  //   modalInstance.hide();
  // };
  const handleAddAndClose = (e) => {
    e.preventDefault();
    if (!doctype.docType_type) {
      centerAlert("error", "Document Type field is mandatory to set.");
      return;
    }

    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? {
              ...node,
              data: {
                ...node.data,
                doctype:
                  editingDoctypeIndex !== null
                    ? shapeData.doctype.map((item, idx) =>
                        idx === editingDoctypeIndex ? doctype : item
                      )
                    : [...(shapeData.doctype || []), doctype],
              },
            }
          : node
      )
    );

    topRightAlert(
      "success",
      `Doctype ${
        editingDoctypeIndex !== null ? "updated" : "created"
      } successfully.`
    );

    resetDoctypeForm();
    closeModal();
  };
  const closeModal = () => {
    const modalElement = document.getElementById("doctypeCreateModal");
    const modalInstance = bootstrapBundleMin.Modal.getInstance(modalElement);
    modalInstance.hide();
  };

  const handleCancel = () => {
    // Close form without submitting and reset fields
    resetDoctypeForm();
    // setShowDocTypeForm(false);
  };
  return (
    <div
      className="modal fade"
      id="doctypeCreateModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="doctypeCreateModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content form_modal_content">
          <div className="modal-header form_modal_header">
            <h1 className="modal-title fs-5" id="formModalLabel">
              {editingDoctypeIndex !== null ? "Edit DocType" : "Create DocType"}
            </h1>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                position: "absolute",
                right: "20px",
                fontSize: "25px",
                color: "white",
              }}
              onClick={handleCancel}
            >
              <IoMdClose />
            </button>
          </div>
          <form
            method="post"
            onSubmit={handleAddAndClose}
            className="form_create_modal"
          >
            <div className="modal-body form_modal_body">
              <div className="formbuilderModalForm">
                <div className="formbuilderModalForm_inputField">
                  <label htmlFor="doctype_type">Document Type*:</label>
                  <input
                    id="doctype_type"
                    type="text"
                    name="docType_type"
                    placeholder="Enter doctype"
                    value={doctype.docType_type}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    required
                  />
                </div>
                <div className="formbuilderModalForm_inputField">
                  <label htmlFor="doctype_description">Description:</label>
                  <textarea
                    name="docType_description"
                    id="doctype_description"
                    rows={5}
                    value={doctype.docType_description}
                    placeholder="Enter doctype detail"
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div
                  className="d-flex justify-content-start align-items-center gap-4"
                  style={{ color: "#383838", width: "100%" }}
                >
                  <label className="checkbox_container">
                    <input
                      id="fieldIsMandatory"
                      type="checkbox"
                      name="isMandatory"
                      checked={doctype.isMandatory}
                      onChange={handleChange}
                    />
                    <span className="checkmark" />
                  </label>
                  <label htmlFor="fieldIsMandatory" className="label_checkbox">
                    Is Mandatory
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-footer form_modal_footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleCancel}
              >
                Close
              </button>
              {editingDoctypeIndex === null && (
                <button
                  type="submit"
                  className="btn modal_save_button"
                  onClick={handleAddMore}
                >
                  Save & Next
                </button>
              )}
              <button
                type="submit"
                className="btn modal_save_button"
                onClick={handleAddAndClose}
              >
                {editingDoctypeIndex !== null ? "Update" : "Save & Close"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DoctypeCreateOverlay;
