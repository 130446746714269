import React, { useEffect, useState } from "react";
// import { CiEdit } from "react-icons/ci";
// import { RiDeleteBin5Line } from "react-icons/ri";
// import { Link, useNavigate } from "react-router-dom";
import { useProcessContext } from "../../context/ProcessContext";
import { useAdminContext } from "../../context/AdminContext";
// import BASE_URL, { API_KEY, PAYLOAD_SECRET } from '../../../config';

const Databases = () => {
//   const { topRightAlert, centerAlert, encryptData } = useProcessContext();
  const { adminData, fetchAllDatabases, databases, setDatabases } =
    useAdminContext();

  const [loading, setLoading] = useState(false);
  //   const [visibleRow, setVisibleRow] = useState(null);
//   const navigate = useNavigate();

  useEffect(() => {
    fetchAllDatabases();
    setLoading(false);
  }, [setDatabases, adminData.userid]);

  //   const handleDelete = async (subscriptionId) => {
  //     try {
  //       const requestBody = {
  //         subscription_id: subscriptionId,
  //         key: API_KEY,
  //       };
  //       const encryptedData = encryptData(requestBody, PAYLOAD_SECRET);
  //       const response = await fetch(`${BASE_URL}/subscription/delete`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ data: encryptedData }),
  //       });

  //       if (response.ok) {
  //         const data = await response.json();
  //         // console.log("delete process: ", data);
  //         topRightAlert("success", data.message);
  //         // Filter out the deleted process from the state
  //         // setProcesses((prevProcesses) =>
  //         //   prevProcesses.filter((process) => process.process_id !== processId)
  //         // );
  //         await fetchAllSubscriptionData();
  //       } else {
  //         const data = await response.json();
  //         centerAlert("error", data.message);
  //         console.error("Error deleting subscription:", data);
  //       }
  //     } catch (error) {
  //       centerAlert("error", "Internal Server Error");
  //       console.error("Error deleting subscription:", error);
  //     }
  //   };
  //   const handleEditClick = (e, subscriptionId) => {
  //     e.preventDefault();
  //     setsubscriptionId(null);
  //     setsubscriptionId(subscriptionId);
  //     navigate("/admin-dashboard/subscription/subscription-form");
  //   };
  // const handleEdit = async (processId) => {
  //   if (processId !== null) {
  //     try {
  //       const response = await fetch(`${BASE_URL}/process/fetch`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           process_id: processId,
  //           user_id: userData.userid,
  //         }),
  //       });

  //       if (response.ok) {
  //         const data = await response.json();
  //         // console.log("Data ProcessDetail:::::::: ", data);
  //         // fetchAllProcessData();
  //         const nodesData = data.processes.process_data_flow.nodesData;
  //         const edgesData = data.processes.process_data_flow.edgesData;
  //         console.log("flowData::::: ", nodesData);
  //         setProcessDetail(data.processes);
  //         setNodes(
  //           data.processes.process_data_flow.nodesData
  //             ? JSON.parse(nodesData)
  //             : initialNodes
  //         );
  //         setEdges(
  //           data.processes?.process_data_flow.edgesData
  //             ? JSON.parse(edgesData)
  //             : []
  //         );
  //         setNewProcessId(processId);
  //         setShapeId(null);
  //         navigate("/process-modeler");
  //       } else {
  //         const data = await response.json();
  //         console.error("Error updating process status:", data);
  //       }
  //     } catch (error) {
  //       console.error("Error updating process status:", error);
  //     }
  //   }
  // };

  if (loading) return <span>Loading....</span>;
  return (
    <div className="donor_dash_right">
      {/* <div className="dashboard_buttons">
        <Link to={"/admin-dashboard/subscription/subscription-form"}>
          <button onClick={() => setsubscriptionId(null)}>
            New Subscription
          </button>
        </Link>
      </div> */}
      {/* Modal */}
      {/* over modal code */}
      <div className="donor_dash_deatil">
        <div className="donor_kit">
          <h2 className="table_caption_head">List of existing databases</h2>
          <div className="donor_kit_desc" />
          <div className="donor_table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Database Id</th>
                  <th>Database</th>
                  <th>Database Url</th>
                  <th>Status</th>
                  <th>Database Secret Key</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {databases.length > 0 ? (
                  databases.map((database, index) => (
                    <tr key={database._id}>
                      <th>{index + 1}</th>
                      <td>{database._id}</td>
                      <td>{database.database}</td>
                      <td>{database.parameter}</td>
                      <td>{database.isactive ? "True" : "False"}</td>
                      <td>{database.securitykey}</td>
                      {/* <td className="td_action_cell">
                        <CiEdit
                          className="dash_table_icon_edit"
                          onClick={(e) => handleEditClick(e, subscription._id)}
                        />
                        <RiDeleteBin5Line
                          className="dash_table_icon_del"
                          onClick={() => handleDelete(subscription._id)}
                        />
                      </td> */}
                    </tr>
                  ))
                ) : (
                  <tr key={adminData.userid}>
                    <td colSpan={5} className="text-center ">
                      No existing databases...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Databases;
