import React, { useState } from "react";
import { useProcessContext } from "../../context/ProcessContext";
import { useEffect } from "react";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import { IoMdClose } from "react-icons/io";
import CustomDropdown from "../custom_dropdown/CustomDropdown";
import { useFormContext } from "../../context/FormContext";
import BASE_URL, { API_KEY } from "../../../config";

const styles = `
  #flex-start {
    display: flex !important;
    justify-content: flex-start !important;
  }
`;

const CreateRecord = () => {
  const { centerAlert, centerAlertWithTitle, fetchAllRecords } =
    useProcessContext();
  const { customModelDetail, parseFormDataWithoutTableO2m } = useFormContext();
  //   const navigate = useNavigate();
  const [allModelFields, setAllModelFields] = useState(null);
  const [recordData, setRecordData] = useState(null);
  // const [allModelFields, setAllModelFields] = useState({title: "Name", technicalName: "x_name", type:"char", widget: "char"});
  // const [recordData, setRecordData] = useState({x_name: ''});

  const handleDataChange = (e) => {
    const { name, value, checked, type } = e.target;
    setRecordData((prevRecordData) => ({
      ...prevRecordData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  //   useEffect for getting form fields
  useEffect(() => {
    if (
      customModelDetail &&
      Array.isArray(customModelDetail.model_fields) &&
      customModelDetail.model_fields.length > 0
    ) {
      const fields = parseFormDataWithoutTableO2m(
        customModelDetail.model_fields
      )?.filter((field) => field.widget !== "email_verify_status");
      setAllModelFields(fields);
      setRecordData(
        fields?.reduce((acc, field) => {
          acc[field.technicalName] = "";
          return acc;
        }, {})
      );
    }
  }, [customModelDetail]);

  const handleReset = () => setRecordData(null);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      key: API_KEY,
      database: customModelDetail.database_obj,
      database_url: customModelDetail.database_url,
      modelDetail: {
        model: customModelDetail.model,
        model_id: customModelDetail.id,
      },
      fields: { ...recordData },
    };
    try {
      const response = await fetch(`${BASE_URL}/custom-model/post/data/model`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (response.ok) {
        console.log("record submit success:: ", data);
        await fetchAllRecords({
          key: API_KEY,
          database: customModelDetail?.database_obj,
          database_url: customModelDetail?.database_url,
          modelDetail: {
            model: customModelDetail?.model,
            model_id: customModelDetail?.id,
          },
        });
        centerAlert("success", data.message);
        // fetchAllCustomModel(userData.userid);
        handleReset();
        const modalElement = document.getElementById("createRecordModal");
        const modalInstance =
          bootstrapBundleMin.Modal.getInstance(modalElement);
        modalInstance.hide();
      } else {
        centerAlert("error", data.message);
      }
    } catch (error) {
      centerAlertWithTitle("Oops...", error, "error");
    }
  };

  console.log("allModelFields::: ", allModelFields);
  console.log("recordData::: ", recordData);

  return (
    <div
      className="modal fade"
      id="createRecordModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="createRecordModal"
      aria-hidden="true"
    >
      <style dangerouslySetInnerHTML={{ __html: styles }} />
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="createRecordModalLabel">
              Create New Record
            </h1>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                position: "absolute",
                right: "20px",
                fontSize: "25px",
                color: "white",
              }}
              onClick={handleReset}
            >
              <IoMdClose />
            </button>
          </div>
          <form method="post" onSubmit={handleSubmit}>
            <div className="modal-body">
              <div
                id="flex-start"
                className={`processModalForm  d-flex ${
                  allModelFields &&
                  Array.isArray(allModelFields) &&
                  allModelFields.length > 3
                    ? "flex-row flex-wrap"
                    : "flex-column flex-nowrap"
                } align-items-center gap-3`}
              >
                {allModelFields?.map((field) => (
                  <div
                    key={`${field.technicalName}_${field.type}`}
                    className={`processModalForm_inputField ${
                      field.type === "boolean"
                        ? "flex-row justify-content-start align-items-start gap-4"
                        : ""
                    }`}
                    style={{
                      width: allModelFields?.length > 3 ? "45%" : "100%",
                    }}
                  >
                    <label htmlFor={field.technicalName}>{field.title}</label>
                    <input
                      type={
                        field.type === "char" && field.widget === "password"
                          ? "password"
                          : field.type === "char" && field.widget === "email"
                          ? "email"
                          : field.type === "integer" || field.type === "float"
                          ? "number"
                          : field.type === "date"
                          ? "date"
                          : field.type === "datetime"
                          ? "datetime-local"
                          : field.type === "boolean"
                          ? "checkbox"
                          : "text"
                      }
                      id={field.technicalName}
                      placeholder={`Enter ${field.title}`}
                      name={field.technicalName}
                      // value={customModelData.customModelData_name}
                      onChange={handleDataChange}
                      // required
                      style={{
                        width: field.type === "boolean" ? "17px" : "100%",
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleReset}
              >
                Close
              </button>
              <button type="submit" className="btn modal_save_button">
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateRecord;
