import React, { useEffect, useState } from "react";
import { useProcessContext } from "../../../context/ProcessContext";
import { useNodeContext } from "../../../context/NodeContext";
import { useFormContext } from "../../../context/FormContext";
import CustomDropdown from "../../../template/custom_dropdown/CustomDropdown";
import { MdOutlineClose } from "react-icons/md";
import { GiCancel } from "react-icons/gi";

const ListDetailUpdate = ({
  widgets,
  odooButtonColors,
  tableFieldTypes,
  odooIcons,
  selectedListField,
  setSelectedListField,
}) => {
  const { showFieldDetail, setShowFieldDetail, listDetail, setListDetail } =
    useFormContext();
  const { groups, fetchAllGroups, processDetail } = useProcessContext();
  const { shapeId, setNodes } = useNodeContext();
  const [updateFieldData, setUpdateFieldData] = useState({
    title: "",
    technicalName: "x_o2b_",
    type: "",
    widget: "",
    options: null,
    odooButtonColor: "",
    placeholder: "",
    tooltip: "",
    relatedModel: "",
    groupVisible: [],
    optional: "",
    isRequired: false,
    isReadOnly: false,
    isInvisible: false,
  });

  // Array data for all input types
  const inputTypes =
    updateFieldData.type === "button" ||
    updateFieldData.type === "object" ||
    updateFieldData.type === "action"
      ? ["object", "action"]
      : [
          "text",
          "char",
          "date",
          "datetime",
          "binary",
          "boolean",
          "float",
          "html",
          "integer",
          "json",
          "many2many",
          "many2one",
          "many2one_reference",
          "monetary",
          "one2many",
          "reference",
          "create_uid",
          "employee_id",
          "department",
          "create_date",
          "write_uid",
          "write_date",
          "logged_in_user",
          "static_image",
          "remark_history",
        ];

  // useEffect for getting latest selected field value
  useEffect(() => {
    if (selectedListField && selectedListField.field) {
      if (
        selectedListField?.field?.type === "many2many" ||
        selectedListField?.field?.type === "many2one" ||
        selectedListField?.field?.type === "one2many"
      ) {
        updateFieldData.domain = selectedListField?.field?.domain || null;
      }
      if (
        selectedListField?.field?.type === "button" ||
        selectedListField?.field?.type === "object" ||
        selectedListField?.field?.type === "action"
      ) {
        if (
          !["default-statinfo", "statinfo"].includes(
            selectedListField?.field?.widget
          )
        ) {
          updateFieldData.alignment =
            selectedListField?.field?.alignment || "center";
        } else {
          updateFieldData.smartIcon =
            selectedListField?.field?.smartIcon || "select";
        }
      }
      let relatedModel;
      if (
        selectedListField?.field?.type === "many2one" &&
        selectedListField?.field?.widget === "res_partner_many2one"
      ) {
        relatedModel = "res.partner";
      } else {
        relatedModel = selectedListField?.field?.relatedModel
          ? selectedListField?.field?.relatedModel
          : null;
      }
      let odooButtonColor =
        selectedListField?.field?.type === "button" ||
        selectedListField?.field?.type === "object" ||
        selectedListField?.field?.type === "action"
          ? selectedListField?.field?.odooButtonColor
          : "btn-outline-dark";
      setUpdateFieldData({
        ...updateFieldData,
        title: selectedListField?.field?.title || "",
        type: selectedListField?.field?.type || "",
        technicalName: selectedListField?.field?.technicalName || "",
        widget: selectedListField?.field?.widget || "",
        options: selectedListField?.field?.options || null,
        placeholder: selectedListField?.field?.placeholder || "",
        tooltip: selectedListField?.field?.tooltip || "",
        groupVisible: selectedListField?.field?.groupVisible || [],
        optional: selectedListField?.field?.optional || "show",
        isRequired: selectedListField?.field?.isRequired || false,
        isReadOnly: selectedListField?.field?.isReadOnly || false,
        isInvisible: selectedListField?.field?.isInvisible || false,
        relatedModel: relatedModel,
        odooButtonColor: odooButtonColor,
      });
    }
  }, [selectedListField]);

  // method for reseting selected list field data
  const handleReset = () => {
    setUpdateFieldData({
      title: "",
      technicalName: "x_o2b_",
      type: "",
      widget: "",
      options: null,
      odooButtonColor: "",
      placeholder: "",
      tooltip: "",
      relatedModel: "",
      groupVisible: [],
      isRequired: false,
      isReadOnly: false,
      isInvisible: false,
    });
    setShowFieldDetail(false);
  };

  // method for taking input field data
  const handleChangeData = (e) => {
    const { name, value, type, checked } = e.target;

    setUpdateFieldData({
      ...updateFieldData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleCheckBoxChange = (e) => {
    const { name, checked } = e.target;
    setUpdateFieldData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  // method for save field detail
  const handleSaveListFieldDetail = () => {
    let updatedListData = JSON.parse(JSON.stringify(listDetail));
    const rowIndex = selectedListField.rowIndex;
    updatedListData[rowIndex] = { ...updateFieldData };

    setListDetail(updatedListData);
  };

  // handle list field update at detail form submit
  const handleUpdateFieldDataSubmit = (e) => {
    e.preventDefault();
    handleSaveListFieldDetail();
    handleReset();
  };

  // method for blur field
  const handleBlurData = (e) => {
    let clickedFieldData = { ...selectedListField };
    clickedFieldData.field = { ...updateFieldData };
    setSelectedListField({ ...clickedFieldData });
    handleSaveListFieldDetail();
    // let updatedFormData = JSON.parse(JSON.stringify(listDetail));
  };

  // handling group visibilty for fields ========================================
  // const [newGrp, setNewGrp] = useState("");
  const [showGrpInput, setShowGrpInput] = useState(true);
  const [filteredGroup, setFilteredGroup] = useState(null);
  const handleAddNewGroup = (e) => {
    const { value } = e.target;
    if (value && value !== "") {
      updateFieldData.groupVisible.push(value);
      handleBlurData();
      setShowGrpInput(false);
    }
  };
  const handleGrpDelete = (e, grpIndex) => {
    e.preventDefault();
    updateFieldData.groupVisible.splice(grpIndex, 1);
    handleBlurData();
  };

  // useEffect for filter group by database
  useEffect(() => {
    if (groups.length > 0) {
      const grps = groups.filter(
        (group) => group.database_obj === processDetail?.database_obj
      );
      const filteredGrps = grps?.filter(
        (group) => group.category_name === processDetail?.process_group_category
      );
      setFilteredGroup(filteredGrps || []);
    } else {
      const getGroups = async () => await fetchAllGroups();
      getGroups();
    }
  }, [groups]);

  console.log("ListDetail--------------========>>>>>>: ", listDetail);
  console.log(
    "ListDetail--------------========>>>>>>: ",
    JSON.stringify(listDetail)
  );
  console.log("updateFieldData::: ", updateFieldData);
  console.log("selectedListField::: ", selectedListField);
  console.log("showFieldDetail::: ", showFieldDetail);

  return (
    <div className="fb_detailUpdate">
      <h2>Field Details</h2>
      {showFieldDetail && (
        <div className="fb_field_details">
          {/* code for field delete button */}
          {/* {updateFieldData.widget !== "remark_field" &&
            updateFieldData.technicalName !== "x_o2b_remark_table" && (
              <button
                className="btn btn-danger fb_field_details_btn"
                onClick={handleDeleteField}
              >
                <MdDeleteOutline className="fb_field_details_btn_icon" />
              </button>
            )} */}
          <form
            action=""
            className="fb_field_details_form"
            onSubmit={handleUpdateFieldDataSubmit}
          >
            {/* field code for label field */}
            <div className="fb_field_details_form_group">
              <label htmlFor="title">Label</label>
              <input
                type="text"
                name="title"
                id="title"
                value={updateFieldData.title}
                onChange={handleChangeData}
                onBlur={handleBlurData}
                readOnly
              />
              <p
                style={{
                  fontSize: "12px",
                  width: "100%",
                  marginBottom: "0rem",
                }}
              >
                Technical Name:{" "}
                <span
                  style={{ wordBreak: "break-word", lineBreak: "anywhere" }}
                >
                  {updateFieldData.technicalName}
                </span>
              </p>
            </div>

            {/* field code for type field */}
            <div className="fb_field_details_form_group">
              <label htmlFor="inputTypeSelect">Field Type</label>
              <select
                id="inputTypeSelect"
                name="type"
                value={updateFieldData.type}
                onChange={handleChangeData}
                onBlur={handleBlurData}
                disabled
                style={{ appearance: "none" }}
              >
                {inputTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            {/* field code for widget field */}
            <div className="fb_field_details_form_group">
              <label htmlFor="inputWidgetSelect">Widget</label>
              <select
                id="inputWidgetSelect"
                name="widget"
                value={updateFieldData.widget}
                onChange={handleChangeData}
                onBlur={handleBlurData}
                style={{ appearance: "none" }}
                disabled
              >
                {widgets.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            {/* code for button class name */}
            {["button", "object", "action"].includes(updateFieldData.type) &&
              !["default-statinfo", "statinfo"].includes(
                updateFieldData.widget
              ) && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputClassSelect">Class</label>
                  <CustomDropdown
                    name="odooButtonColor"
                    defaultValue={
                      updateFieldData.odooButtonColor || "btn-outline-dark"
                    }
                    options={odooButtonColors}
                    onChange={(event) => handleChangeData(event)}
                    onBlur={handleBlurData}
                  />
                </div>
              )}

            {/* field code for group field */}
            <div className="fb_field_details_form_group">
              <label htmlFor="availGroups">User Visibilty</label>
              <div className="fdu_group_box">
                {updateFieldData.groupVisible &&
                  updateFieldData.groupVisible.length > 0 && (
                    <ul>
                      <>
                        {updateFieldData.groupVisible.length > 0 &&
                          updateFieldData.groupVisible.map((names, index) => (
                            <li key={index} className="fdu_group_list">
                              <span style={{ fontSize: "14px" }}>
                                {index + 1}.
                              </span>
                              <span className="fdu_group_name_span">
                                {names}
                              </span>
                              <button
                                className="fdu_group_delete"
                                onClick={(e) => handleGrpDelete(e, index)}
                              >
                                <GiCancel />
                              </button>
                            </li>
                          ))}
                      </>
                    </ul>
                  )}
                {showGrpInput ? (
                  <div className="fdu_group_input_box">
                    <select
                      name="group"
                      id="group"
                      onChange={handleAddNewGroup}
                    >
                      <option value="">Select user access</option>
                      {filteredGroup?.map((group, index) => (
                        <option
                          key={index}
                          value={`base.${group.group_internal}`}
                        >
                          {group.group_name}
                        </option>
                      ))}
                    </select>
                    <button
                      className="fdu_group_close_button"
                      onClick={() => setShowGrpInput(false)}
                    >
                      <MdOutlineClose />
                    </button>
                  </div>
                ) : (
                  <>
                    <span
                      onClick={() => setShowGrpInput(true)}
                      className="fdu_group_show_input"
                    >
                      Add user access
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="fb_field_details_form_group">
              <label htmlFor="inputClassSelect">Optional</label>
              <CustomDropdown
                name="optional"
                defaultValue={updateFieldData.optional || "show"}
                options={["show", "hide"]}
                onChange={(event) => handleChangeData(event)}
                onBlur={handleBlurData}
              />
            </div>

            {/* field code for invisible field */}
            <div className="fb_field_details_form_group_checkbox_container">
              <label htmlFor="fieldInvisible" className="label_checkbox">
                Hidden
              </label>
              <label className="checkbox_container">
                <input
                  type="checkbox"
                  id="fieldInvisible"
                  name="isInvisible"
                  checked={updateFieldData.isInvisible}
                  onChange={handleCheckBoxChange}
                  onBlur={handleBlurData}
                />
                <span className="checkmark" />
              </label>
            </div>

            {/* field code for checkboxes and required and readonly field */}
            <div className="fb_field_details_form_group_checkbox_container">
              <label htmlFor="fieldRequired" className="label_checkbox">
                Mandatory
              </label>
              <label className="checkbox_container">
                <input
                  type="checkbox"
                  id="fieldRequired"
                  name="isRequired"
                  checked={updateFieldData.isRequired}
                  onChange={handleCheckBoxChange}
                  onBlur={handleBlurData}
                />
                <span className="checkmark" />
              </label>
            </div>
            <div className="fb_field_details_form_group_checkbox_container">
              <label htmlFor="fieldReadOnly" className="label_checkbox">
                Read-only
              </label>
              <label className="checkbox_container">
                <input
                  type="checkbox"
                  id="fieldReadOnly"
                  name="isReadOnly"
                  checked={updateFieldData.isReadOnly}
                  onChange={handleCheckBoxChange}
                  onBlur={handleBlurData}
                  disabled={
                    [
                      "create_uid",
                      "employee_id",
                      "department",
                      "create_date",
                      "write_uid",
                      "write_date",
                      "logged_in_user",
                    ].includes(updateFieldData.type) && true
                  }
                />
                <span className="checkmark" />
              </label>
            </div>

            <button type="submit" className="btn fb_field_details_form_submit">
              Save
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ListDetailUpdate;
