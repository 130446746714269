import React, { useEffect, useState } from "react";
import "./FormBuilderPage.css";
import FormControl from "./form_builder_content/FormControl";
import FormBoard from "./form_builder_content/FormBoard";
import FormDetailUpdate from "./form_builder_content/FormDetailUpdate";
import { useFormContext } from "../../context/FormContext";
import ListDetailUpdate from "./form_builder_content/ListDetailUpdate";
import { useLocation } from "react-router-dom";
import { useProcessContext } from "../../context/ProcessContext";

const FormBuilderPage = () => {
  const { processDetail, checkLoginStatus } = useProcessContext();
  const { isKanbanBuilder, builderType, formData } = useFormContext();
  const [selectedListField, setSelectedListField] = useState(null);
  // const [selectedTodosField, setSelectedTodosField] = useState(null);
  // const [selectedDocumentsField, setSelectedDocumentsField] = useState(null);
  const location = useLocation();

  useEffect(() => {
    checkLoginStatus();
  }, [location.pathname, processDetail, formData]);

  // Array data for all widgets
  const widgets = [
    "char",
    "text",
    "integer",
    "float",
    "monetary",
    "boolean",
    "selection",
    "many2one",
    "many2many",
    "many2many_tags",
    "many2many_checkboxes",
    "one2many",
    "date",
    "datetime",
    "time",
    "binary",
    "image",
    "file",
    "url",
    "phone",
    "email",
    "password",
    "html",
    "priority",
    "percentage",
    "handle",
    "radio",
    "statinfo",
    "default-statinfo",
    "statusbar",
    "progressbar",
    "color",
    "rating",
    "kanban_state_selection",
    "html_frame",
    "document",
    "static_image",
    "remark_history",
    "remark_field",
    "res_partner_many2one",
    "email_verify_status",
    "defaultField",
    "virtualField",
  ];
  // Array data for button class names
  const odooButtonColors = [
    "btn-primary",
    "btn-secondary",
    "btn-success",
    "btn-danger",
    "btn-warning",
    "btn-info",
    "btn-light",
    "btn-dark",
    "btn-outline-primary",
    "btn-outline-secondary",
    "btn-outline-success",
    "btn-outline-danger",
    "btn-outline-warning",
    "btn-outline-info",
    "btn-outline-light",
    "btn-outline-dark",
  ];
  // Array data for table field type
  const tableFieldTypes = [
    "char",
    "text",
    "date",
    "datetime",
    "boolean",
    "float",
    "integer",
    "selection",
    "many2one",
    "many2many",
    "one2many",
  ];
  // icons for odoo smart button
  const odooIcons = [
    "fa-folder-open",
    "fa-users",
    "fa-shopping-cart",
    "fa-check-circle",
    "fa-pencil",
    "fa-trash",
    "fa-save",
    "fa-download",
    "fa-upload",
    "fa-file",
    "fa-file-o",
    "fa-folder",
    "fa-cog",
    "fa-wrench",
    "fa-money",
    "fa-list",
    "fa-book",
    "fa-search",
    "fa-envelope",
    "fa-comment",
    "fa-comments",
    "fa-phone",
    "fa-star",
    "fa-star-o",
    "fa-heart",
    "fa-heart-o",
    "fa-calendar",
    "fa-calendar-o",
    "fa-clock-o",
    "fa-bell",
    "fa-bell-o",
    "fa-info-circle",
    "fa-exclamation-circle",
    "fa-question-circle",
    "fa-eye",
    "fa-eye-slash",
    "fa-user",
    "fa-user-o",
    "fa-user-plus",
    "fa-user-times",
    "fa-users",
    "fa-key",
    "fa-lock",
    "fa-unlock",
    "fa-shield",
    "fa-tasks",
    "fa-bolt",
    "fa-flag",
    "fa-flag-o",
    "fa-map-marker",
    "fa-globe",
    "fa-bar-chart",
    "fa-line-chart",
    "fa-pie-chart",
    "fa-cloud",
    "fa-cloud-download",
    "fa-cloud-upload",
    "fa-print",
    "fa-pencil-square-o",
    "fa-check-square-o",
    "fa-square",
    "fa-square-o",
    "fa-circle",
    "fa-circle-o",
    "fa-refresh",
    "fa-repeat",
    "fa-undo",
    "fa-reply",
    "fa-paperclip",
    "fa-camera",
    "fa-video-camera",
    "fa-play",
    "fa-pause",
    "fa-stop",
    "fa-forward",
    "fa-backward",
    "fa-fast-forward",
    "fa-fast-backward",
    "fa-caret-right",
    "fa-caret-left",
    "fa-caret-up",
    "fa-caret-down",
    "fa-angle-right",
    "fa-angle-left",
    "fa-angle-up",
    "fa-angle-down",
    "fa-plus",
    "fa-minus",
    "fa-plus-circle",
    "fa-minus-circle",
    "fa-check",
    "fa-times",
    "fa-close",
    "fa-remove",
    "fa-share",
    "fa-share-alt",
    "fa-arrows",
    "fa-arrows-h",
    "fa-arrows-v",
    "fa-long-arrow-right",
    "fa-long-arrow-left",
    "fa-long-arrow-up",
    "fa-long-arrow-down",
    "fa-arrow-right",
    "fa-arrow-left",
    "fa-arrow-up",
    "fa-arrow-down",
    "fa-sort",
    "fa-sort-asc",
    "fa-sort-desc",
    "fa-thumbs-up",
    "fa-thumbs-down",
    "fa-external-link",
    "fa-external-link-square",
    "fa-link",
    "fa-chain",
    "fa-cut",
    "fa-scissors",
    "fa-copy",
    "fa-files-o",
    "fa-file-text",
    "fa-file-text-o",
    "fa-indent",
    "fa-outdent",
    "fa-align-left",
    "fa-align-right",
    "fa-align-center",
    "fa-align-justify",
    "fa-bold",
    "fa-italic",
    "fa-underline",
    "fa-strikethrough",
    "fa-code",
    "fa-terminal",
    "fa-bug",
    "fa-database",
    "fa-code-fork",
    "fa-desktop",
    "fa-laptop",
    "fa-tablet",
    "fa-mobile",
    "fa-mobile-phone",
    "fa-folder",
    "fa-folder-open",
    "fa-archive",
    "fa-cutlery",
    "fa-building",
    "fa-home",
    "fa-institution",
    "fa-bank",
    "fa-university",
    "fa-graduation-cap",
    "fa-mortar-board",
    "fa-coffee",
    "fa-book",
    "fa-briefcase",
    "fa-car",
    "fa-road",
    "fa-truck",
    "fa-plane",
    "fa-ship",
    "fa-power-off",
    "fa-signal",
    "fa-gears",
    "fa-cogs",
    "fa-gear",
    "fa-cog",
    "fa-wrench",
    "fa-hammer",
    "fa-shield",
    "fa-bell",
    "fa-bell-o",
    "fa-gift",
    "fa-leaf",
    "fa-fire",
    "fa-eye",
    "fa-eye-slash",
    "fa-binoculars",
    "fa-trash-o",
    "fa-recycle",
    "fa-user-secret",
    "fa-rocket",
    "fa-anchor",
    "fa-puzzle-piece",
    "fa-plug",
    "fa-certificate",
    "fa-sitemap",
    "fa-heartbeat",
    "fa-stethoscope",
    "fa-hospital-o",
    "fa-medkit",
    "fa-wheelchair",
    "fa-ambulance",
    "fa-bed",
    "fa-bookmark",
    "fa-bookmark-o",
    "fa-clipboard",
    "fa-suitcase",
    "fa-cut",
    "fa-copy",
    "fa-paste",
    "fa-puzzle-piece",
    "fa-life-ring",
    "fa-anchor",
    "fa-bell",
    "fa-bell-o",
    "fa-bullhorn",
    "fa-bullseye",
    "fa-calendar",
    "fa-calendar-o",
    "fa-calendar-check-o",
    "fa-calendar-minus-o",
    "fa-calendar-plus-o",
    "fa-calendar-times-o",
    "fa-camera",
    "fa-camera-retro",
    "fa-car",
    "fa-caret-square-o-down",
    "fa-caret-square-o-left",
    "fa-caret-square-o-right",
    "fa-caret-square-o-up",
    "fa-cart-arrow-down",
    "fa-cart-plus",
    "fa-certificate",
    "fa-check-circle",
    "fa-check-square",
    "fa-check-square-o",
    "fa-circle-o",
    "fa-circle-o-notch",
    "fa-circle-thin",
    "fa-clipboard",
    "fa-clock-o",
    "fa-clone",
    "fa-cloud",
    "fa-cloud-download",
    "fa-cloud-upload",
    "fa-code",
    "fa-code-fork",
    "fa-coffee",
    "fa-cog",
    "fa-cogs",
    "fa-columns",
    "fa-comment",
    "fa-comment-o",
    "fa-comments",
    "fa-comments-o",
    "fa-compass",
    "fa-copyright",
    "fa-credit-card",
    "fa-crop",
    "fa-crosshairs",
    "fa-cube",
    "fa-cubes",
    "fa-cutlery",
    "fa-dashboard",
    "fa-database",
    "fa-desktop",
    "fa-download",
    "fa-edit",
    "fa-ellipsis-h",
    "fa-ellipsis-v",
    "fa-envelope",
    "fa-envelope-o",
    "fa-exclamation",
    "fa-exclamation-circle",
    "fa-exclamation-triangle",
    "fa-external-link",
    "fa-external-link-square",
    "fa-eye",
    "fa-eye-slash",
    "fa-facebook",
    "fa-facebook-f",
    "fa-facebook-official",
    "fa-facebook-square",
    "fa-fast-backward",
    "fa-fast-forward",
    "fa-fax",
    "fa-feed",
    "fa-female",
    "fa-fighter-jet",
    "fa-file",
    "fa-file-archive-o",
    "fa-file-audio-o",
    "fa-file-code-o",
    "fa-file-excel-o",
    "fa-file-image-o",
    "fa-file-movie-o",
    "fa-file-o",
    "fa-file-pdf-o",
    "fa-file-photo-o",
    "fa-file-picture-o",
    "fa-file-powerpoint-o",
    "fa-file-sound-o",
    "fa-file-text",
    "fa-file-text-o",
    "fa-file-video-o",
    "fa-file-word-o",
    "fa-files-o",
    "fa-film",
    "fa-filter",
    "fa-fire",
    "fa-fire-extinguisher",
    "fa-flag",
    "fa-flag-checkered",
    "fa-flag-o",
    "fa-flash",
    "fa-flask",
    "fa-folder",
    "fa-folder-o",
    "fa-folder-open",
    "fa-folder-open-o",
    "fa-font",
    "fa-font-awesome",
    "fa-forward",
    "fa-frown-o",
    "fa-futbol-o",
    "fa-gamepad",
    "fa-gavel",
    "fa-gear",
    "fa-gears",
    "fa-gift",
    "fa-git",
    "fa-git-square",
    "fa-github",
    "fa-github-alt",
    "fa-github-square",
    "fa-glass",
    "fa-globe",
    "fa-google",
    "fa-google-plus",
    "fa-google-plus-square",
    "fa-google-wallet",
    "fa-graduation-cap",
    "fa-group",
    "fa-h-square",
    "fa-hacker-news",
    "fa-hand-o-down",
    "fa-hand-o-left",
    "fa-hand-o-right",
    "fa-hand-o-up",
    "fa-hdd-o",
    "fa-header",
    "fa-headphones",
    "fa-heart",
    "fa-heart-o",
    "fa-heartbeat",
    "fa-history",
    "fa-home",
    "fa-hospital-o",
    "fa-hotel",
    "fa-hourglass",
    "fa-hourglass-1",
    "fa-hourglass-2",
    "fa-hourglass-3",
    "fa-hourglass-end",
    "fa-hourglass-half",
    "fa-hourglass-o",
    "fa-hourglass-start",
    "fa-i-cursor",
    "fa-id-badge",
    "fa-id-card",
    "fa-id-card-o",
    "fa-image",
    "fa-inbox",
    "fa-indent",
    "fa-industry",
    "fa-info",
    "fa-info-circle",
    "fa-institution",
    "fa-italic",
    "fa-joomla",
    "fa-jpy",
    "fa-key",
    "fa-keyboard-o",
    "fa-language",
    "fa-laptop",
    "fa-lastfm",
    "fa-lastfm-square",
    "fa-leaf",
    "fa-leanpub",
    "fa-legal",
    "fa-lemon-o",
    "fa-level-down",
    "fa-level-up",
    "fa-life-bouy",
    "fa-life-ring",
    "fa-life-saver",
    "fa-lightbulb-o",
    "fa-line-chart",
    "fa-link",
    "fa-linkedin",
    "fa-linkedin-square",
    "fa-linux",
    "fa-list",
    "fa-list-alt",
    "fa-list-ol",
    "fa-list-ul",
    "fa-location-arrow",
    "fa-lock",
    "fa-long-arrow-down",
    "fa-long-arrow-left",
    "fa-long-arrow-right",
    "fa-long-arrow-up",
    "fa-magic",
    "fa-magnet",
    "fa-mail-forward",
    "fa-mail-reply",
    "fa-mail-reply-all",
    "fa-male",
    "fa-map",
    "fa-map-marker",
    "fa-map-o",
    "fa-map-pin",
    "fa-map-signs",
    "fa-mars",
    "fa-mars-double",
    "fa-mars-stroke",
    "fa-mars-stroke-h",
    "fa-mars-stroke-v",
    "fa-maxcdn",
    "fa-meanpath",
    "fa-medium",
    "fa-medkit",
    "fa-meh-o",
    "fa-mercury",
    "fa-microphone",
    "fa-microphone-slash",
    "fa-minus",
    "fa-minus-circle",
    "fa-minus-square",
    "fa-minus-square-o",
    "fa-mixcloud",
    "fa-mobile",
    "fa-modx",
    "fa-money",
    "fa-moon-o",
    "fa-mortar-board",
    "fa-motorcycle",
    "fa-mouse-pointer",
    "fa-music",
    "fa-navicon",
    "fa-neuter",
    "fa-newspaper-o",
    "fa-object-group",
    "fa-object-ungroup",
    "fa-odnoklassniki",
    "fa-odnoklassniki-square",
    "fa-opencart",
    "fa-openid",
    "fa-opera",
    "fa-optin-monster",
    "fa-outdent",
    "fa-pagelines",
    "fa-paint-brush",
    "fa-paper-plane",
    "fa-paper-plane-o",
    "fa-paperclip",
    "fa-paragraph",
    "fa-paste",
    "fa-pause",
    "fa-pause-circle",
    "fa-pause-circle-o",
    "fa-paw",
    "fa-paypal",
    "fa-pencil",
    "fa-pencil-square",
    "fa-pencil-square-o",
    "fa-percent",
    "fa-phone",
    "fa-phone-square",
    "fa-picture-o",
    "fa-pie-chart",
    "fa-pied-piper",
    "fa-pied-piper-alt",
    "fa-pinterest",
    "fa-pinterest-p",
    "fa-pinterest-square",
    "fa-plane",
    "fa-play",
    "fa-play-circle",
    "fa-play-circle-o",
    "fa-plug",
    "fa-plus",
    "fa-plus-circle",
    "fa-plus-square",
    "fa-plus-square-o",
    "fa-podcast",
    "fa-power-off",
    "fa-print",
    "fa-product-hunt",
    "fa-puzzle-piece",
    "fa-qq",
    "fa-qrcode",
    "fa-question",
    "fa-question-circle",
    "fa-question-circle-o",
    "fa-quote-left",
    "fa-quote-right",
    "fa-ra",
    "fa-random",
    "fa-rebel",
    "fa-recycle",
    "fa-reddit",
    "fa-reddit-alien",
    "fa-reddit-square",
    "fa-refresh",
    "fa-registered",
    "fa-remove",
    "fa-renren",
    "fa-reorder",
    "fa-repeat",
    "fa-reply",
    "fa-reply-all",
    "fa-retweet",
    "fa-road",
    "fa-rocket",
    "fa-rss",
    "fa-rss-square",
    "fa-rub",
    "fa-ruble",
    "fa-rupee",
    "fa-safari",
    "fa-save",
    "fa-scissors",
    "fa-scribd",
    "fa-search",
    "fa-search-minus",
    "fa-search-plus",
    "fa-sellsy",
    "fa-send",
    "fa-send-o",
    "fa-server",
    "fa-share",
    "fa-share-alt",
    "fa-share-alt-square",
    "fa-share-square",
    "fa-share-square-o",
    "fa-shield",
    "fa-ship",
    "fa-shirtsinbulk",
    "fa-shopping-bag",
    "fa-shopping-basket",
    "fa-shopping-cart",
    "fa-sign-in",
    "fa-sign-out",
    "fa-signal",
    "fa-simplybuilt",
    "fa-sitemap",
    "fa-skyatlas",
    "fa-skype",
    "fa-slack",
    "fa-sliders",
    "fa-slideshare",
    "fa-smile-o",
    "fa-soccer-ball-o",
    "fa-sort",
    "fa-sort-alpha-asc",
    "fa-sort-alpha-desc",
    "fa-sort-amount-asc",
    "fa-sort-amount-desc",
    "fa-sort-asc",
    "fa-sort-desc",
    "fa-sort-numeric-asc",
    "fa-sort-numeric-desc",
    "fa-soundcloud",
    "fa-space-shuttle",
    "fa-spinner",
    "fa-spoon",
    "fa-spotify",
    "fa-square",
    "fa-square-o",
    "fa-stack-exchange",
    "fa-stack-overflow",
    "fa-star",
    "fa-star-half",
    "fa-star-half-empty",
    "fa-star-half-full",
    "fa-star-half-o",
    "fa-star-o",
    "fa-steam",
    "fa-steam-square",
    "fa-step-backward",
    "fa-step-forward",
    "fa-stethoscope",
    "fa-sticky-note",
    "fa-sticky-note-o",
    "fa-stop",
    "fa-stop-circle",
    "fa-stop-circle-o",
    "fa-street-view",
    "fa-strikethrough",
    "fa-stumbleupon",
    "fa-stumbleupon-circle",
    "fa-subscript",
    "fa-subway",
    "fa-suitcase",
    "fa-sun-o",
    "fa-superpowers",
    "fa-superscript",
    "fa-support",
    "fa-table",
    "fa-tablet",
    "fa-tachometer",
    "fa-tag",
    "fa-tags",
    "fa-tasks",
    "fa-taxi",
    "fa-telegram",
    "fa-television",
    "fa-tencent-weibo",
    "fa-terminal",
    "fa-text-height",
    "fa-text-width",
    "fa-th",
    "fa-th-large",
    "fa-th-list",
    "fa-themeisle",
    "fa-thumb-tack",
    "fa-thumbs-down",
    "fa-thumbs-o-down",
    "fa-thumbs-o-up",
    "fa-thumbs-up",
    "fa-ticket",
    "fa-times",
    "fa-times-circle",
    "fa-times-circle-o",
    "fa-tint",
    "fa-toggle-down",
    "fa-toggle-left",
    "fa-toggle-off",
    "fa-toggle-on",
    "fa-toggle-right",
    "fa-toggle-up",
    "fa-trademark",
    "fa-train",
    "fa-transgender",
    "fa-transgender-alt",
    "fa-trash",
    "fa-trash-o",
    "fa-tree",
    "fa-trello",
    "fa-tripadvisor",
    "fa-trophy",
    "fa-truck",
    "fa-try",
    "fa-tty",
    "fa-tumblr",
    "fa-tumblr-square",
    "fa-turkish-lira",
    "fa-tv",
    "fa-twitch",
    "fa-twitter",
    "fa-twitter-square",
    "fa-umbrella",
    "fa-underline",
    "fa-undo",
    "fa-university",
    "fa-unlink",
    "fa-unlock",
    "fa-unlock-alt",
    "fa-unsorted",
    "fa-upload",
    "fa-usb",
    "fa-usd",
    "fa-user",
    "fa-user-circle",
    "fa-user-circle-o",
    "fa-user-md",
    "fa-user-o",
    "fa-user-plus",
    "fa-user-secret",
    "fa-user-times",
    "fa-users",
    "fa-venus",
    "fa-venus-double",
    "fa-venus-mars",
    "fa-viacoin",
    "fa-viadeo",
    "fa-viadeo-square",
    "fa-video-camera",
    "fa-vimeo",
    "fa-vimeo-square",
    "fa-vine",
    "fa-vk",
    "fa-volume-control-phone",
    "fa-volume-down",
    "fa-volume-off",
    "fa-volume-up",
    "fa-warning",
    "fa-wechat",
    "fa-weibo",
    "fa-weixin",
    "fa-whatsapp",
    "fa-wheelchair",
    "fa-wheelchair-alt",
    "fa-wifi",
    "fa-wikipedia-w",
    "fa-window-close",
    "fa-window-close-o",
    "fa-window-maximize",
    "fa-window-minimize",
    "fa-window-restore",
    "fa-windows",
    "fa-won",
    "fa-wordpress",
    "fa-wpbeginner",
    "fa-wpexplorer",
    "fa-wpforms",
    "fa-wrench",
    "fa-xing",
    "fa-xing-square",
    "fa-y-combinator",
    "fa-y-combinator-square",
    "fa-yahoo",
    "fa-yc",
    "fa-yc-square",
    "fa-yelp",
    "fa-yen",
    "fa-yoast",
    "fa-youtube",
    "fa-youtube-play",
    "fa-youtube-square",
  ];

  return (
    <div className="formbuilder">
      <div className="fb_container">
        <FormControl
        // isTodoFormBuilder={isTodoFormBuilder}
        // isDocumentFormBuilder={isDocumentFormBuilder}
        />
        {/* {isTodoFormBuilder === true ? (
          <TodoControl />
        ) : isDocumentFormBuilder === true ? (
          <DocumentControl />
        ) : (
          <FormControl
            isTodoFormBuilder={isTodoFormBuilder}
            isDocumentFormBuilder={isDocumentFormBuilder}
          />
        )} */}
        <FormBoard
          selectedListField={selectedListField}
          setSelectedListField={setSelectedListField}
          // selectedTodosField={selectedTodosField}
          // setSelectedTodosField={setSelectedTodosField}
          // selectedDocumentsField={selectedDocumentsField}
          // setSelectedDocumentsField={setSelectedDocumentsField}
        />

        {/* {isTodoFormBuilder && builderType === "form" ? (
          <TodosDetailUpdate
            widgets={widgets}
            selectedTodosField={selectedTodosField}
            setSelectedTodosField={setSelectedTodosField}
          />
        ) : isDocumentFormBuilder && builderType === "form" ? (
          <DocumentDetailUpdate
            widgets={widgets}
            selectedDocumentsField={selectedDocumentsField}
            setSelectedDocumentsField={setSelectedDocumentsField}
          />
        ) : builderType === "form" ? (
          <FormDetailUpdate
            widgets={widgets}
            odooButtonColors={odooButtonColors}
            tableFieldTypes={tableFieldTypes}
            odooIcons={odooIcons}
          />
        ) : (
          ""
        )} */}
        {builderType === "form" && (
          <FormDetailUpdate
            widgets={widgets}
            odooButtonColors={odooButtonColors}
            tableFieldTypes={tableFieldTypes}
            odooIcons={odooIcons}
          />
        )}
        {builderType === "list" && (
          <ListDetailUpdate
            widgets={widgets}
            odooButtonColors={odooButtonColors}
            tableFieldTypes={tableFieldTypes}
            odooIcons={odooIcons}
            selectedListField={selectedListField}
            setSelectedListField={setSelectedListField}
          />
        )}
      </div>
    </div>
  );
};

export default FormBuilderPage;
