import React, { useEffect, useState } from "react";
import "./ConditionFields.css";
import { useFormContext } from "../../context/FormContext";
import { FaRegTimesCircle } from "react-icons/fa";
import CustomDropdown from "../custom_dropdown/CustomDropdown";
import { useNodeContext } from "../../context/NodeContext";
import { useProcessContext } from "../../context/ProcessContext";

const ConditionFields = ({
  index,
  handleConditionChange,
  handleRemoveCondition,
}) => {
  const { forms, parseFormData, parseFormDataWithoutTableO2m } =
    useFormContext();
  const { shapeId, nodes, handleFindPrevNode } = useNodeContext();
  const { convertDateTimeToTimeZone } = useProcessContext();
  const [operands, setOperands] = useState(null);
  const [condition, setCondition] = useState({
    conditionOperand: "",
    conditionOperator: "=",
    conditionValue: "",
  });

  const handleConditionInputChange = (e) => {
    const { name, value, type } = e.target;
    // let updatedCondition;
    // if (type === "datetime-local") {
    //   updatedCondition = {
    //     ...condition,
    //     [name]: convertDateTimeToTimeZone(
    //       value,
    //       "Asia/Kolkata",
    //       "America/New_York"
    //     ),
    //   };
    // } else {
    //   updatedCondition = { ...condition, [name]: value };
    // }
    const updatedCondition = { ...condition, [name]: value };
    console.log("updatedCondition::: ", updatedCondition);

    setCondition(updatedCondition);
    handleConditionChange(index, updatedCondition);
  };
  // Get selected operand type
  const selectedOperand = operands?.find(
    (operand) => operand.technicalName === condition.conditionOperand
  );
  // useEffect for getting prev form field
  useEffect(() => {
    if (shapeId) {
      const prevNode = handleFindPrevNode(shapeId);
      // console.log("prev node:::: prevNode:: ", prevNode);
      if (!prevNode) {
        setOperands(null);
      }

      if (prevNode && prevNode.data.form) {
        // const newFields = parseFormData(prevNode.data.form.formbuilder_data);
        const newFields = parseFormDataWithoutTableO2m(
          prevNode.data.form.formbuilder_data
        );
        const uniqueOperands = new Map();
        newFields.forEach((op) => {
          if (!uniqueOperands.has(op.technicalName)) {
            uniqueOperands.set(op.technicalName, op);
          }
        });

        setOperands([...uniqueOperands.values()]);
      }
    }
  }, [shapeId, nodes]);

  // useEffect(() => {
  //   if (forms) {
  //     const uniqueOperands = new Map();

  //     forms.forEach((form) => {
  //       if (form.formbuilder_data) {
  //         const opr = parseFormData(form.formbuilder_data);
  //         opr.forEach((op) => {
  //           if (
  //             ![
  //               "static_image",
  //               "chatter",
  //               "button",
  //               "table",
  //               "tab",
  //               "group",
  //               "separator",
  //               "label",
  //             ].includes(op.type)
  //           ) {
  //             // Add to the map if the technicalName doesn't exist already
  //             if (!uniqueOperands.has(op.technicalName)) {
  //               uniqueOperands.set(op.technicalName, op);
  //             }
  //           }
  //         });
  //       }
  //     });

  //     // Convert the map values to an array
  //     setOperands([...uniqueOperands.values()]);
  //   }
  // }, [forms]);

  // Render value field conditionally based on the operand type
  const renderValueField = () => {
    if (selectedOperand) {
      if (selectedOperand.type === "date") {
        return (
          <input
            type="date"
            name="conditionValue"
            value={condition.conditionValue}
            onChange={handleConditionInputChange}
            style={{ width: "85%", height: "25px", color: "#666666" }}
          />
        );
      } else if (selectedOperand.type === "datetime") {
        return (
          <input
            type="datetime-local"
            name="conditionValue"
            value={condition.conditionValue}
            onChange={handleConditionInputChange}
            style={{ width: "85%", height: "25px", color: "#666666" }}
          />
        );
      } else if (selectedOperand.type === "selection") {
        return (
          <CustomDropdown
            name="conditionValue"
            defaultValue={condition.conditionValue || null}
            options={selectedOperand.options?.map((opt) => {
              return {
                value: `${opt.toLowerCase().trim().replace(/ /g, "_")}`,
                label: opt,
              };
            })}
            onChange={(event) => handleConditionInputChange(event)}
            width={"85%"}
            height={"25px"}
            border="1px solid gray"
            placeholder="value"
          />
        );
      }
    }

    // Default case: return input type="text" for other operand types
    return (
      <>
        <input
          type="text"
          name="conditionValue"
          list="operandlist1"
          value={condition.conditionValue}
          placeholder="Value"
          onChange={handleConditionInputChange}
          style={{ width: "85%" }}
        />
        <datalist id="operandlist1">
          {operands &&
            operands.map((operand, index) => (
              <option key={index + "1"} value={operand.technicalName} />
            ))}
        </datalist>
      </>
    );
  };

  console.log("operands: ", operands);

  return (
    <div className="condition_field_container">
      <CustomDropdown
        name="conditionOperand"
        defaultValue={condition.conditionOperand || null}
        options={operands?.map((field) => {
          return {
            value: field.technicalName,
            label: field.title,
          };
        })}
        onChange={(event) => handleConditionInputChange(event)}
        width={"60%"}
        height={"25px"}
        border="1px solid gray"
        placeholder="Operand"
      />
      <CustomDropdown
        name="conditionOperator"
        defaultValue={condition.conditionOperator || null}
        options={["=", "!=", ">", ">=", "<", "<=", "in", "not in"]}
        onChange={(event) => handleConditionInputChange(event)}
        width={"30%"}
        height={"25px"}
        border="1px solid gray"
      />
      {renderValueField()}
      <button
        onClick={() => handleRemoveCondition(index)}
        className="condition_field_remove_btn"
      >
        <FaRegTimesCircle />
      </button>
    </div>
  );
};

export default ConditionFields;
